import { parseISO, formatDistance, formatISO, format } from "date-fns";
import { da } from "date-fns/locale";

const magicDateFormatWithZeroWidthSpaceBeforeYearAndMonth = "dd.​MM.​yyyy";

export default function DateStamp({ date, useDistanceFromToday }: { date: string | Date; useDistanceFromToday?: boolean }) {
  const { d, isoStr } =
    typeof date === "string" ? { d: parseISO(date), isoStr: date } : { d: date, isoStr: Number.isNaN(date.valueOf()) ? "" : formatISO(date) };

  return Number.isNaN(d.getTime()) ? (
    <div>-</div>
  ) : (
    <time title={format(d, "PPPP 'kl.' H:mm", { locale: da })} dateTime={isoStr}>
      {useDistanceFromToday
        ? formatDistance(d, Date.now(), { locale: da, addSuffix: true })
        : format(d, magicDateFormatWithZeroWidthSpaceBeforeYearAndMonth)}
    </time>
  );
}
