import { contentfulConfig } from "./configuration";
import { ClientError, gql, request } from "graphql-request";
import { NormalizedSearchWords, normalizeString, toRoutingName } from "./misc";
import { FetcherFnReturnType, useSWRCurried } from "./fetching";
import { parseISO } from "date-fns";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";

const newsItemFragment = gql`
  fragment NewsItemFields on NewsItem {
    sys {
      id
      firstPublishedAt
      publishedAt
    }
    heroImage {
      contentType
      fileName
      url
      width
      height
    }
    tagline
    headline
    subHeadline
    body {
      json
    }
    archived
  }
`;

const newsItemsFetcher = () => {
  return request<DeepArrayUnmaybefy<NewsItemsQuery>>(
    contentfulConfig.graphqlEndpoint,
    gql`
      query NewsItems {
        newsItems: newsItemCollection(locale: "da-DK", order: [sys_firstPublishedAt_DESC]) {
          total
          items {
            ...NewsItemFields
          }
        }
      }
      ${newsItemFragment}
    `
  );
};

type NewsItemDto =
  | NonNullable<FetcherFnReturnType<typeof newsItemsFetcher>["newsItems"]>["items"][number]
  | NonNullable<FetcherFnReturnType<typeof newsItemFetcher>>;

function augmentNewsItem<T extends NewsItemDto>(itm: T) {
  return {
    ...itm,
    firstPublishedAtDate: parseISO(itm.sys.firstPublishedAt!),
    publishedAtDate: parseISO(itm.sys.publishedAt!),
    routingName: toRoutingName(itm.headline),
    archived: itm.archived === true,
  };
}

export function useNewsItems() {
  const { data, error } = useSWRCurried<ClientError>()("contentful/news-items", newsItemsFetcher);
  const all = data?.newsItems?.items.map(augmentNewsItem) ?? [];

  // DEV-shit in order to make more news items than there really are in Contentful
  // const usedImages = new Set<string>();
  // const rndColor = () => Math.random().toString(16).slice(2, 8).padEnd(6, "0");
  // const orig = data?.newsItems?.items.map((itm) => augmentNewsItem(itm)) ?? [];
  // const all = Array.from({ length: 10 }, (v, i) => orig)
  //   .flat()
  //   .map<ReturnType<typeof augmentNewsItem>>((itm, i) => {
  //     let url = itm.heroImage?.url;
  //     if (url && !usedImages.has(url)) {
  //       usedImages.add(url);
  //     } else {
  //       url = i % 4 === 0 ? undefined : `https://via.placeholder.com/760x428.png/${rndColor()}/${rndColor()}`;
  //     }

  //     return {
  //       ...itm,
  //       sys: { ...itm.sys, id: `${itm.sys.id}_${i}` },
  //       heroImage: itm.heroImage && { ...itm.heroImage, url },
  //       headline: `${itm.headline}${i}` as string | undefined,
  //       routingName: toRoutingName(`${i} - ${itm.headline}`),
  //       archived: itm.archived || i % 10 > 2,
  //     };
  //   });
  //.slice(0, 3);

  return {
    newsItems: data
      ? {
          all: {
            newsItems: all,
            findById: (newsId: string) => all.find((itm) => itm.sys.id === newsId),
            findByRoutingName: (routingName: string) => all.find((itm) => itm.routingName === routingName.toLowerCase()),
            findHeadlineById: (newsId: string) => all.find((itm) => itm.sys.id === newsId)?.headline ?? "",
            findHeadlineByRoutingName: (routingName: string) => all.find((itm) => itm.routingName === routingName.toLowerCase())?.headline ?? "",
          },
          active: all.filter((itm) => itm.archived !== true),
          archived: all.filter((itm) => itm.archived === true),
        }
      : undefined,
    newsItemsFetchError: error,
    isLoadingNewsItems: !data && !error,
  };
}

export type NewsItem = NonNullable<FetcherFnReturnType<typeof useNewsItems>["newsItems"]>["all"]["newsItems"][number];

const newsItemFetcher = (_: string, newsId: NewsItemQueryVariables["newsId"]) => {
  return request<DeepArrayUnmaybefy<NewsItemQuery>>(
    contentfulConfig.graphqlEndpoint,
    gql`
      query NewsItem($newsId: String!) {
        newsItem(id: $newsId) {
          ...NewsItemFields
          body {
            json
            links {
              assets {
                block {
                  sys {
                    id
                  }
                  contentType
                  url
                  title
                  width
                  height
                  description
                }
                hyperlink {
                  sys {
                    id
                  }
                  url
                  title
                  description
                }
              }
            }
          }
        }
      }
      ${newsItemFragment}
    `,
    {
      newsId,
    }
  ).then((data) => {
    if (!!!data.newsItem) {
      throw new Error(`NewsItem with ID '${newsId}' not found`);
    }
    return data.newsItem!;
  });
};

export function useNewsItem(newsId: string) {
  // DEV-shit in order to make more news items than there really are in Contentful
  // const realNewsId = newsId.split("_")[0];
  // const { newsItems } = useNewsItems();
  // const { data, error } = useSWRCurried<ClientError | Error>()(() => (!newsItems ? null : ["contentful/news-item", realNewsId]), newsItemFetcher);
  // const alreadyAugmentedNewsItem = data ? newsItems?.all.findById(newsId) : undefined;
  // const newsItem = alreadyAugmentedNewsItem && { ...data, ...alreadyAugmentedNewsItem };

  const { data, error } = useSWRCurried<ClientError | Error>()(["contentful/page", newsId], newsItemFetcher);
  const newsItem = data ? augmentNewsItem(data) : undefined;

  return {
    newsItem,
    newsItemFetchError: error,
    isLoadingNewsItem: !data && !error,
  };
}

export function includesAnyOfFullWords(itm: NewsItem, searchWords: NormalizedSearchWords): boolean {
  return searchWords.length === 0
    ? true
    : searchWords.some(
        (searchWords) =>
          normalizeString(itm.headline ?? "").includes(searchWords) ||
          normalizeString(itm.subHeadline ?? "").includes(searchWords) ||
          normalizeString(itm.body?.json ? documentToPlainTextString(itm.body.json) : "").includes(searchWords)
      );
}
