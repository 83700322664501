import { gql } from "graphql-request";

const assetItemFields = gql`
  fragment AssetItemFields on AssetItem {
    __typename
    sys {
      id
    }
    title
    description {
      json
    }
    asset {
      sys {
        id
      }
      title
      description
      url
      contentType
    }
  }
`;

const linkItemFields = gql`
  fragment LinkItemFields on LinkItem {
    __typename
    sys {
      id
    }
    title
    description {
      json
    }
    link
    linkText
  }
`;

export const videoItemFields = gql`
  fragment VideoItemFields on VideoItem {
    __typename
    sys {
      id
    }
    title
    description {
      json
    }
    video
  }
`;

export const itemListFragment = gql`
  fragment ItmList on ItemList {
    __typename
    sys {
      id
    }
    title
    listName
    items: itemsCollection {
      total
      items {
        ... on AssetItem {
          ...AssetItemFields
        }
        ... on LinkItem {
          ...LinkItemFields
        }
        ... on VideoItem {
          ...VideoItemFields
        }
      }
    }
  }
  ${assetItemFields}
  ${linkItemFields}
  ${videoItemFields}
`;

export const assetFields = gql`
  fragment AssetFields on Asset {
    __typename
    sys {
      id
    }
    title
    description
    contentType
    url
  }
`;

export const docListFragment = gql`
  fragment DocList on DocumentList {
    __typename
    sys {
      id
    }
    title
    listName
    documents: documentsCollection {
      total
      items {
        ...AssetFields
      }
    }
  }
  ${assetFields}
`;
