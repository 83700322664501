import { route, param, Route, PathParam, RouteParams as TrrRouteParams } from "typesafe-react-router";

// prettier-ignore
export const Routes = {
  Home: route(),
  OperationsInformation: route("operations", "information"),
  OperationsInformationFasId: route("operations", "information", param("fasId")),
  OperationsCoverageMap: route("operations", "coverage-map"),
  Contact: route("contact"),
  Dashboard: route("dashboard"),
  DashboardVideosVideoIdRouteName: route("dashboard","videos", param("videoId"), param("videoRoutingName")        ),
  DashboardMessagesDirect: route("dashboard", "messages", "direct"),
  DashboardMessagesDirectMsgIdMsgRouteName: route("dashboard", "messages", "direct", param("msgId"), param("msgRoutingName")),
  DashboardMessages: route("dashboard", "messages").withQueryParams("msg", "dashboard"),
  DashboardMessagesMsgId: route("dashboard", "messages", param("msgId")),
  DashboardMessagesMsgIdMsgRouteName: route("dashboard", "messages", param("msgId"), param("msgRoutingName")),
  Products: route("products"),
  ProductsProductRouteName: route("products", param("productRoutingName")),
  ProductsProductRouteNameVideosVideoIdRouteName: route("products", param("productRoutingName"), "videos", param("videoId"), param("videoRoutingName")),
  Apps: route("applications"),
  AppsErrorReports: route("applications", "error-reports"),
  AppsAppRouteName: route("applications", param("appRoutingName")),
  AppsAppRouteNameErrorReports: route("applications", param("appRoutingName"), "error-reports"),
  AppsAppRouteNameErrorReportsRptId: route("applications", param("appRoutingName"), "error-reports", param("rptId")),
  Library: route("library"),
  LibraryReleaseCalendar: route("library", "release-calendar"),
  LibraryPageRouteName: route("library", param("libraryPageRoutingName")),
  LibraryPageRouteNameVideosVideoIdRouteName: route("library", param("libraryPageRoutingName"), "videos", param("videoId"), param("videoRoutingName")),
  News: route("news"),
  NewsNewsItemNewsId: route("news", param("newsId")),
  NewsNewsItemNewsIdRouteName: route("news", param("newsId"), param("newsRoutingName")),
  NewsArchive: route("news", "archive"),
  NewsArchiveNewsItemNewsId: route("news", "archive", param("newsId")),
  NewsArchiveNewsItemNewsIdRouteName: route("news", "archive", param("newsId"), param("newsRoutingName")),
  Videos: route("videos"),
  VideosVideoIdRouteName: route("videos", param("videoId"), param("videoRoutingName")),
  Profile: route("profile"),
  AuthLoginRedirect: route("authentication", "login-redirect"),
  AuthLoggedOut: route("authentication", "logged-out"),
} as const;

export type RoutesType = typeof Routes;

export type WellKnownUrlSegments = RoutesType[keyof RoutesType] extends Route<infer SegmentsArray, any>
  ? SegmentsArray extends Array<infer Segment>
    ? Segment extends string
      ? Segment
      : never
    : never
  : never;

export type RouteParamsUnion<R extends keyof RoutesType> = RoutesType[R] extends Route<infer SegmentsArray, any>
  ? SegmentsArray extends Array<infer Segment>
    ? Segment extends PathParam<any>
      ? Segment["param"]
      : never
    : never
  : never;

export type RouteParams<R extends keyof RoutesType> = TrrRouteParams<RoutesType[R]>;
