import React from "react";
import { useAuth } from "oidc-react";
import Breadcrumb from "./shared/Breadcrumb";
import PageHeader from "./shared/PageHeader";
import Page from "./shared/Page";
import LoadingProblemBanner from "./shared/LoadingProblemBanner";
import { useMobileServiceProvider } from "../store/mobileServiceProviders";

const Profile: React.FC = () => {
  const userData = useAuth().userData;
  const profile = userData?.profile;
  const spName = useMobileServiceProvider().mobileServiceProvider?.name;

  return (
    <Page>
      <section className="section">
        <div className="content-container">
          <Breadcrumb />

          <PageHeader title="Profil" subtitle="Profiloplysninger er hentet fra brugeradministrationen" className="pb-6" />

          {profile ? (
            <div className="is-flex is-justify-content-start">
              <div className="card has-background-white">
                <div className="has-text-grey-light is-size-8 p-2 pb-3">{spName || <>&nbsp;</>}</div>
                <div className="card-content has-text-centered px-6 pt-5 pb-6">
                  <div className="content px-4">
                    <p className="is-size-5 has-text-weight-bold">
                      {profile.given_name} {profile.family_name}
                    </p>
                    <p>{profile.phone}</p>
                    <p>{profile.email}</p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <LoadingProblemBanner>Profiloplysninger mangler</LoadingProblemBanner>
          )}
        </div>
      </section>
    </Page>
  );
};

export default Profile;
