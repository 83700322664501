import React from "react";
import { Link, useLocation } from "react-router-dom";
import { cx, css } from "@emotion/css";
import { WellKnownUrlSegments } from "../../store/routing";

const urlSegmentToNameMap: Partial<Record<WellKnownUrlSegments | "", string | false>> = {
  "": "Hjem",
  contact: "Kontakt",
  dashboard: "Kontrolpanel",
  messages: "Beskeder",
  direct: false,
  applications: "Værktøjer",
  library: "Bibliotek",
  videos: "Videoer",
  news: "Nyheder",
  archive: "Arkiv",
  profile: "Profil",
  "error-reports": "Fejlmelding",
  "release-calendar": "Releasekalender",
};

const breadcrumbCss = css`
  &.breadcrumb {
    margin-top: -1.75rem;

    li + li::before {
      content: "›";
      color: var(--color-grey);
    }
    li a.lnk,
    li.isActive a.lnk {
      color: #636363;
      white-space: normal;
    }
  }
`;

const Breadcrumb: React.FC<{ mapUrlSegmentToName?: (segment: string, segmentIdx: number, path: string) => string | false | null | undefined }> = ({
  mapUrlSegmentToName,
}) => {
  const { pathname } = useLocation();
  const crumbs = [
    ...pathname
      .trim()
      .replace(/[/]+$/, "") // trim away '/' chars at the end of the URL
      .split("/"),
  ];

  return (
    <nav className={"breadcrumb is-small " + breadcrumbCss} aria-label="breadcrumbs">
      <ul>
        {crumbs.map((c, idx) => {
          const segmentMapping = mapUrlSegmentToName?.(c, idx, pathname);
          const wellKnownSegmentMapping = urlSegmentToNameMap[c as WellKnownUrlSegments];

          return segmentMapping === false || wellKnownSegmentMapping === false ? null : (
            <li key={c + idx} className={cx({ "is-active": idx === crumbs.length - 1 })}>
              <Link className="lnk" to={`/${crumbs.slice(1, idx + 1).join("/")}`}>
                {segmentMapping || wellKnownSegmentMapping || c}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Breadcrumb;
