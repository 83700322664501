import { useIsAuthenticated, useItsgToken } from "./authentication";
import { buildEndpointUrl, errorReportingTestReportFilter } from "./configuration";
import { fetchEnsureSuccess, NppFetchError, useSWRCurried } from "./fetching";
import { useState } from "react";
import { useAuth } from "oidc-react";
import xml2js from "xml2js";
import { parseISO } from "date-fns";
import { map, escapeXml } from "./misc";
import * as zod from "zod";

export type ErrorReportInputs = {
  subject: string;
  description: string;
  problemStartedOn?: Date;
  phone: string;
  extraContactPersons: { email: string }[];
  attachments: File[];
};

export type ErrorReportSubmissionState =
  | { kind: "initialized" }
  | {
      kind: "data-to-submit";
      data: ErrorReportInputs;
    }
  | {
      kind: "data-submitted-successfully";
      data: ErrorReportInputs;
      docId: number;
      toEmail: string;
    }
  | {
      kind: "data-submit-problem";
      data: ErrorReportInputs;
      problemDescription:
        | "NotAuthenticated"
        | "ItsgTokenMissing"
        | "EndpointMissing"
        | "EmailMissingFromUserProfile"
        | "Unauthorized"
        | "UnexpectedResponse"
        | "FileLoadFailed"
        | "UnknownProblem";
      error?: any;
    };

export const isRetryableSubmissionState = (state: ErrorReportSubmissionState): boolean =>
  state.kind === "data-submit-problem" &&
  ["NotAuthenticated", "EndpointMissing", "EmailMissingFromUserProfile"].includes(state.problemDescription) === false;

type LoadedFile = { file: File; base64Content: string };

function loadAllFiles(files: File[]): Promise<PromiseSettledResult<LoadedFile>[]> {
  return Promise.allSettled(
    files.map((file) => {
      return new Promise<{ file: File; base64Content: string }>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file); //Math.random() > 0.2 ? null! : file);
        reader.onload = () => {
          const dataUrl = reader.result ?? "";
          const valStr: string = typeof dataUrl === "string" ? dataUrl : Buffer.from(dataUrl).toString();
          resolve({
            file,
            base64Content: valStr
              .substr(valStr.indexOf(",") + 1)
              .trim()
              .replace(/^data:/, ""),
          });
        };
        reader.onerror = (error) => reject(error);
      });
    })
  );
}

export function useSubmitErrorReport() {
  const isAuthenticated = useIsAuthenticated();
  const userProfile = useAuth().userData?.profile;
  const itsgToken = useItsgToken();

  const [submissionState, setSubmissionState] = useState<Readonly<ErrorReportSubmissionState>>({ kind: "initialized" });
  // const [submissionState, setSubmissionState] = useState<Readonly<ErrorReportSubmissionState>>({
  //   kind: "data-submitted-successfully",
  //   toEmail: "rgarewgf@awewaef.dk",
  //   docId: 32165487,
  //   data: { attachments: [], description: "", extraContactPersons: [], phone: "", subject: "" },
  // });

  const submitErrorReport = (applicationId: number, applicationName: string) => async (data: ErrorReportInputs) => {
    setSubmissionState({ kind: "data-to-submit", data });

    const endpoint = buildEndpointUrl("errorReportingHost", "/npp/etrayservice")?.[0];

    if (!isAuthenticated || !!!userProfile) {
      setSubmissionState({ kind: "data-submit-problem", data, problemDescription: "NotAuthenticated" });
    } else if (!!!itsgToken) {
      setSubmissionState({ kind: "data-submit-problem", data, problemDescription: "ItsgTokenMissing" });
    } else if (endpoint === undefined) {
      setSubmissionState({ kind: "data-submit-problem", data, problemDescription: "EndpointMissing" });
    } else if (!!!userProfile.email) {
      setSubmissionState({ kind: "data-submit-problem", data, problemDescription: "EmailMissingFromUserProfile" });
    } else {
      const createProperty = (name: string, values: string | string[] | undefined) =>
        `<Property>
          <Name>${name}</Name>
          <Values>
            <Value>${[values].flat().filter((elm) => elm).join(`</Value>
            <Value>`)}</Value>
          </Values>
        </Property>`;

      const email = userProfile.email; // Need to take it out here to "help" the compiler know that it cannot be undefined later

      const filesAndContent = await loadAllFiles(data.attachments);
      const fileLoads = filesAndContent.reduce<{
        fulfilled: PromiseFulfilledResult<LoadedFile>[];
        rejected: PromiseRejectedResult[];
      }>((prev, cur) => ({ ...prev, [cur.status]: [...prev[cur.status], cur] }), { fulfilled: [], rejected: [] });

      if (fileLoads.rejected.length > 0) {
        setSubmissionState({
          kind: "data-submit-problem",
          data,
          problemDescription: "FileLoadFailed",
          error: fileLoads.rejected.map((fnc) => fnc.reason),
        });
      } else {
        fetchEnsureSuccess(endpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/xml; charset=utf-8",
            SOAPAction: "eTray.eTrayService/DocumentCreate",
            Authorization: `Bearer ${itsgToken}`,
          },
          body: `
                <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
                  xmlns:xsd="http://www.w3.org/2001/XMLSchema"
                  xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
                  <soap:Body>
                    <DocumentCreate xmlns="eTray.eTrayService">
                      <sDocType>SP Mobil Errors - IT applikationsfejlmelding</sDocType>
                      <sTray>SP Mobil Online</sTray>
                      <Properties>
                        ${[
                          createProperty("Created_by_user", userProfile.sub),
                          createProperty("SP_id", userProfile.spid),
                          createProperty("Kunde", userProfile.spid),
                          createProperty("Applikation", applicationName),
                          createProperty("App_id", `${applicationId}`),
                          createProperty("Emne", escapeXml(data.subject)),
                          createProperty("Beskrivelse", escapeXml(data.description)),
                          createProperty("Problem_Start_Dt", data.problemStartedOn?.toISOString()),
                          createProperty("Fornavn", userProfile.given_name),
                          createProperty("Efternavn", userProfile.family_name),
                          createProperty("Email", email),
                          createProperty("Telefonnummer", userProfile.phone_number),
                          createProperty("Sprog", "da"),
                          createProperty("Linkit", userProfile.linkit_number),
                          createProperty("Ekstra_email", data.extraContactPersons.map((cp) => cp.email).join(";")),
                        ].join("\n")}
                      </Properties>
                      <Files>
                        ${fileLoads.fulfilled
                          .map(
                            (f) =>
                              `<File>
                                <Name>${f.value.file.name}</Name>
                                <Data>${f.value.base64Content}</Data>
                              </File>`
                          )
                          .join("\n")}                  
                      </Files>                
                    </DocumentCreate>
                  </soap:Body>
                </soap:Envelope>`,
        })
          .then((res) => {
            if (res.status === 200) {
              res
                .text()
                .then((txt) => xml2js.parseStringPromise(txt))
                .then<any>((responseObj) => {
                  const foundDocIdStr = responseObj?.["soap:Envelope"]?.["soap:Body"]?.[0]?.DocumentCreateResponse?.[0]?.DocumentCreateResult?.[0];

                  const foundDocId = Number.parseInt(foundDocIdStr ?? "");
                  if (Number.isNaN(foundDocId)) {
                    setSubmissionState({
                      kind: "data-submit-problem",
                      problemDescription: "UnexpectedResponse",
                      data,
                    });
                  } else {
                    setSubmissionState({
                      kind: "data-submitted-successfully",
                      docId: foundDocId,
                      data,
                      toEmail: email,
                    });
                  }
                });
            } else {
              setSubmissionState({
                kind: "data-submit-problem",
                data,
                problemDescription: "UnexpectedResponse",
              });
            }
          })
          .catch((err) => {
            setSubmissionState({
              kind: "data-submit-problem",
              problemDescription: err.response?.status === 401 ? "Unauthorized" : "UnknownProblem",
              data,
              error: err,
            });
          });
      }
    }
  };

  return {
    submissionState,
    submitErrorReport,
  };
}

const errorReportsDtoSchema = zod.object({
  Error: zod.string(),
  ErrorCode: zod.string(),
  MultiResponse: zod.array(
    zod.object({
      Response: zod.array(
        zod.object({
          Name: zod.enum([
            "DocID",
            "CreatedDate",
            "ArchivedDate",
            "AppID",
            "Applikation",
            "Emne",
            "Beskrivelse",
            "Status",
            "StatusChangedOn",
            "ProblemStartedOn",
          ]),
          Value: zod.string(),
        })
      ),
    })
  ),
  Response: zod
    .tuple([
      zod.object({
        Name: zod.literal("ErrMsg"),
        Value: zod.string().nullable(),
      }),
      zod.object({
        Name: zod.literal("ErrNo"),
        Value: zod.string().nullable(),
      }),
    ])
    .optional(),
});

type ErrorReportsDto = zod.TypeOf<typeof errorReportsDtoSchema>;
type MultiResponseElement = ErrorReportsDto["MultiResponse"][number]["Response"][number];

const statusValues = ["Igangsat", "Fejlrettelse", "Lukket"] as const;
type StatusValue = typeof statusValues[number];

export const toStatusValue = (status?: string | null): StatusValue | "Ukendt" =>
  (status && statusValues.find((lbl) => lbl.localeCompare(status, "da") === 0)) || "Ukendt";

export type ErrorReport = {
  docId: number;
  createdDate: Date;
  archivedDate?: Date;
  applicationId: number;
  application: string;
  subject: string;
  description: string;
  status: StatusValue | "Ukendt";
  statusChangedDate: Date;
  problemStartedOn?: Date;
};

function parseEtrayDate(etrayDateStr?: string | null): Date | undefined {
  if (!etrayDateStr) {
    return undefined;
  }
  return parseISO(etrayDateStr);
}

function parseToErrorReport(pairs: MultiResponseElement[]): ErrorReport | false {
  const nameToPairMap = new Map<MultiResponseElement["Name"], MultiResponseElement>(pairs.map((p) => [p.Name, p]));
  const get = (name: MultiResponseElement["Name"]) => map(nameToPairMap.get(name)?.Value, (v) => (v === "[NODATA]" ? undefined : v));

  const docId = Number.parseInt(get("DocID") ?? "-1");
  if (docId === -1) {
    return false;
  }

  const report: ErrorReport = {
    docId,
    createdDate: parseEtrayDate(get("CreatedDate")) ?? new Date("INVALID"),
    archivedDate: parseEtrayDate(get("ArchivedDate")),
    applicationId: Number.parseInt(get("AppID") ?? "-1"),
    application: get("Applikation") ?? "",
    subject: get("Emne") ?? "",
    description: get("Beskrivelse") ?? "",
    status: toStatusValue(get("Status")),
    statusChangedDate: parseEtrayDate(get("StatusChangedOn")) ?? new Date(), // This default will make closed error reports show if StatusChangedOn is missing
    problemStartedOn: parseEtrayDate(get("ProblemStartedOn")),
  };

  return report;
}

const errorReportsFetcher = (url: string, token: string) =>
  fetchEnsureSuccess(url, {
    method: "POST",
    headers: { Authorization: `Bearer ${token}` },
    body: JSON.stringify(
      {
        ApiKey: "", // ITSG injects this
        Name: "", // ITSG injects this
        Data: [
          {
            Name: "",
            Value: "",
          },
        ],
      },
      null,
      2
    ),
    redirect: "follow",
  })
    //fetch("./errorReportingTest.json")
    //.then(makeFlaky(500, 2500, 0.5, "getting ErrorReports"))
    .then(async (rsp) => {
      const errorReportsDto = await rsp.json().then((json) => errorReportsDtoSchema.parse(json));

      if (errorReportsDto.Error || errorReportsDto.ErrorCode) {
        const error: NppFetchError = new Error(
          [
            errorReportsDto.ErrorCode,
            errorReportsDto.Error,
            ...(errorReportsDto.Response?.sort((r1, r2) => r1.Name.localeCompare(r2.Name)).map((r) => r.Value) ?? []),
          ].join("|")
        );
        error.response = rsp;
        throw error;
      }

      // All cases that are parsed correctly and which doesn't match the (configured) regex in errorReportingTestReportFilter
      const cases = errorReportsDto.MultiResponse.map((mr) => parseToErrorReport(mr.Response))
        .filter((rpt): rpt is ErrorReport => rpt !== false)
        .filter((rpt) => !errorReportingTestReportFilter.test(rpt.subject) && !errorReportingTestReportFilter.test(rpt.description));

      return cases;
    });

export function useErrorReports() {
  const isAuthenticated = useIsAuthenticated();
  const itsgToken = useItsgToken();

  const { data, error } = useSWRCurried<NppFetchError | Error>()(
    () => (!isAuthenticated || !!!itsgToken ? null : buildEndpointUrl("errorReportingHost", "/npp/etraysearchservice", itsgToken)),
    errorReportsFetcher
  );

  return {
    errorReports: data,
    errorReportsFetchError: error,
    isLoadingErrorReports: !data && !error,
  };
}
