import React from "react";
import { useDashboardContent } from "../store/dashboard";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { css } from "@emotion/css";
import FileIcon from "./shared/FileIcon";
import { parseUrl } from "../store/misc";
import DashboardSectionHeading from "./DashboardSectionHeading";

import OpenInNewIcon from "mdi-react/OpenInNewIcon";

const DashboardContentHighlights: React.FC = () => {
  const { dashboardContent } = useDashboardContent();

  return dashboardContent?.highlights && dashboardContent.highlights.items.length > 0 ? (
    <div className="block">
      <DashboardSectionHeading text={dashboardContent.highlightsLabel} />
      <div
        className={
          "columns is-multiline " +
          css`
            .column {
              min-width: 300px;
            }

            .icon {
              opacity: 0.8;
            }
          `
        }
      >
        {dashboardContent.highlights.items.map((entry, idx) => {
          switch (entry.__typename) {
            case "AssetItem":
              return (
                <div className="column is-relative" key={entry.sys.id + idx}>
                  <a
                    className="has-cta streched-link"
                    title={entry.title ?? undefined}
                    href={entry.asset?.url ?? "#"}
                    key={entry.sys.id + idx} // idx is used because the same entry can potentially occur in the list multiple times :(
                  >
                    <div className={`box is-shadowless is-fullheight cta-box cta-box-${idx % 3} is-flex is-flex-direction-column`}>
                      <p className="has-text-weight-bold pb-3">{entry.title ?? entry.asset?.title}</p>
                      <div className="is-size-8 pb-3">
                        {(entry.description?.json && documentToReactComponents(entry.description.json)) ?? entry.asset?.description}
                      </div>
                      <div className="icon is-flex-grow-1 is-align-items-flex-end">
                        <FileIcon {...entry.asset} />
                      </div>
                    </div>
                  </a>
                </div>
              );
            case "LinkItem":
              return (
                <div className="column is-relative" key={entry.sys.id + idx}>
                  <a
                    className="has-cta stretched-link"
                    title={entry.title ?? undefined}
                    href={entry.link ?? "#"}
                    target="_blank"
                    rel="noreferrer"
                    key={entry.sys.id + idx} // idx is used because the same entry can potentially occur in the list multiple times :(
                  >
                    <div className={`box is-shadowless is-fullheight cta-box cta-box-${idx % 3} is-flex is-flex-direction-column`}>
                      <p className="has-text-weight-bold pb-3">{entry.title}</p>
                      <div className="is-size-8 pb-3">{entry.description?.json && documentToReactComponents(entry.description.json)}</div>
                      <div className="is-flex is-flex-grow-1 is-align-items-flex-end">
                        <span className="icon">
                          <OpenInNewIcon />
                        </span>
                        <p className="is-size-8 pl-2 link-text">{entry.linkText || parseUrl(entry.link)?.hostname}</p>
                      </div>
                    </div>
                  </a>
                </div>
              );
            default:
              return null;
          }
        })}
      </div>
    </div>
  ) : null;
};

export default DashboardContentHighlights;
