import { contentfulConfig } from "./configuration";
import { ClientError, gql, request } from "graphql-request";
import { allLinkedVideoItems, map, toRoutingName } from "./misc";
import { docListFragment, itemListFragment } from "./contentfulShared";
import { useMobileServiceProvider } from "./mobileServiceProviders";
import { useSWRCurried } from "./fetching";

const productsFetcherGql = () => {
  return request<DeepArrayUnmaybefy<ProductsQuery>>(
    contentfulConfig.graphqlEndpoint,
    gql`
      query Products {
        # note the limit of 5 which is a "safeish" guess but if wrong it is not handled!
        products: productCollection(locale: "da-DK", limit: 5, order: [order_ASC]) {
          total
          items {
            sys {
              id
            }
            name
            title
            teaser
            description {
              json
            }
            # note the limit of 3 which is set in Contentful
            mainCtas: mainDocumentsCollection(locale: "da-DK", limit: 3) {
              total
              items {
                ...AssetFields
              }
            }
            # note the limit of 5 which is set in Contentful
            mainLists: mainListsCollection(locale: "da-DK", limit: 5) {
              total
              items {
                ... on DocumentList {
                  ...DocList
                }
                ... on ItemList {
                  ...ItmList
                }
              }
            }
            # note the limit of 5 which is set in Contentful
            secondaryLists: secondaryListsCollection(locale: "da-DK", limit: 5) {
              total
              items {
                ... on DocumentList {
                  ...DocList
                }
                ... on ItemList {
                  ...ItmList
                }
              }
            }
          }
        }
      }
      ${docListFragment}
      ${itemListFragment}
    `
  );
};

export function useProducts() {
  const { data, error } = useSWRCurried<ClientError>()("contentful/products", productsFetcherGql);
  const { mobileServiceProvider } = useMobileServiceProvider();

  return {
    products: !!!mobileServiceProvider
      ? undefined
      : map(
          data?.products?.items.map((p) => ({
            ...p,
            routingName: toRoutingName(p.name),
            allLinkedVideoItems: allLinkedVideoItems(p),
          })),
          (prods) =>
            prods
              ? map(
                  prods.filter((p) => mobileServiceProvider.productAccess?.items.some((pAcc) => pAcc.sys.id === p.sys.id)),
                  (prodsFiltered) => ({
                    all: {
                      products: prods,
                      findByRoutingName: (routingName: string) => prods.find((p) => toRoutingName(p.name) === routingName.toLowerCase()),
                      findNameByRoutingName: (routingName: string) =>
                        prods.find((p) => toRoutingName(p.name) === routingName.toLowerCase())?.name ?? "",
                    },
                    filtered: {
                      products: prodsFiltered,
                      findByRoutingName: (routingName: string) => prodsFiltered.find((p) => toRoutingName(p.name) === routingName.toLowerCase()),
                      findNameByRoutingName: (routingName: string) =>
                        prodsFiltered.find((p) => toRoutingName(p.name) === routingName.toLowerCase())?.name ?? "",
                    },
                  })
                )
              : undefined
        ),
    productsFetchError: error,
    isLoadingProducts: !data && !error,
  };
}
