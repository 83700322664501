import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { NppAuthProvider } from "./store/authentication";
import { SWRConfig, cache as swrCache } from "swr";
import { GtmProvider } from "./store/gtm";
import { NppContextProvider } from "./store/nppContext";

import "./styles/index.scss";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href") ?? undefined;

// Make sure to clear SWR cache when logging out otherwise a new user could potentially see old data meant for another user
const clearSwrCache = () => swrCache.clear();

ReactDOM.render(
  <React.StrictMode>
    <GtmProvider>
      <NppAuthProvider onSignOut={clearSwrCache}>
        <SWRConfig value={{ refreshInterval: 3 * 60 * 1000, dedupingInterval: 1000 }}>
          <NppContextProvider>
            <BrowserRouter basename={baseUrl}>
              <App />
            </BrowserRouter>
          </NppContextProvider>
        </SWRConfig>
      </NppAuthProvider>
    </GtmProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
