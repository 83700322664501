import React from "react";
import { useIsAuthenticated } from "../store/authentication";
import { useAuth } from "oidc-react";
import { Redirect } from "react-router-dom";

import LoginIcon from "mdi-react/LoginIcon";

const LoggedOut: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const auth = useAuth();

  return (
    <section className="section">
      {isAuthenticated && <Redirect to="/" />}
      <div className="container is-max-widescreen is-flex is-flex-direction-column is-align-items-center pt-6">
        <div className="is-size-5 has-text-weight-bold pb-5">Du er nu logget ud</div>
        <button
          className="button is-rounded is-primary pt-3 px-5"
          onClick={() => auth.signIn({ state: { postSignInPath: "/" } })}
        >
          Log ind
          <span className="icon ml-1 pl-1 pb-1">
            <LoginIcon />
          </span>
        </button>
      </div>
    </section>
  );
};

export default LoggedOut;
