import React from "react";
import { assertUnreachable } from "./misc";

const initialNppContextState = { areMenusActive: true, isTopMenuMenuActive: false };

export type NppContextState = typeof initialNppContextState;

export type NppContextAction =
  | {
      type: "ToggleMenus";
      payload?: boolean;
    }
  | {
      type: "ToggleTopMenuActive";
    }
  | {
      type: "Noop";
    };

const NppContext = React.createContext<{
  state: NppContextState;
  dispatch: React.Dispatch<NppContextAction>;
}>({
  state: initialNppContextState,
  dispatch: () => undefined,
});

const nppContextReducer = (state: NppContextState, action: NppContextAction): NppContextState => {
  switch (action.type) {
    case "ToggleMenus":
      return state.areMenusActive === action.payload ? state : { ...state, areMenusActive: action.payload ?? !state.areMenusActive };
    case "ToggleTopMenuActive":
      return { ...state, isTopMenuMenuActive: !state.isTopMenuMenuActive };
    case "Noop":
      return state;
    default:
      // Just as a belt and suspenders approach we will throw in case the reducer is called dynamically in non-TypeScript context with unknown action.type value
      return assertUnreachable(action);
  }
};

export const useNppContext = () => React.useContext(NppContext);

/**
 *This component sits here in thbis file in order to keep NppContext and nppContextReducer private inside this module
 */
export const NppContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = React.useReducer(nppContextReducer, initialNppContextState);
  return <NppContext.Provider value={{ state, dispatch }}>{children}</NppContext.Provider>;
};
