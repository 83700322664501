import React from "react";
import { css } from "@emotion/css";
import { useLocation } from "react-router-dom";
import Badge from "./Badge";

export type TabId = string | number;

export type TabItem<T extends TabId> = { id: T; label: string | JSX.Element; badgeContent?: string | number | JSX.Element };

export type TabsProps<T extends TabId> = {
  tabs: readonly TabItem<T>[];
  selectedTabId: number;
  setSelectedTab: (id: T) => void;
};

function Tabs<T extends TabId>({ tabs, selectedTabId, setSelectedTab }: TabsProps<T>) {
  const { pathname, search } = useLocation();

  return (
    <div
      className={
        "tabs " +
        css`
          &.tabs {
            ul {
              margin-left: 0;
              flex-shrink: 1;
              flex-wrap: wrap;

              li + li {
                margin-right: 0.5rem;
              }
            }

            ul,
            a {
              border-bottom-color: transparent;
              border-bottom-width: 2px;
              outline: none;
            }

            a:hover,
            a:active,
            a:focus {
              border-bottom-color: var(--color-primary-light);
            }

            li.is-active a {
              font-weight: bold;
              color: var(--color-dark);
              border-bottom-color: var(--color-primary);
            }
          }
        `
      }
    >
      <ul className="is-size-7">
        {tabs.map((tab) => (
          <li key={tab.id} className={selectedTabId === tab.id ? "is-active" : ""}>
            <a
              className="p-0 pt-2 mr-4"
              tabIndex={selectedTabId === tab.id ? -1 : 0}
              href={pathname + search}
              onKeyDown={(e: React.KeyboardEvent<HTMLAnchorElement>) => {
                if (e.key === " " || e.key === "Enter") {
                  e.preventDefault();
                  setSelectedTab(tab.id);
                }
              }}
              onClick={(e) => {
                e.preventDefault();
                setSelectedTab(tab.id);
              }}
            >
              {tab.label} {tab.badgeContent !== undefined && <Badge>{tab.badgeContent}</Badge>}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Tabs;
