import { css, cx } from "@emotion/css";
import React from "react";
import { mq } from "../../styles/styleUtils";
import SubMenuVertical from "./SubMenuVertical";
import { useNppContext } from "../../store/nppContext";

export type SideMenuData = { title: string; description: string; body: JSX.Element };

const asideStyles = css`
  display: flex;
  align-items: flex-start;

  z-index: 10;

  background-color: white;

  &.has-side-menu {
    border-right: solid 1px var(--color-grey-lighter);
  }

  .sub-menu {
    width: 4.75rem;
  }

  .side-menu {
    width: 15.25rem;
  }

  transition: left 300ms ease-out;

  ${mq.touch} {
    position: absolute;
    left: -20rem;
    height: 100%;

    &.is-active {
      left: 0;
    }

    border-right: solid 1px var(--color-primary-dark);
  }
`;

const Page: React.FC<{
  noSubMenu?: boolean;
  noFooter?: boolean;
  sideMenuData?: SideMenuData;
  whitePage?: boolean;
}> = ({ noSubMenu, noFooter, sideMenuData, whitePage, children }) => {
  const { state, dispatch } = useNppContext();

  const closeMenusOnNextRenderRef = React.useRef(true);
  React.useEffect(() => {
    if (closeMenusOnNextRenderRef.current && state.areMenusActive) {
      closeMenusOnNextRenderRef.current = false;
      dispatch({ type: "ToggleMenus", payload: false });
    }
  });

  return (
    <div className={"is-flex is-relative" + (whitePage ? " has-background-white" : "")}>
      <aside
        onClick={(e) => {
          if ((e.target as HTMLElement).tagName !== "NAV") {
            closeMenusOnNextRenderRef.current = true;
          }
        }}
        className={cx({ [asideStyles]: true, "has-side-menu": !!sideMenuData, "is-active": state.areMenusActive })}
      >
        {!noSubMenu && <SubMenuVertical />}

        {sideMenuData && (
          <nav
            className={
              "side-menu is-flex is-flex-direction-column has-background-white is-flex-shrink-0 " +
              css`
                position: sticky;
                top: 0;

                > * {
                  padding: 1.1rem 1rem 0.9rem 1rem;
                }

                .side-menu-header {
                  padding-top: 3.75rem;
                  border-bottom: solid 1px var(--color-grey-lighter);
                  margin-bottom: 1rem;
                }

                > a {
                  color: black;
                  margin-bottom: 2px;

                  &:focus,
                  &:active,
                  &:hover,
                  &:focus-within {
                    background-color: var(--color-success-light);
                    color: black;
                  }

                  &.is-active {
                    background-color: var(--color-success);
                  }
                }
              `
            }
          >
            <div onClick={(e) => e.stopPropagation()} className="side-menu-header">
              <p className="is-size-6 has-text-weight-bold">{sideMenuData.title}</p>
              <p className="is-size-8">{sideMenuData.description}</p>
            </div>
            {sideMenuData.body}
          </nav>
        )}
      </aside>

      <div className="is-flex is-flex-direction-column is-flex-grow-1">
        {children}

        {!noFooter && (
          <footer className={"footer is-size-8 py-4 has-text-right" + (whitePage ? " has-background-white" : "")}>
            <span className="has-text-weight-bold" style={{ fontFamily: "Noto Color Emoji" }}>
              &copy;
            </span>{" "}
            {new Date().getFullYear()} Nuuday Mobile Partners. Alle rettigheder forbeholdt.
          </footer>
        )}
      </div>
    </div>
  );
};

export default Page;
