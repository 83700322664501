import React from "react";
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";

// Note the empty object literal for "dataLayer". The value of "dataLayer" is pushed into window.dataLayer by code in react-gtm-hook.
// Since "undefined" breaks our cookie consent script we supply an empty dataLayer object value instead :/
const gtmConfig = {
  id: process.env.REACT_APP_GTM_CONTAINER_ID!,
  dataLayer: {},
};

export const GtmProvider: React.FC<{ children: JSX.Element }> = ({ children }) =>
  gtmConfig.id ? GTMProvider({ state: gtmConfig, children }) : children;
