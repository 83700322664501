import React, { useEffect, useState } from "react";
import OperationsInformationTable from "./OperationsInformationTable";
import {
  FasDataItemPlanningStatus,
  useGetFasData,
  FasData,
  calculateFasDataStats,
  includesAnyOfFullWords,
  wellKnownGeography,
} from "../store/operations";
import Tabs, { TabItem } from "./shared/Tabs";
import OperationsInformationPageHeader from "./OperationsInformationPageHeader";
import Page from "./shared/Page";
import { map, splitToNormalizedWords } from "../store/misc";
import SearchTextBox from "./shared/SearchTextBox";
import Badge from "./shared/Badge";

const getTabsWithCounts = (stats: FasData["stats"] | undefined, selectedGeoFilters: number): TabItem<FasDataItemPlanningStatus>[] => {
  const effectiveGeoFilters = wellKnownGeography.getFlaggedOrAllValues(selectedGeoFilters);

  const createTab = (planningStatus: FasDataItemPlanningStatus, label: string) => ({
    id: planningStatus,
    label,
    badgeContent: stats ? effectiveGeoFilters.map((gv) => stats[gv][planningStatus]).reduce((a, b) => a + b, 0) : undefined,
  });

  return [
    createTab(FasDataItemPlanningStatus.Unplanned, "Aktuelle fejl"),
    createTab(FasDataItemPlanningStatus.Planned, "Planlagt"),
    createTab(FasDataItemPlanningStatus.Completed, "Løste"),
  ];
};

const OperationsInformation: React.FC = () => {
  const [selectedGeoFilters, setSelectedGeoFilters] = useState(0);
  const [selectedTabId, setSelectedTabId] = useState(FasDataItemPlanningStatus.Unplanned);
  const [globalTextSearch, setGlobalTextSearch] = useState("");
  const { fasData } = useGetFasData();
  const [effectiveStats, setEffectiveStats] = useState(fasData?.stats);
  const [tabs, setTabs] = useState(getTabsWithCounts(effectiveStats, selectedGeoFilters));

  useEffect(() => {
    const searchWords = splitToNormalizedWords(globalTextSearch);
    const textFilteredItems = fasData?.all.filter((item) => includesAnyOfFullWords(item, searchWords));
    const effectiveStats = textFilteredItems === undefined ? fasData?.stats : calculateFasDataStats(textFilteredItems);

    setEffectiveStats(effectiveStats);
    setTabs(getTabsWithCounts(effectiveStats, selectedGeoFilters));
  }, [fasData, selectedGeoFilters, globalTextSearch]);

  return (
    <Page>
      <section className="section">
        <div className="container is-max-widescreen">
          <OperationsInformationPageHeader
            title="Driftsinformation"
            subtitle="Her kan du se en oversigt over planlagte og akutte driftsforstyrrelser på mobilnetværket"
          />

          <nav className="level pt-5 pb-3 is-flex-align-items-flex-start is-mobile">
            <div className="level-left is-flex-wrap-wrap is-flex-align-items-stretch is-flex-shrink-1">
              <div className="level-item pt-4 pr-3 is-flex-grow-0 is-size-7">
                <span className="pt-1">Områder</span>
              </div>
              {wellKnownGeography.values.map((geoFltr) => (
                <div key={geoFltr} className="level-item pt-3 is-flex-grow-0">
                  <button
                    className={
                      "button is-rounded pt-3 is-size-7 " +
                      (wellKnownGeography.isValueFlagged(selectedGeoFilters, geoFltr) ? " has-background-grey-lighter" : " has-background-white")
                    }
                    onClick={(e) =>
                      setSelectedGeoFilters((prev) =>
                        map(wellKnownGeography.calcFlag(geoFltr), (flag) => (e.ctrlKey ? prev ^ flag : prev === flag ? 0 : flag))
                      )
                    }
                  >
                    {geoFltr === "Flere danske områder" ? "Flere områder" : geoFltr}
                    {typeof fasData?.stats[geoFltr].total === "number" && <Badge>{effectiveStats?.[geoFltr].total}</Badge>}
                  </button>
                </div>
              ))}
            </div>
          </nav>

          <div className="is-flex is-justify-content-space-between is-align-items-flex-start is-flex-wrap-wrap">
            <Tabs tabs={tabs} selectedTabId={selectedTabId} setSelectedTab={setSelectedTabId} />
            <SearchTextBox onSearchTextChanged={setGlobalTextSearch} />
          </div>

          <OperationsInformationTable
            selectedGeoFilters={selectedGeoFilters || wellKnownGeography.allFlag}
            selectedPlanningStatus={selectedTabId}
            globalTextSearch={globalTextSearch}
          />
        </div>
      </section>
    </Page>
  );
};

export default OperationsInformation;
