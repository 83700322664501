import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { Routes } from "./store/routing";
import AuthorizeRoute from "./components/shared/AuthorizeRoute";
import Layout from "./components/shared/Layout";
import Home from "./components/Home";
import OperationsInformation from "./components/OperationsInformation";
import OperationsInformationItem from "./components/OperationsInformationItem";
import CoverageMap from "./components/CoverageMap";
import Applications from "./components/Applications";
import Profile from "./components/Profile";
import Contact from "./components/Contact";
import LoginRedirect from "./components/LoginRedirect";
import LoggedOut from "./components/LoggedOut";
import News from "./components/News";
import Dashboard from "./components/Dashboard";
import Products from "./components/Products";
import Library from "./components/Library";
import Videos from "./components/Videos";
import { useIsAuthenticated } from "./store/authentication";

const App: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const HomeOrRedirect = isAuthenticated ? () => <Redirect to={Routes.Dashboard.create({})} /> : Home;

  return (
    <Layout>
      <Switch>
        <Route exact path={Routes.Home.template()} component={HomeOrRedirect} />
        <Route exact path={Routes.OperationsInformation.template()} component={OperationsInformation} />
        <Route exact path={Routes.OperationsInformationFasId.template()} component={OperationsInformationItem} />
        <Route exact path={Routes.OperationsCoverageMap.template()} component={CoverageMap} />
        <Route exact path={Routes.Contact.template()} component={Contact} />
        <AuthorizeRoute path={Routes.Dashboard.template()} component={Dashboard} />
        <AuthorizeRoute path={Routes.Products.template()} component={Products} />
        <AuthorizeRoute path={Routes.Apps.template()} component={Applications} />
        <AuthorizeRoute path={Routes.Library.template()} component={Library} />
        <AuthorizeRoute path={Routes.News.template()} component={News} />
        <Route exact path={Routes.Videos.template()} component={() => <Redirect to={Routes.Home.template()} />} />
        <AuthorizeRoute exact path={Routes.VideosVideoIdRouteName.template()} component={Videos} />
        <AuthorizeRoute exact path={Routes.Profile.template()} component={Profile} />
        <Route exact path={Routes.AuthLoginRedirect.template()} component={LoginRedirect} />
        <Route exact path={Routes.AuthLoggedOut.template()} component={LoggedOut} />
        <Route>
          <div
            style={{
              padding: "300px 0",
              display: "flex",
              flexDirection: "column",
              color: "black",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "calc(10px + 2vmin)",
            }}
          >
            404 - Nuuday Partner Portal
          </div>
        </Route>
      </Switch>
    </Layout>
  );
};

export default App;
