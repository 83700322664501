import React from "react";
import { RouteParams, Routes } from "../store/routing";
import { useLocation, matchPath } from "react-router-dom";
import DashboardMessagesOverview from "./DashboardMessagesOverview";
import DashboardMessagesMessage from "./DashboardMessagesMessage";
import { Message } from "../store/messages";

export type CreateRouteToMessage = (
  params: RouteParams<"DashboardMessagesDirectMsgIdMsgRouteName" | "DashboardMessagesMsgIdMsgRouteName">,
  msgType: Message["msgType"]
) => string;

export default function DashboardMessages() {
  const matched = matchPath<RouteParams<"DashboardMessagesDirectMsgIdMsgRouteName" | "DashboardMessagesMsgIdMsgRouteName">>(
    useLocation().pathname,
    Routes.DashboardMessagesMsgIdMsgRouteName.template()
  );

  const show = !!!matched ? "overview" : "details";

  return (
    <>
      <div className={show === "overview" ? "" : "is-hidden"}>
        <DashboardMessagesOverview />
      </div>
      <div className={show === "details" ? "" : "is-hidden"}>
        <DashboardMessagesMessage
          params={matched?.params ?? { msgId: "", msgRoutingName: "" }}
          createRouteToMessage={Routes.DashboardMessagesMsgIdMsgRouteName.create}
          backUrl={Routes.DashboardMessages.create({})}
        />
      </div>
    </>
  );
}
