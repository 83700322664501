import React from "react";
import { useNewsItems } from "../store/news";
import NewsTable from "./shared/NewsTable";
import PageHeader from "./shared/PageHeader";

const NewsArchive: React.FC = () => {
  const { newsItems } = useNewsItems();

  return (
    <>
      <PageHeader title="Nyheder arkiv" />

      <NewsTable data={newsItems?.archived ?? []} pageSize={8} noNewsText="Der er ingen arkiverede nyheder" withControls={true} />
    </>
  );
};

export default NewsArchive;
