import React from "react";
import NppHero from "./shared/NppHero";
import { Link } from "react-router-dom";
import { css } from "@emotion/css";
import Page from "./shared/Page";

import { ReactComponent as OperationsIcon } from "../styles/icon-operations-information.svg";
import { ReactComponent as CoverageMapIcon } from "../styles/icon-coverage-map.svg";
import { ReactComponent as CustomerServiceIcon } from "../styles/icon-customer-service.svg";

const data = [
  {
    svg: <OperationsIcon />,
    title: "Driftsinformation",
    description: <>Her kan du tjekke planlagt arbejde og uforudsete udfordringer</>,
    link: { to: "/operations/information", text: "Se oversigt" },
  },
  {
    svg: <CoverageMapIcon />,
    title: "Dækningskort",
    description: (
      <>
        Oversigt over dækning af 3G, 4G, 5G
        <br /> og tale i Danmark
      </>
    ),
    link: { to: "/operations/coverage-map", text: "Se dækningskort" },
  },
  {
    svg: <CustomerServiceIcon />,
    title: "Kontakt",
    description: <>Ring eller skriv til os. Vi besvarer din henvendelse så hurtigt som muligt</>,
    link: { to: "/contact", text: "Kontakt os" },
  },
];

const Home: React.FC = () => (
  <Page>
    <NppHero
      title="Nuuday Partner Portal"
      subtitle="Her kan du finde driftsinformation på din IT-applikation og tjeneste lokalt og bredt i Danmark"
    />

    <section className="section">
      <div className="content-container">
        <div className="columns">
          {data.map((d) => (
            <div
              key={d.title}
              className={
                "column " +
                css`
                  margin-left: 0.5rem;
                  margin-right: 0.5rem;
                `
              }
            >
              <Link to={d.link.to} className="has-cta">
                <div className="box is-fullheight has-cta is-flex is-flex-direction-column is-align-items-center has-text-centered py-6 px-5">
                  {d.svg}
                  <p className="is-size-5 has-text-weight-semibold mt-6">{d.title}</p>
                  <p className="is-size-7 pt-3 pb-4 mb-4">{d.description}</p>
                  <span
                    className={
                      "button is-rounded is-size-7 is-link pt-3 " +
                      css`
                        margin-top: auto;
                        margin-bottom: 2.5rem;
                      `
                    }
                  >
                    {d.link.text}
                  </span>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  </Page>
);

export default Home;
