import React from "react";
import { UseSortByColumnProps } from "react-table";

import ChevronUpIcon from "mdi-react/ChevronUpIcon";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import SortIcon from "mdi-react/SortIcon";

const TableHeaderRenderer: React.FC<{
  label: string;
  spanWrapperClassName?: string;
  column: Pick<UseSortByColumnProps<object>, "canSort" | "isSorted" | "isSortedDesc">;
}> = ({ label, spanWrapperClassName, column }) => (
  <span className={spanWrapperClassName ?? "is-flex"}>
    {label}
    {column.canSort && (
      <span className="icon has-text-grey-dark">
        {column.isSorted ? column.isSortedDesc ? <ChevronDownIcon /> : <ChevronUpIcon /> : <SortIcon size="1rem" className="has-text-grey-lighter" />}
      </span>
    )}
  </span>
);

export function createHeaderRenderer(
  label: string,
  spanWrapperClassName?: string
): React.FC<{ column: Pick<UseSortByColumnProps<object>, "canSort" | "isSorted" | "isSortedDesc"> }> {
  return ({ column }) => <TableHeaderRenderer {...{ label, spanWrapperClassName, column }} />;
}

export default TableHeaderRenderer;
