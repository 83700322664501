import React from "react";
import { css } from "@emotion/css";

const badgeStyles = css`
  font-size: 0.8125rem;
  color: var(--color-grey-dark);

  &:before {
    content: "(";
    padding-left: 0.5em;
  }
  &:after {
    content: ")";
  }
`;

const Badge: React.FC = ({ children }) => <span className={badgeStyles}>{children}</span>;

export default Badge;
