import { contentfulConfig } from "./configuration";
import { ClientError, gql, request } from "graphql-request";
import { videoItemFields } from "./contentfulShared";
import { useSWRCurried } from "./fetching";
import { toRoutingName } from "./misc";

const videoFetcher = (_: string, id: VideoItemQueryVariables["id"]) => {
  return request<DeepArrayUnmaybefy<VideoItemQuery>>(
    contentfulConfig.graphqlEndpoint,
    gql`
      query VideoItem($id: String!) {
        videoItem(locale: "da-DK", id: $id) {
          ...VideoItemFields
        }
      }
      ${videoItemFields}
    `,
    {
      id,
    }
  ).then((data) => {
    if (!!!data.videoItem) {
      throw new Error(`VideoItem with ID '${id}' not found`);
    }
    return data.videoItem!;
  });
};

export function useVideoItem(id: string | undefined) {
  const { data, error } = useSWRCurried<ClientError | Error>()(["contentful/video", id], videoFetcher);
  const videoItem = data ? { ...data, routingName: toRoutingName(data.title) } : undefined;

  return {
    videoItem,
    videoFetchError: error,
    isLoadingVideoItem: !data && !error,
  };
}
