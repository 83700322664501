import React from "react";
import { useDirectMessages } from "../store/messages";
import Loader from "./shared/Loader";
import LoadingProblemBanner from "./shared/LoadingProblemBanner";
import { useMobileServiceProvider } from "../store/mobileServiceProviders";
import { useDashboardContent } from "../store/dashboard";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { css } from "@emotion/css";
import { useApplications } from "../store/applications";
import { useErrorReports } from "../store/errorReporting";
import { Routes } from "../store/routing";
import { Link } from "react-router-dom";
import { mq } from "../styles/styleUtils";
import { assertUnreachable } from "../store/misc";
import DashboardSectionHeading from "./DashboardSectionHeading";
import { useContactContent } from "../store/contact";

import ChevronLeftIcon from "mdi-react/ChevronLeftIcon";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";

const DashboardTopRowDirectMessages: React.FC = () => {
  const spName = useMobileServiceProvider().mobileServiceProvider?.name;
  const { directMessages, isLoadingDirectMessages, directMessagesFetchError } = useDirectMessages();
  const [msgIdx, setMsgIdx] = React.useState(0);
  const selectedMessage = directMessages?.[msgIdx % directMessages.length];

  const MessageStatusDisplay = ({ status }: { status: StatusHue | undefined }) => {
    switch (status) {
      case undefined:
        return <span>&nbsp;</span>;
      case "Green":
        return <span className="has-text-success">Drift: Normal</span>;
      case "Yellow":
        return <span className="has-text-warning">Drift: Mindre driftsforstyrrelse</span>;
      case "Red":
        return <span className="has-text-danger">Drift: Ude af drift</span>;
      default:
        assertUnreachable(status);
    }
  };

  return (
    <div
      className={
        "box is-size-7 is-fullheight p-0 has-text-centered " +
        css`
          &.box {
            .loader-container {
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              min-height: 11rem;

              .loader-wrapper {
                background-color: white !important;

                min-height: 11rem;

                .loader {
                  height: 75px;
                  width: 75px;
                }
              }
            }

            .message-title {
              line-height: 1.25;
            }

            .content {
              line-height: 1.25;
              margin: 2.5rem;
            }

            footer {
              > * {
                width: 100%;
              }
            }

            .p-container {
              padding: 0.15rem 0.2rem 0 0;

              color: var(--color-grey-lighter);

              .p-prv,
              .p-nxt {
                display: flex;
                align-items: center;
              }

              button {
                color: var(--color-grey-lighter);

                display: flex;
                align-items: center;

                padding: 0;

                background-color: transparent;
                border: 1px solid transparent;
                border-radius: 2px;
              }

              .items-shown-indicator {
                padding-top: 0.3em;
                padding-right: 1em;

                span + span:before {
                  content: "/";
                  margin: 0.125rem;
                }

                ${mq.mobile} {
                  padding-right: 0.5em;
                }
              }

              &.is-active {
                color: black;

                button {
                  cursor: pointer;

                  color: var(--color-grey-dark);

                  &:focus,
                  &:active,
                  &:hover,
                  &:focus-within {
                    border-color: var(--color-primary);
                    color: var(--color-primary);
                  }
                }
              }
            }
          }
        `
      }
    >
      <Loader isLoading={isLoadingDirectMessages}>
        <LoadingProblemBanner show={Boolean(!!!directMessages && directMessagesFetchError)}>
          Der er i øjeblikket problemer med at hente beskeder
        </LoadingProblemBanner>

        <div className="has-text-left pt-3 px-4 is-size-8 has-text-weight-bold">
          <MessageStatusDisplay status={selectedMessage?.status} />
        </div>

        {directMessages &&
          (selectedMessage ? (
            <div className="content">
              <h6 className="message-title is-size-5">{selectedMessage.title}</h6>
              {selectedMessage.details && documentToReactComponents(selectedMessage.details)}
            </div>
          ) : (
            <div className="p-4 has-text-weight-bold">Der er i øjeblikket ingen beskeder stilet direkte til {spName}</div>
          ))}

        <footer className="is-flex is-align-items-flex-end is-justify-content-space-between pr-1 pb-2 pl-4">
          <Link className="is-flex is-align-items-center is-inverted pl-1" to={Routes.DashboardMessages.create({})}>
            Se historik
          </Link>

          {selectedMessage && (
            <>
              <Link
                className="pb-5 is-inverted has-text-weight-bold"
                to={Routes.DashboardMessagesDirectMsgIdMsgRouteName.create({
                  msgId: selectedMessage.sys.id,
                  msgRoutingName: selectedMessage.routingName,
                })}
              >
                Læs mere
              </Link>

              <nav
                className={`p-container is-flex is-align-items-flex-end is-justify-content-flex-end${directMessages.length > 1 ? " is-active" : ""}`}
                role="navigation"
                aria-label="pagination"
              >
                <div className="p-prv pr-1">
                  <div className="items-shown-indicator white-space-nowrap">
                    <span>{msgIdx + 1}</span>
                    <span>{directMessages.length}</span>
                  </div>
                  <button onClick={() => setMsgIdx((i) => (--i < 0 ? directMessages.length - 1 : i))}>
                    <span className="icon">
                      <ChevronLeftIcon />
                    </span>
                  </button>
                </div>
                <div className="p-nxt pr-1">
                  <button onClick={() => setMsgIdx((i) => (i + 1) % directMessages.length)}>
                    <span className="icon">
                      <ChevronRightIcon />
                    </span>
                  </button>
                </div>
              </nav>
            </>
          )}
        </footer>
      </Loader>
    </div>
  );
};

const DashboardTopRowItApplications: React.FC = () => {
  const { applications } = useApplications();
  const { errorReports } = useErrorReports();

  const { errorReportsCount, total } =
    (applications?.all &&
      errorReports
        ?.filter((rpt) => rpt.status !== "Lukket")
        .reduce(
          (acc, cur) => {
            acc.errorReportsCount.set(cur.applicationId, (acc.errorReportsCount.get(cur.applicationId) ?? 0) + 1);
            acc.total++;
            return acc;
          },
          {
            errorReportsCount: new Map<number, number>(applications.all.map((app) => [app.applicationId, 0])),
            total: 0,
          }
        )) ||
    {};

  return (
    <div
      className={
        "box is-flex is-flex-direction-column is-justify-content-center is-align-items-center " +
        css`
          font-weight: bold;

          .error-count {
            font-size: 0.8125rem;
            color: var(--color-grey-dark);

            &:before {
              content: "(";
              padding-left: 0.25em;
            }
            &:after {
              content: ")";
            }
          }

          ul {
            li {
              line-height: 2;

              ::before {
                content: "\\2022";
                font-size: 3.5rem;
                line-height: 0;
                display: inline-block;
                vertical-align: middle;
                margin-right: 0.25rem;
              }

              &.success::before {
                color: var(--color-success);
              }
              &.danger::before {
                color: var(--color-danger);
              }
            }
          }
        `
      }
    >
      <div>IT Driftstatus</div>
      <div className="my-3">
        {applications?.all && (
          <ul className="mb-1">
            {applications.all.map((app) => {
              const count = (errorReportsCount?.get(app.applicationId) ?? "?") || false; // the value false doesn't get rendered where as the value 0 would (and we don't want 0 rendered)
              return (
                <li key={app.applicationId} className={count === "?" ? "" : count ? "danger" : "success"}>
                  <Link to={Routes.AppsAppRouteName.create({ appRoutingName: app.routingName })} className="is-inverted">
                    {app.applicationName} {count && <span className="error-count">{count}</span>}
                  </Link>
                </li>
              );
            })}
          </ul>
        )}
      </div>
      <div className="is-size-8 has-text-weight-normal">
        {total === 0 ? "Ingen aktuelle IT fejlmeldinger" : total === 1 ? "1 aktuel fejlmelding" : `${total ?? "?"} aktuelle fejlmeldninger`}
      </div>
    </div>
  );
};

const DashoardTopRowContactInfo: React.FC = () => {
  const { contactContent } = useContactContent();
  const { dashboardContent } = useDashboardContent();

  return (
    <div className={"box has-text-white has-background-primary has-text-centered px-2"}>
      <div className="pb-5">Kontakt support</div>
      <div className="is-size-5 has-text-weight-bold pb-4 white-space-nowrap">
        <a href={`tel:${contactContent?.contactPhone}`} className="has-text-white">
          {contactContent?.contactPhone}
        </a>
      </div>
      <div className="is-size-8 white-space-nowrap">
        {dashboardContent?.topRowOpeningHours?.json &&
          documentToReactComponents(dashboardContent.topRowOpeningHours.json, {
            renderText: (text) => text.split(/\n/).flatMap((text, i) => [i > 0 && <br />, text]),
          })}
      </div>
    </div>
  );
};

const DashboardTopRow: React.FC = () => {
  const { dashboardContent } = useDashboardContent();

  return (
    <div className="block">
      <DashboardSectionHeading text={dashboardContent?.topRowLabel} />
      <div className="columns">
        <div className="column">
          <DashboardTopRowDirectMessages />
        </div>
        <div className="column">
          <div
            className={
              "columns is-desktop " +
              css`
                .box {
                  height: 11rem;
                }
              `
            }
          >
            <div className="column is-half-desktop">
              <DashboardTopRowItApplications />
            </div>
            <div className="column is-half-desktop">
              <DashoardTopRowContactInfo />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardTopRow;
