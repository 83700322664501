import React from "react";
import PageHeader from "./shared/PageHeader";
import Loader from "./shared/Loader";
import Breadcrumb from "./shared/Breadcrumb";
import LoadingProblemBanner from "./shared/LoadingProblemBanner";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { css } from "@emotion/css";
import { useRealeaseCalendar } from "../store/releaseCalendar";
import DateTimeStamp from "./shared/DateTimeStamp";
import HistogramMonthRangePicker from "./shared/HistogramMonthRangePicker";
import { addMonths, isAfter, isBefore, startOfMonth } from "date-fns";

const calendarStyles = css`
  .app-calendar {
    display: grid;
    grid-template-columns: auto auto 1fr;
    grid-template-rows: auto;

    & > * {
      padding: 0.5em 1rem 0.2em 1rem;

      &:not(header) {
        cursor: pointer;
      }

      &:first-child {
        border-top-left-radius: var(--radius-large);
        border-top-right-radius: var(--radius-large);
      }

      &:last-child {
        border-bottom-left-radius: var(--radius-large);
        border-bottom-right-radius: var(--radius-large);
      }
    }

    header,
    .details {
      grid-column: 1 / -1;
      background-color: white;
    }
    header {
    }
    .details {
      padding: 1.5em 2.25em;
    }

    .month-separator {
      grid-column: 1 / -1;
      line-height: 0.25;
      padding-top: 2em;
      padding-bottom: 0;
      color: var(--color-grey);
    }
  }
`;

const ClickWrapper: React.FC<{ children: (React.ReactElement | boolean)[]; onClick: React.MouseEventHandler }> = ({ children, onClick }) => (
  <>{React.Children.map(children, (child) => (child && typeof child !== "boolean" ? React.cloneElement(child, { onClick }) : child))}</>
);

const magicDateFormatWithZeroWidthSpaceBeforeYear = "dd.MM.​yyyy HH:mm";

function ToolsReleaseCalendar() {
  const { releaseCalendarData, releaseCalendarDataFetchError, isLoadingReleaseCalendarData } = useRealeaseCalendar();
  const [expandedItems, setExpandedItems] = React.useState<Record<string, boolean>>({});
  const currentMonth = startOfMonth(new Date());
  const [[selectedFrom, selectedTo], setSelectedMonthInterval] = React.useState([currentMonth, addMonths(currentMonth, 3)] as [Date, Date]);
  const calendarItems = React.useMemo(
    () => releaseCalendarData?.applications.flatMap((app) => app.releaseCalendarItems.map((itm) => itm.startDate)),
    [releaseCalendarData?.applications]
  );

  const hasSelectedStartDate = (itm: { startDate: Date }) => isAfter(itm.startDate, selectedFrom) && isBefore(itm.startDate, selectedTo);

  return (
    <>
      <Breadcrumb />

      <PageHeader
        title="Releasekalender"
        subtitle="Her får du overblikket over kommende releases, servicevinduer og lukning af systemer. Vi opdaterer kalenderen løbende."
        maxInlineSizeInChars={NaN}
      />

      <Loader isLoading={isLoadingReleaseCalendarData}>
        <LoadingProblemBanner show={Boolean(!!!releaseCalendarData && releaseCalendarDataFetchError)}>
          Der er i øjeblikket problemer med at hente informationer omkring releases
        </LoadingProblemBanner>

        {releaseCalendarData && releaseCalendarData.applications.length > 0 && (
          <>
            {calendarItems && (
              <HistogramMonthRangePicker
                dates={calendarItems}
                onBrushChanged={([from, to]) => setSelectedMonthInterval([from, to])}
                monthsDomain={[currentMonth, addMonths(currentMonth, 2)]}
              />
            )}

            <div className={"columns mt-3 " + calendarStyles}>
              {releaseCalendarData.applications.map((itApplication) => {
                let prevMonth = startOfMonth(new Date(new Date().getFullYear() + 1, 0, 1));

                return (
                  <div className="column" key={itApplication.sys.id}>
                    <div className="app-calendar panel is-shadowless is-size-7">
                      <header className="has-text-weight-bold">{itApplication.name}</header>
                      {itApplication.releaseCalendarItems.filter(hasSelectedStartDate).map((itm) => {
                        const thisMonth = startOfMonth(itm.startDate);
                        const monthChanged = isBefore(thisMonth, prevMonth);
                        prevMonth = thisMonth;

                        return (
                          <ClickWrapper
                            key={itm.sys.id}
                            onClick={() => setExpandedItems((expanded) => ({ ...expanded, [itm.sys.id]: !!!expanded[itm.sys.id] }))}
                          >
                            {monthChanged && (
                              <div className="month-separator is-size-8">{<DateTimeStamp date={itm.startDate} dateTimeFormat="MMM yyyy" />}</div>
                            )}
                            <div>{<DateTimeStamp date={itm.startDate} dateTimeFormat={magicDateFormatWithZeroWidthSpaceBeforeYear} />}</div>
                            <div>{<DateTimeStamp date={itm.endDate} dateTimeFormat={magicDateFormatWithZeroWidthSpaceBeforeYear} />}</div>
                            <div className="item-type has-text-right has-text-primary">
                              {/Service window/i.test(itm.itemType) ? "Servicevindue" : "IT-release"}
                            </div>
                            {expandedItems[itm.sys.id] && (
                              <div className="details">
                                {itm.description && documentToReactComponents(itm.description.json)}
                                <p className="pt-3">(sidst ændret {<DateTimeStamp date={itm.sys.publishedAt} />})</p>
                              </div>
                            )}
                          </ClickWrapper>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </Loader>
    </>
  );
}

export default ToolsReleaseCalendar;
