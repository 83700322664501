import React from "react";
import { useProducts } from "../store/products";
import PageHeader from "./shared/PageHeader";
import Loader from "./shared/Loader";
import Breadcrumb from "./shared/Breadcrumb";
import LoadingProblemBanner from "./shared/LoadingProblemBanner";
import { matchPath, NavLink, Redirect, Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import Page from "./shared/Page";
import { Routes, RouteParams } from "../store/routing";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { css } from "@emotion/css";
import { mq } from "../styles/styleUtils";
import PageList from "./shared/PageList";
import FileIcon from "./shared/FileIcon";
import { toRoutingName } from "../store/misc";
import Video from "./shared/Video";

const routeToProduct = (productRoutingName: string) => Routes.ProductsProductRouteName.create({ productRoutingName });

const Products: React.FC = () => {
  const { products } = useProducts();

  const sideMenuData = {
    title: "Produkter",
    description: "Det samlede produktmateriale",
    body: (
      <>
        {products?.filtered.products.map((product) => (
          <NavLink to={routeToProduct(product.routingName)} activeClassName="is-active" key={product.sys.id}>
            {product.name}
          </NavLink>
        ))}
      </>
    ),
  };

  return (
    <Page sideMenuData={sideMenuData}>
      <section className="section">
        <div className="content-container">
          <Switch>
            <Route exact path={Routes.Products.template()} component={ProductsOverview} />
            <Route path={Routes.ProductsProductRouteName.template()} component={ProductsProduct} />
            <Route>
              <Redirect to={Routes.Products.template()} />
            </Route>
          </Switch>
        </div>
      </section>
    </Page>
  );
};

export function ProductsOverview() {
  const { products, productsFetchError, isLoadingProducts } = useProducts();

  return (
    <>
      <Breadcrumb />

      <PageHeader title="Produkter" subtitle="Oversigt over vores produkter og ydelser" />

      <Loader isLoading={isLoadingProducts}>
        <LoadingProblemBanner show={Boolean(!!!products && productsFetchError)}>
          Der er i øjeblikket problemer med at hente informationer omkring vores produkter
        </LoadingProblemBanner>

        {products && products.filtered.products.length > 0 && (
          <Redirect
            to={Routes.ProductsProductRouteName.create({
              productRoutingName: products.filtered.products[0].routingName,
            })}
          />
        )}
      </Loader>
    </>
  );
}

const productStyles = css`
  .main-documents {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(auto-fit, minmax(235px, 1fr));
    grid-auto-rows: 1fr;

    &.single .box {
      ${mq.tabletOnly} {
        width: 50%;
      }

      ${mq.widescreen} {
        width: 50%;
      }
    }

    .icon {
      opacity: 0.8;
    }
  }
`;

export function ProductsProduct() {
  const { products, productsFetchError, isLoadingProducts } = useProducts();
  const { params } = useRouteMatch<RouteParams<"ProductsProductRouteName">>();

  const selectedProduct = products?.filtered.findByRoutingName(params.productRoutingName);

  const matchedVideoRoute = matchPath<RouteParams<"ProductsProductRouteNameVideosVideoIdRouteName">>(
    useLocation().pathname,
    Routes.ProductsProductRouteNameVideosVideoIdRouteName.template()
  );

  if (products && !selectedProduct) {
    return <Redirect to={Routes.Products.create({})} />;
  }

  const showVideo = !!(matchedVideoRoute && matchedVideoRoute.params.videoId);

  const selectedVideoItem = matchedVideoRoute?.params.videoId
    ? selectedProduct?.allLinkedVideoItems.find((itm) => itm.sys.id === matchedVideoRoute.params.videoId)
    : undefined;

  const selectedVideoItemRoutingName = selectedVideoItem?.title ? toRoutingName(selectedVideoItem.title) : undefined;

  // Fix URL if we are to show a video but the video item routing name in the URL isn't correct
  if (showVideo && matchedVideoRoute.params.videoRoutingName !== selectedVideoItemRoutingName) {
    return (
      <Redirect
        to={Routes.ProductsProductRouteNameVideosVideoIdRouteName.create({
          ...matchedVideoRoute.params,
          videoRoutingName: selectedVideoItemRoutingName ?? "loading",
        })}
      />
    );
  }

  const urlRetrievers: Parameters<typeof PageList>[0]["urlRetrievers"] = {
    videoItemUrlRetriever: (item) => ({
      kind: "route",
      href: Routes.ProductsProductRouteNameVideosVideoIdRouteName.create({
        ...params,
        videoId: item.sys.id,
        videoRoutingName: toRoutingName(item.title ?? "loading"),
      }),
    }),
  };

  return (
    <>
      <Breadcrumb
        mapUrlSegmentToName={(segment) => {
          return segment === matchedVideoRoute?.params.videoId // Don't show video ID in breadcrumb
            ? false
            : segment === selectedVideoItemRoutingName // Show actual video title not the video routing name in breadcrumb
            ? selectedVideoItem?.title
            : products?.filtered.findNameByRoutingName(segment);
        }}
      />

      <Loader isLoading={isLoadingProducts}>
        <LoadingProblemBanner show={Boolean(!!!products && productsFetchError)}>
          Der er i øjeblikket problemer med at hente informationer omkring vores produkter
        </LoadingProblemBanner>

        {selectedProduct && <PageHeader title={selectedProduct.title ?? ""} maxInlineSizeInChars={NaN} />}

        {selectedProduct &&
          (showVideo ? (
            selectedVideoItem?.video?.playbackId ? (
              <div className="pt-3">
                <Video playbackId={selectedVideoItem.video.playbackId} />
              </div>
            ) : (
              <Redirect to={Routes.ProductsProductRouteName.create(params)} />
            )
          ) : (
            <div className={"columns is-desktop pb-5 " + productStyles}>
              <div className="column is-7-desktop is-8-widescreen">
                <div className="content is-size-7">{selectedProduct.description && documentToReactComponents(selectedProduct.description.json)}</div>
                {selectedProduct.mainCtas?.items.length && (
                  <div className={`main-documents ${selectedProduct.mainCtas.items.length === 1 ? "single" : ""} py-5`}>
                    {selectedProduct.mainCtas.items.map((asset, idx) => (
                      <a
                        className="has-cta"
                        title={asset.title ?? undefined}
                        href={asset.url ?? undefined}
                        key={asset.sys.id + idx} // idx is used because the same asset can potentially occur in the list multiple times :(
                      >
                        <div className={`box is-shadowless is-fullheight cta-box cta-box-${idx % 3} is-flex is-flex-direction-column`}>
                          <p className="has-text-weight-bold pb-3">{asset.title}</p>
                          <p className="is-size-8 pb-3">{asset.description}</p>
                          <span className="icon is-flex-grow-1 is-align-items-flex-end">
                            <FileIcon {...asset} />
                          </span>
                        </div>
                      </a>
                    ))}
                  </div>
                )}
                {selectedProduct.mainLists?.items.length ? (
                  <div className="py-5">
                    {selectedProduct.mainLists.items.map((list) => (
                      <PageList list={list} iconsTrailing={true} urlRetrievers={urlRetrievers} key={list.sys.id} />
                    ))}
                  </div>
                ) : null}
              </div>
              <div className="column is-5-desktop is-4-widescreen">
                {selectedProduct.secondaryLists?.items.map((list) => (
                  <PageList list={list} urlRetrievers={urlRetrievers} key={list.sys.id} />
                ))}
              </div>
            </div>
          ))}
      </Loader>
    </>
  );
}

export default Products;
