import React from "react";
import { Redirect, useRouteMatch } from "react-router-dom";
import Loader from "./shared/Loader";
import LoadingProblemBanner from "./shared/LoadingProblemBanner";
import { css } from "@emotion/css";
import { useNewsItem } from "../store/news";
import { NewsItemsItemRouteParameters, routeToNewsItem } from "./News";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import DateStamp from "./shared/DateStamp";
import { mq } from "../styles/styleUtils";

const NewsNewsItem: React.FC = () => {
  const { params } = useRouteMatch<NewsItemsItemRouteParameters>();
  const { newsItem, newsItemFetchError, isLoadingNewsItem } = useNewsItem(params.newsId);

  // Fix URL if the news item routing name in the URL isn't correct
  if (newsItem && params.newsRoutingName !== newsItem.routingName) {
    return <Redirect to={routeToNewsItem(newsItem.routingName, newsItem.sys.id, newsItem.archived)} />;
  }

  return (
    <>
      <Loader isLoading={isLoadingNewsItem}>
        <LoadingProblemBanner show={Boolean(!!!newsItem && newsItemFetchError)}>
          Der er i øjeblikket problemer med at hente nyheden
        </LoadingProblemBanner>

        <LoadingProblemBanner wrapperClassName="my-5" show={Boolean(!!!newsItemFetchError && !newsItem)}>
          Nyheden kan ikke fremsøges.
        </LoadingProblemBanner>

        <div
          className={
            "pt-5 " +
            css`
              max-inline-size: 760px;
              margin-left: auto;
              margin-right: auto;

              h1 {
                line-height: 1.25;
              }

              img {
                display: block;
              }

              .date-line-spacer {
                margin: 0 0.25rem;
              }

              .body-text {
                ${mq.from(560)} {
                  columns: 2;
                }
                white-space: pre-line;
              }
            `
          }
        >
          {newsItem && (
            <>
              <div className="is-uppercase has-text-primary is-size-8 has-text-weight-bold pt-1 pb-3">{newsItem.tagline}</div>

              <h1 className="is-size-3 has-text-weight-bold mb-5">{newsItem.headline ?? ""}</h1>

              {newsItem.heroImage?.url && <img className="mb-5" src={newsItem.heroImage.url} alt=""></img>}

              <div className="has-text-black-ter is-size-8 mb-2">
                Oprettet: <DateStamp date={newsItem.firstPublishedAtDate} /> <span className="date-line-spacer">|</span> Opdateret:{" "}
                <DateStamp date={newsItem.publishedAtDate} />
              </div>

              {newsItem.subHeadline && <p className="is-size-5 has-text-weight-bold pt-5 pb-1">{newsItem.subHeadline}</p>}

              <div className="body-text content pt-5">
                {newsItem.body &&
                  documentToReactComponents(newsItem.body.json, {
                    renderNode: {
                      [BLOCKS.EMBEDDED_ASSET]: (node, _) => {
                        const assetId = node.data.target.sys.id;
                        const asset = newsItem.body?.links.assets.block.find((ass) => ass.sys.id === assetId);

                        return asset && asset.contentType?.startsWith("image/") ? (
                          <figure
                            className={
                              "mr-1 mb-3 m-0 has-text-left " +
                              css`
                                display: table;
                                break-inside: avoid-column;

                                img.embedded-image {
                                  display: block;
                                }

                                figcaption {
                                  display: table-caption;
                                  caption-side: bottom;

                                  span + span:before {
                                    content: "-";
                                    margin: 0 0.25em;
                                  }
                                }
                              `
                            }
                          >
                            <img src={asset.url ?? undefined} alt={asset.title ?? undefined} className="embedded-image mb-2" />
                            <figcaption className="has-text-grey-light is-size-8">
                              {asset.title && <span>{asset.title}</span>}
                              {asset.description && <span>{asset.description}</span>}
                            </figcaption>
                          </figure>
                        ) : null;
                      },
                      [INLINES.ASSET_HYPERLINK]: (node, children) => {
                        const assetId = node.data.target.sys.id;
                        const asset = newsItem.body?.links.assets.hyperlink.find((ass) => ass.sys.id === assetId);

                        return <a href={asset?.url ?? undefined}>{children}</a>;
                      },
                    },
                  })}
              </div>
            </>
          )}
        </div>
      </Loader>
    </>
  );
};

export default NewsNewsItem;
