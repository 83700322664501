import React from "react";
import MessageBanner from "./MessageBanner";
import TopMenu from "./TopMenu";

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <>
      <MessageBanner />

      <TopMenu />

      {children}
    </>
  );
};

export default Layout;
