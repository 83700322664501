import React from "react";
import { useNewsItems } from "../store/news";
import Loader from "./shared/Loader";
import LoadingProblemBanner from "./shared/LoadingProblemBanner";
import NewsTable from "./shared/NewsTable";

const DashboardNewsTable: React.FC = () => {
  const { newsItems, newsItemsFetchError, isLoadingNewsItems } = useNewsItems();

  return (
    <div className="block pt-5">
      <Loader isLoading={isLoadingNewsItems}>
        <LoadingProblemBanner show={Boolean(!!!newsItems && newsItemsFetchError)}>
          Der er i øjeblikket problemer med at hente nyheder
        </LoadingProblemBanner>

        {newsItems?.active && <NewsTable data={newsItems.active} pageSize={5} />}
      </Loader>
    </div>
  );
};

export default DashboardNewsTable;
