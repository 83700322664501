import { contentfulConfig } from "./configuration";
import { ClientError, gql, request } from "graphql-request";
import { docListFragment, itemListFragment } from "./contentfulShared";
import { allLinkedVideoItems, toRoutingName } from "./misc";
import { useSWRCurried } from "./fetching";

const pageFetcher = (_: string, id: PageQueryVariables["id"]) => {
  return request<DeepArrayUnmaybefy<PageQuery>>(
    contentfulConfig.graphqlEndpoint,
    gql`
      query Page($id: String!) {
        page: page(locale: "da-DK", id: $id) {
          sys {
            id
          }
          routingName
          name
          title
          teaser
          description {
            json
          }
          # note the limit of 3 which is set in Contentful
          highlights: highlightsCollection(locale: "da-DK", limit: 3) {
            total
            items {
              ... on AssetItem {
                ...AssetItemFields
              }
              ... on LinkItem {
                ...LinkItemFields
              }
            }
          }
          # note the limit of 5 which is set in Contentful
          mainLists: mainListsCollection(locale: "da-DK", limit: 5) {
            total
            items {
              ... on DocumentList {
                ...DocList
              }
              ... on ItemList {
                ...ItmList
              }
            }
          }
          # note the limit of 5 which is set in Contentful
          secondaryLists: secondaryListsCollection(locale: "da-DK", limit: 5) {
            total
            items {
              ... on DocumentList {
                ...DocList
              }
              ... on ItemList {
                ...ItmList
              }
            }
          }
        }
      }
      ${docListFragment}
      ${itemListFragment}
    `,
    {
      id,
    }
  ).then((data) => {
    if (!!!data.page) {
      throw new Error(`Page with ID '${id}' not found`);
    }
    return data.page!;
  });
};

export function usePage(id: string | undefined) {
  const { data, error } = useSWRCurried<ClientError | Error>()(["contentful/page", id], pageFetcher);
  const page = data
    ? { ...data, routingName: data.routingName ?? toRoutingName(data.name), allLinkedVideoItems: allLinkedVideoItems(data) }
    : undefined;

  return {
    page,
    pageFetchError: error,
    isLoadingPage: !data && !error,
  };
}
