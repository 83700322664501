import React from "react";
import { css } from "@emotion/css";

const CoverageMap: React.FC = () => {
  return (
    <iframe
      title="Dækningskort"
      className={css`
        height: 100%;
        width: 100%;
      `}
      src="https://daekning.tdc.dk/tdcnetmap_ext_tile2/"
    />
  );
};

export default CoverageMap;
