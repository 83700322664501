import React from "react";
import { Link, Redirect, useRouteMatch } from "react-router-dom";
import Loader from "./shared/Loader";
import LoadingProblemBanner from "./shared/LoadingProblemBanner";
import { useErrorReports } from "../store/errorReporting";
import DateTimeStamp from "./shared/DateTimeStamp";
import { css } from "@emotion/css";
import { useApplications } from "../store/applications";
import PageHeader from "./shared/PageHeader";
import { RouteParams, Routes } from "../store/routing";

import ChevronLeftIcon from "mdi-react/ChevronLeftIcon";

const ApplicationsApplicationErrorReportsReport: React.FC = () => {
  const { params } = useRouteMatch<RouteParams<"AppsAppRouteNameErrorReportsRptId">>();
  const { errorReports, errorReportsFetchError, isLoadingErrorReports } = useErrorReports();
  const { applications } = useApplications();

  const selectedErrorReport = errorReports?.find((rpt) => rpt.docId === Number(params.rptId));
  const application = selectedErrorReport && applications?.findById(selectedErrorReport.applicationId);

  // Fix URL if the application name in it doesn't match
  if (selectedErrorReport && application && params.appRoutingName !== application.routingName) {
    return <Redirect to={Routes.AppsAppRouteNameErrorReportsRptId.create({ appRoutingName: application.routingName, rptId: params.rptId })} />;
  }

  const backUrl = application ? Routes.AppsAppRouteName.create({ appRoutingName: application.routingName }) : Routes.Apps.create({});

  return (
    <>
      <PageHeader title={`${application ? `${application.applicationName} - ` : ""}DocID #${params.rptId}`} />

      <Link className="is-size-7 is-primary is-inverted" to={backUrl}>
        <span className="icon-text pt-2">
          <span className="icon" style={{ marginTop: "-0.2em" }}>
            <ChevronLeftIcon size={"1.5em"} />
          </span>
          Tilbage til fejlmeldingslisten
        </span>
      </Link>

      <Loader isLoading={isLoadingErrorReports}>
        <LoadingProblemBanner wrapperClassName="my-5" show={Boolean(!!!errorReports && errorReportsFetchError)}>
          Der er i øjeblikket problemer med at hente fejlmelding med DocID: #{params.rptId}
        </LoadingProblemBanner>

        <LoadingProblemBanner wrapperClassName="my-5" show={Boolean(!!!errorReportsFetchError && !selectedErrorReport)}>
          Fejlmelding med DocID: #{params.rptId} kan ikke fremsøges.
        </LoadingProblemBanner>

        {selectedErrorReport && (
          <div
            className={
              "mt-3 pt-4 pl-5 " +
              css`
                > * {
                  max-inline-size: 60ch;
                }
                border-top: 1px solid var(--color-grey-lighter);
                border-bottom: 1px solid var(--color-grey-lighter);
              `
            }
          >
            <div className="is-flex is-size-8 pb-5">
              <span>DocID: #{selectedErrorReport.docId}</span>
            </div>

            <div className="pt-3 pb-6">
              <div className="block">
                <p className="has-text-primary">
                  <span className="has-text-weight-bold">{selectedErrorReport.status} </span>
                  <DateTimeStamp date={selectedErrorReport.statusChangedDate} />
                </p>
              </div>
              <div className="block">
                <p className="is-size-7">Emne</p>
                <p className="has-text-weight-bold">{selectedErrorReport.subject}</p>
              </div>
              <div className="block">
                <p className="is-size-7">Beskrivelse</p>
                <p className="has-text-weight-bold">{selectedErrorReport.description}</p>
              </div>
            </div>
          </div>
        )}
      </Loader>
    </>
  );
};

export default ApplicationsApplicationErrorReportsReport;
