import React from "react";
import PageHeader from "./shared/PageHeader";
import Loader from "./shared/Loader";
import Breadcrumb from "./shared/Breadcrumb";
import LoadingProblemBanner from "./shared/LoadingProblemBanner";
import { Link, NavLink, Redirect, Route, Switch, matchPath } from "react-router-dom";
import Page from "./shared/Page";
import { RouteParams, RouteParamsUnion, Routes } from "../store/routing";
import { css } from "@emotion/css";
import { mq } from "../styles/styleUtils";
import { useNewsItems, NewsItem } from "../store/news";
import NewsArchive from "./NewsArchive";
import NewsNewsItem from "./NewsNewsItem";
import DateStamp from "./shared/DateStamp";

export const routeToNewsItem = (newsRoutingName: string, newsId: string, isArchived: boolean) =>
  isArchived
    ? Routes.NewsArchiveNewsItemNewsIdRouteName.create({ newsRoutingName, newsId })
    : Routes.NewsNewsItemNewsIdRouteName.create({ newsRoutingName, newsId });

type AllNewsItemsItemRoutes =
  | "NewsArchiveNewsItemNewsId"
  | "NewsNewsItemNewsId"
  | "NewsArchiveNewsItemNewsIdRouteName"
  | "NewsNewsItemNewsIdRouteName";

type AllNewsItemsItemRouteParameters = {
  [K in RouteParamsUnion<AllNewsItemsItemRoutes>]: string;
};

type CommonNewsItemsItemRouteParameters = Record<keyof RouteParams<AllNewsItemsItemRoutes>, string>;

export type NewsItemsItemRouteParameters = CommonNewsItemsItemRouteParameters & Partial<AllNewsItemsItemRouteParameters>;

const News: React.FC = () => {
  const { newsItems } = useNewsItems();

  const sideMenuData = {
    title: "Nyheder oversigt",
    description: "Nuuday Mobile Partners",
    body: (
      <>
        <NavLink
          to={Routes.News.create({})}
          isActive={(_, location) => !matchPath(location.pathname, Routes.NewsArchive.template())}
          activeClassName="is-active"
        >
          Oversigt
        </NavLink>
        <NavLink to={Routes.NewsArchive.create({})} activeClassName="is-active">
          Arkiv
        </NavLink>
      </>
    ),
  };

  return (
    <Page sideMenuData={sideMenuData}>
      <section className="section">
        <div className="content-container">
          <Breadcrumb
            mapUrlSegmentToName={(segment, _, path) => {
              const matched =
                matchPath<NewsItemsItemRouteParameters>(path, Routes.NewsArchiveNewsItemNewsIdRouteName.template()) ??
                matchPath<NewsItemsItemRouteParameters>(path, Routes.NewsNewsItemNewsIdRouteName.template());

              return segment === matched?.params.newsId && /^[a-z0-9]{22}/i.test(segment) ? false : newsItems?.all.findHeadlineByRoutingName(segment);
            }}
          />

          <Switch>
            <Route exact path={Routes.News.template()} component={NewsOverview} />
            <Route exact path={Routes.NewsArchive.template()} component={NewsArchive} />
            <Route exact path={Routes.NewsArchiveNewsItemNewsId.template()} component={NewsNewsItem} />
            <Route exact path={Routes.NewsNewsItemNewsId.template()} component={NewsNewsItem} />
            <Route path={Routes.NewsArchiveNewsItemNewsIdRouteName.template()} component={NewsNewsItem} />
            <Route path={Routes.NewsNewsItemNewsIdRouteName.template()} component={NewsNewsItem} />
            <Route>
              <Redirect to={Routes.News.create({})} />
            </Route>
          </Switch>
        </div>
      </section>
    </Page>
  );
};

export function NewsOverview() {
  const { newsItems, newsItemsFetchError, isLoadingNewsItems } = useNewsItems();

  return (
    <>
      <PageHeader title="Nyheder" />

      <Loader isLoading={isLoadingNewsItems}>
        <LoadingProblemBanner show={Boolean(!!!newsItems && newsItemsFetchError)}>
          Der er i øjeblikket problemer med at hente nyheder
        </LoadingProblemBanner>

        {newsItems && newsItems.active.length > 0 ? (
          <div>
            {
              <div
                className={css`
                  > hr {
                    margin: 0.5rem 0;
                    display: block;
                    height: 1px;
                    background-color: var(--color-grey-lighter);
                  }

                  img {
                    display: block;
                  }

                  .news-item {
                    cursor: pointer;

                    &:nth-child(1) {
                      font-size: 1.5rem;
                    }

                    .image-wrapper {
                      margin-bottom: 0.5rem;

                      &.no-image {
                        width: 100%;
                        padding-bottom: calc(100% * calc(9 / 16));
                        position: relative;

                        &:before {
                          content: "[NO IMAGE]";
                          text-align: center;
                          color: var(--color-primary);
                          z-index: 2;
                          position: absolute;
                          inset: 0;
                          display: grid;
                          place-items: center;
                        }

                        &:after {
                          content: "";
                          position: absolute;
                          inset: 0;
                          z-index: 1;
                          //background-color: transparent;
                          background: white;
                          background: radial-gradient(circle, var(--color-primary-light) 10%, var(--color-primary) 100%);
                        }
                      }
                    }
                  }

                  ${mq.mobile} {
                    > hr {
                      display: none;
                    }

                    .news-item {
                      padding-bottom: 2.5rem;
                    }
                  }

                  ${mq.tablet} {
                    display: grid;
                    gap: 2rem 1.5rem;

                    grid-template-columns: repeat(2, 1fr);

                    .news-item:nth-child(1) {
                      grid-column: 1 / 3;
                    }

                    > hr {
                      grid-column: 1 / 3;
                    }
                  }

                  ${mq.from(1250)} {
                    grid-template-columns: repeat(3, 1fr);

                    .news-item:nth-child(1) {
                      grid-row: 1 / 3;
                      grid-column: 1 / 3;
                    }

                    > hr {
                      grid-column: 1 / 4;
                    }
                  }
                `}
              >
                {newsItems.active.map((itm, idx) => (
                  <React.Fragment key={`${itm.sys.id}_${idx}`}>
                    {idx === 3 && <hr />}
                    <RenderNewsItem item={itm} />
                  </React.Fragment>
                ))}
              </div>
            }
          </div>
        ) : (
          <div className="content has-text-primary pt-5">
            <span className="is-size-6 has-text-weight-semibold">Der er ingen nyheder</span>
          </div>
        )}
      </Loader>
    </>
  );
}

const LinkWrapper: React.FC<{ url: string; children: Array<React.ReactElement | boolean> }> = ({ url, children }) => (
  <>
    {React.Children.map(children, (child) =>
      child && typeof child !== "boolean" ? (
        <Link to={url} className="is-inverted">
          {child}
        </Link>
      ) : (
        child
      )
    )}
  </>
);

function RenderNewsItem({ item }: { item: NewsItem | undefined }) {
  return item ? (
    <div className="news-item">
      <LinkWrapper url={routeToNewsItem(item.routingName, item.sys.id, item.archived)}>
        <div className={"image-wrapper " + (!!!item.heroImage?.url ? "no-image" : "")}>
          {item.heroImage?.url && <img src={item.heroImage.url} alt=""></img>}
        </div>
        <div className="is-flex is-justify-content-space-between has-text-black-ter is-uppercase is-size-9 has-text-weight-bold pt-1 pb-2">
          <span className="has-text-primary">{item.tagline}</span>
          <DateStamp date={item.firstPublishedAtDate} />
        </div>
        <div className="has-text-weight-bold">{item.headline}</div>
      </LinkWrapper>
    </div>
  ) : null;
}

export default News;
