import React from "react";
import { FasDataItemPlanningStatus, FasDataItem } from "../store/operations";
import DateTimeStamp from "./shared/DateTimeStamp";

const OperationInformationItemDetails: React.FC<{ item: FasDataItem; showTitle?: boolean }> = ({ item, showTitle = false }) => {
  const completed = item.planningStatus === FasDataItemPlanningStatus.Completed;
  const planned =
    item.planningStatus === FasDataItemPlanningStatus.Planned || (item.planningStatus === FasDataItemPlanningStatus.Completed && item.wasPlanned);

  return (
    <div className="pb-6">
      <pre style={{ display: "none" }}>{JSON.stringify(item, null, 2)}</pre>

      {showTitle && <p className="has-text-primary has-text-weight-bold py-3">{item.title}</p>}

      <LeadDetailsLine label={planned ? "Oprettet" : "Opstået"}>
        <DateTimeStamp date={item.occured} />
      </LeadDetailsLine>

      {planned && (
        <LeadDetailsLine label="Varighed">
          <DateTimeStamp date={item.plannedStart} /> - <DateTimeStamp date={completed ? item.realEnd : item.plannedEnd} />
        </LeadDetailsLine>
      )}

      <LeadDetailsLine label="Opdateret">
        <DateTimeStamp date={item.updated} />
      </LeadDetailsLine>

      {!completed ? (
        <>
          {!planned && (
            <LeadDetailsLine label="Næste opdatering">
              <DateTimeStamp date={item.nextStatus} />
            </LeadDetailsLine>
          )}
          <LeadDetailsLine label="Forventes løst">
            <DateTimeStamp date={item.estimateResolved} defaultTo="(tidspunkt tilkommer)" />
          </LeadDetailsLine>
        </>
      ) : (
        <LeadDetailsLine label="Løst">
          <DateTimeStamp date={item.realResolved} />
        </LeadDetailsLine>
      )}

      <LeadDetailsLine label="Årsag">{item.reason}</LeadDetailsLine>

      <div className="pt-5">{item.consequence.map((line, idx) => (line ? <p key={idx}>{line}</p> : <br key={idx} />))}</div>

      {item.status && (
        <div className="pt-3">
          <LeadDetailsLine label="Status">{item.status}</LeadDetailsLine>
        </div>
      )}
    </div>
  );
};

function LeadDetailsLine({ label, children }: React.PropsWithChildren<{ label: string }>) {
  return (
    <p className="has-text-primary pt-3">
      <span className="has-text-weight-bold">{label}</span> {children}
    </p>
  );
}

export default OperationInformationItemDetails;
