import React, { useEffect, useState } from "react";
import { ApplicationOverview } from "../store/applications";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import ReactDatePicker from "react-datepicker";
import { css, cx } from "@emotion/css";
import * as Dz from "react-dropzone";
import Dropzone from "react-dropzone";
import { zodResolver } from "@hookform/resolvers/zod";
import * as zod from "zod";
import { useSubmitErrorReport, ErrorReportInputs, isRetryableSubmissionState } from "../store/errorReporting";
import LoadingProblemBanner from "./shared/LoadingProblemBanner";
import { MdiReactIconProps } from "mdi-react";
import { useAuth } from "oidc-react";
import { Link } from "react-router-dom";
import PageHeader from "./shared/PageHeader";
import { getFileIcon } from "../store/misc";
import { Routes } from "../store/routing";
import { useContactContent } from "../store/contact";

import CloseCircleOutlineIcon from "mdi-react/CloseCircleOutlineIcon";
import CloudUploadOutlineIcon from "mdi-react/CloudUploadOutlineIcon";

import "react-datepicker/dist/react-datepicker.css";

const styles = css`
  &.report-error {
    max-width: 700px;

    .field {
      position: relative;
      margin-bottom: 1.5rem;
    }
    .label {
      position: absolute;
      top: 0.8rem;
      z-index: 1;
      font-size: 0.8rem;
      transition: all 200ms;
      opacity: 0.5;

      &.same-line {
        top: 0;
      }
    }
    .input {
      border-width: 0 0 1px 0;
      border-radius: 0;
      box-shadow: none;
      z-index: 2;
      padding-left: 0;
      font-size: 0.875rem;
      padding-top: 1rem;
      padding-bottom: 0.2rem;

      &:focus,
      &:active,
      &:hover {
        box-shadow: none;

        ~ .icon {
          color: #dbdbdb !important;
        }
      }

      ~ .icon:focus,
      ~ .icon:active,
      ~ .icon:hover {
        outline: none;

        .icon-remove {
          border-radius: var(--radius-rounded);
          box-shadow: var(--color-primary) 0 0 0px 2px;

          color: var(--color-primary);
        }
      }
    }
    textarea.input {
      min-height: 8em;
      margin-top: 1.5rem;
      padding-left: 0.4rem;
      background-color: hsl(0deg 0% 99%);
    }

    .field:focus-within .label,
    .field.filled .label {
      font-size: 0.625rem;
      top: 0.3rem;
      transform: translate3d(0, -100%, 0);
      opacity: 1;

      &.same-line {
        top: 0.3;
        transform: none;
      }
    }
    label.checkbox {
      font-size: 0.8rem;
      opacity: 0.5;
    }
    .react-datepicker-wrapper {
      width: 100%;
    }
    .react-datepicker-popper {
      z-index: 3;
    }
    .panel-block.dropzone {
      background-color: hsl(0deg 0% 99%);
      padding: 3.3rem 3rem 3rem 3rem;
      border-radius: 0;
      border: solid 1px transparent;
      outline: none;

      &:focus,
      &:active,
      &:hover,
      &focus-within {
        border-color: var(--color-primary);
      }
    }
    .panel-block.icon-text {
      font-size: 0.8125rem;
    }
    .panel-block .icon {
      font-size: 0.8125rem;
      line-height: 0.8125rem;
      width: 1.2rem;
      height: 1.2rem;
      margin-top: -0.3rem;
      outline: none;

      &.icon-remove-wrapper {
        margin-left: auto;

        color: #dbdbdb;

        &:focus,
        &:active,
        &:hover {
          .icon-remove {
            border-radius: var(--radius-rounded);
            box-shadow: var(--color-primary) 0 0 0px 2px;

            color: var(--color-primary);
          }
        }
      }
    }
  }
`;

const phoneRegExp = /^((\+[0-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

// eTray permits 20mb at the moment but Base64 encoding of the files adds an overhead of approximately 33%
const maxAttachmentSizeMb = 10;
const maxAttachmentSizeGraceMb = maxAttachmentSizeMb * 0.25; // Let them get away with attaching a bit more
const maxAttachmentSizeBytes = maxAttachmentSizeMb * 1024 * 1024;
const maxAttachmentSizeGraceBytes = maxAttachmentSizeGraceMb * 1024 * 1024;
const maxAttachmentSizeEffectiveBytes = maxAttachmentSizeBytes + maxAttachmentSizeGraceBytes;
const numAttachmentAllowed = 10;

const errorReportsInputsSchema: zod.Schema<ErrorReportInputs> = zod.object({
  subject: zod
    .string()
    .nonempty()
    .refine((s) => s.trim().length > 0),
  description: zod.string(),
  problemStartedOn: zod.date().optional(),
  phone: zod.string({ required_error: "Telefonnummer skal angives" }).regex(phoneRegExp, "Det angivne telefonnummer er ugyldigt"),
  extraContactPersons: zod.array(zod.object({ email: zod.string().email() })),
  attachments: zod.array(zod.any()), // Too much hassle to bother with validating the file attachments
});

const ErrorReportedSuccessfully: React.FC<{ docId: number; email: string; applicationName: string }> = ({ docId, email, applicationName }) => (
  <>
    <PageHeader title={`Opret fejlmelding - ${applicationName}`} />

    <div
      className={
        "has-background-white p-6 " +
        css`
          max-width: 700px;
        `
      }
    >
      <div className="has-text-centered px-4 py-5">
        <PageHeader
          title="Tak for din henvendelse"
          subtitle={
            <>
              Din fejlmelding bliver sendt til Customer Support Team. Vi behandler din fejlmelding DocID:
              <span className="has-text-weight-bold"> #{docId}</span> så hurtigt som muligt.
              <br />
              Du vil modtage en bekræftelse på e-mail {email}.
              <br />
              <br />
              <b>Customer Support Team</b>
            </>
          }
          maxInlineSizeInChars={NaN}
          padding={false}
        />
        <Link className="button is-rounded pt-3 has-background-white-ter is-size-7 mt-6" to={Routes.Apps.create({})}>
          Tilbage til IT-applikationer
        </Link>
      </div>
    </div>
  </>
);

const ApplicationsReportErrorForm: React.FC<{ application: ApplicationOverview }> = ({ application }) => {
  const { submissionState, submitErrorReport } = useSubmitErrorReport();
  const userProfile = useAuth().userData?.profile;
  const { contactContent } = useContactContent();
  const [rejectedAttachments, setRejectedAttachments] = useState<Dz.FileRejection[]>([]);
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm<ErrorReportInputs>({
    mode: "onBlur",
    defaultValues: {
      //   subject: "Noget er galt",
      //   description: "Der er sket noget underligt",
      //   problemStartedOn: new Date((() => new Date().setHours(new Date().getHours() - 1))()),
      //   phone: userProfile?.phone_number, //"+45 12345678",
      extraContactPersons: [], // keep this default value or errors may occur!
      attachments: [], // keep this default value or errors may occur!
    },
    resolver: zodResolver(errorReportsInputsSchema),
  });
  const contactPersons = useFieldArray({ control, name: "extraContactPersons" });
  const watchAll = watch();

  useEffect(() => {
    if (rejectedAttachments.length) {
      const timer = setTimeout(() => setRejectedAttachments([]), 500000);
      return () => clearTimeout(timer);
    }
  }, [rejectedAttachments]);

  if (!userProfile?.email) {
    return (
      <LoadingProblemBanner>
        Der mangler en verificeret kontakt-e-mailadresse på din profil.
        <br />
        Du kan derfor ikke indsende fejlmeldinger.
      </LoadingProblemBanner>
    );
  } else if (submissionState.kind === "data-submitted-successfully") {
    return <ErrorReportedSuccessfully docId={submissionState.docId} email={submissionState.toEmail} applicationName={application.applicationName} />;
  }

  const fieldClassName = (fieldName: keyof ErrorReportInputs) =>
    cx({
      field: true,
      "is-danger": !!errors[fieldName],
      filled: !!watchAll[fieldName],
    });

  const inputClassName = (fieldName: keyof ErrorReportInputs) => `input${!!errors[fieldName] ? " is-danger" : ""}`;

  // console.log("watchAll", watchAll);
  // console.log(tz.getTimezoneOffset("Europe/Copenhagen"));
  // console.log(
  //   watchAll.problemStartedOn?.toJSON ?.getTimezoneOffset()
  //     ? //? tz.format(tz.toDate(watchAll.problemStartedOn, { timeZone: "Europe/Copenhagen" }), "dd-MM-yyyy HH:mm:ss zzz")
  //       tz.format(
  //         dfns.addMilliseconds(
  //           watchAll.problemStartedOn,
  //           tz.getTimezoneOffset("Europe/Copenhagen")
  //         ),
  //         "dd-MM-yyyy HH:mm:ss zzz",
  //         { timeZone: "Europe/Copenhagen" }
  //       )
  //     : ""
  // );
  //console.log("errors", errors);
  //console.log("isDirty", isDirty);
  //console.log("isValid", isValid);

  return (
    <>
      <PageHeader title={`Opret fejlmelding - ${application.applicationName}`} />

      <form
        onSubmit={handleSubmit(submitErrorReport(application.applicationId, application.applicationName))}
        noValidate
        className={"report-error has-background-white p-6 " + styles}
      >
        <div className={fieldClassName("subject")}>
          <div className="control">
            <input {...register("subject")} className={inputClassName("subject")} />
            <label className="label" htmlFor="subject">
              Emne
            </label>
          </div>
        </div>

        <div className={fieldClassName("description")}>
          <label className="label same-line" htmlFor="description">
            Beskrivelse
          </label>
          <div className="control">
            <textarea {...register("description")} className={inputClassName("description")} />
          </div>
        </div>

        <div className={fieldClassName("phone")}>
          <div className="control">
            <input {...register("phone")} className={inputClassName("phone")} />
            <label className="label" htmlFor="phone">
              Telefonnummer
            </label>
          </div>
        </div>

        <div className={fieldClassName("problemStartedOn")}>
          <div className="control">
            <Controller
              name="problemStartedOn"
              defaultValue={undefined}
              control={control}
              render={({ field: { value, ...restOfField }, ...props }) => {
                return (
                  <ReactDatePicker
                    className={inputClassName("problemStartedOn")}
                    dateFormat="dd.MM.yyyy HH:mm"
                    selected={value}
                    timeFormat="HH:mm"
                    showTimeSelect
                    calendarClassName="is-flex"
                    {...restOfField}
                  />
                );
              }}
            />
            <label className="label" htmlFor="subject">
              Tidspunktet, fejlen opstod (valgfrit)
            </label>
          </div>
        </div>

        <p className="is-size-7 pt-6 pb-1">
          Notifikationer sendes til <strong>{userProfile.email}</strong>
        </p>
        <p className="is-size-7 pb-3">Skal flere personer modtage notifikationer ang. denne fejlmelding?</p>

        {contactPersons.fields.map((contactPerson, index) => (
          <div
            key={contactPerson.id}
            className={cx({
              field: true,
              "is-danger": !!errors.extraContactPersons?.[index]?.email,
              filled: !!watchAll.extraContactPersons[index]?.email,
            })}
          >
            <div className="control has-icons-right">
              <input
                {...register(`extraContactPersons.${index}.email` as const)}
                className={cx({
                  input: true,
                  "is-danger": !!errors.extraContactPersons?.[index]?.email,
                })}
                defaultValue={contactPerson.email}
              />

              <span
                className="icon is-right is-clickable"
                tabIndex={0}
                onKeyDown={(e: React.KeyboardEvent<HTMLAnchorElement>) => {
                  if (e.key === " " || e.key === "Enter") {
                    e.preventDefault();
                    contactPersons.remove(index);
                  }
                }}
                onClick={() => contactPersons.remove(index)}
              >
                <CloseCircleOutlineIcon className="icon-remove" size="1.2rem" />
              </span>

              <label className="label" htmlFor="extraContactPersons">
                Ekstra e-mailadresse
              </label>
            </div>
          </div>
        ))}

        <div className="field">
          <div className="control">
            <button
              type="button"
              className="button is-rounded has-background-white pt-2 is-small"
              onClick={() => contactPersons.append({ email: "" })}
            >
              Tilføj ekstra kontaktperson
            </button>
          </div>
        </div>

        <p className="title is-6 pt-6">Vedhæft dokumentation / skærmprint</p>

        <div className={fieldClassName("attachments")}>
          <div className="control">
            <Controller
              name="attachments"
              control={control}
              render={({ field: { onChange, onBlur, value, name } }) => {
                const removeAttachment = (index: number): void => {
                  const newFiles = [...value];
                  newFiles.splice(index, 1);
                  setValue("attachments", newFiles);
                };

                return (
                  <div>
                    <Dropzone
                      accept={
                        ".bmp, .csv, .doc, .docm, .docx, .dot, .dotm, .dotx, .gif, .jpe, .jpeg, .jpg, .ods, " +
                        ".pdf, .png, .pot, .potm, .potx, .msg, .pps, .ppsm, .ppsx, .ppt, .pptm, .pptx, .rtf, " +
                        ".tif, .tiff, .txt, .xls, .xlsb, .xlsm, .xlsx, .xlt, .xltm, .xltx, .xml, .xps, .xps, .zip, " +
                        ".ctl, .tmp"
                      }
                      onDrop={(a, r, e) => attachmentsSubmitted(a, r, value, onChange, setRejectedAttachments)}
                      // maxFiles={numAttachmentAllowed} We're not letting Dropzone control the number of file (it will reject ALL files if there are too many)
                      maxSize={maxAttachmentSizeEffectiveBytes}
                    >
                      {({ getRootProps, getInputProps }) => {
                        return (
                          <div className="panel-block dropzone" {...getRootProps()}>
                            <span className="icon icon-file mr-3">
                              <CloudUploadOutlineIcon />
                            </span>
                            <input {...getInputProps()} name={name} onBlur={onBlur} />
                            Upload - Maks {numAttachmentAllowed} filer med en samlet størrelse på {maxAttachmentSizeMb} mb
                          </div>
                        );
                      }}
                    </Dropzone>

                    <LoadingProblemBanner show={Boolean(rejectedAttachments.length)} wrapperClassName="mt-5 mb-4" className="is-size-8 py-1">
                      {Array.from(
                        rejectedAttachments.reduce<Map<string, Dz.FileRejection[]>>((acc, cur) => {
                          // the setup of validator and attachment handling should only ever procuce a single error
                          const key = cur.errors[0]?.code || "unknown-error";
                          if (acc.has(key)) {
                            acc.get(key)!.push(cur);
                          } else {
                            acc.set(key, [cur]);
                          }
                          return acc;
                        }, new Map<string, Dz.FileRejection[]>())
                      ).map(([errorCode, frjs]) => {
                        const plur = (plural: string, singular: string) => (frjs.length > 1 ? plural : singular);
                        return (
                          frjs.length && (
                            <div className="block pl-2" key={errorCode}>
                              <p className="is-size-7 has-text-weight-bold">
                                {errorCode === "file-too-large" && `Følgende ${plur("filer", "fil")} er for ${plur("store", "stor")}`}
                                {errorCode === "too-many-files" && `Flere end ${numAttachmentAllowed} filer er valgt. Følgende blev ikke vedhæftet`}
                                {errorCode === "too-much-data" && "Filernes samlede størrelse overskred det tilladte. Følgende blev ikke vedhæftet"}
                                {errorCode === "file-invalid-type" && `Filtypen på følgende ${plur("filer", "fil")} er ikke tilladt`}
                              </p>
                              {frjs.map((r) => (
                                <p key={r.file.name}>{`${r.file.name}`}</p>
                              ))}
                            </div>
                          )
                        );
                      })}
                    </LoadingProblemBanner>

                    {value.length > 0 && (
                      <div className="pt-4">
                        {value.map((file, index) => (
                          <div key={index} className={"panel-block" + (index > 0 ? " pt-4" : "")}>
                            <span className="icon icon-file mr-2">{fileIcon(file, { size: "1.2rem" })}</span>
                            <span>{file.name}</span>
                            <span
                              className="icon icon-remove-wrapper is-clickable"
                              tabIndex={0}
                              onKeyDown={(e: React.KeyboardEvent<HTMLAnchorElement>) => {
                                if (e.key === " " || e.key === "Enter") {
                                  e.preventDefault();
                                  removeAttachment(index);
                                }
                              }}
                              onClick={() => removeAttachment(index)}
                            >
                              <CloseCircleOutlineIcon className="icon-remove" size="1.2rem" />
                            </span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                );
              }}
            />
          </div>
        </div>

        {submissionState.kind === "data-submit-problem" && (
          <LoadingProblemBanner>
            <p>Indsendelse mislykkedes</p>
            {submissionState.problemDescription === "Unauthorized" && <p>Forsøg eventuelt at logge ud og ind igen.</p>}
            <p>
              Hvis problemet varer ved, så kontakt
              <br />
              Nuuday Mobile Partners på tlf. {contactContent?.contactPhone}
            </p>
          </LoadingProblemBanner>
        )}

        <div className="is-flex is-flex-direction-column is-align-items-center has-text-centered pt-4">
          <button
            type="submit"
            disabled={submissionState.kind !== "initialized" && !isRetryableSubmissionState(submissionState)}
            className="button is-rounded is-primary pt-3 is-size-7"
          >
            Send fejlmelding
          </button>
          <Link className="is-primary is-inverted pt-4 is-size-8" to={Routes.AppsAppRouteName.create({ appRoutingName: application.routingName })}>
            Annuller
          </Link>
        </div>
      </form>
    </>
  );
};

function fileIcon(file: Dz.FileWithPath, props: MdiReactIconProps = {}) {
  const Comp = getFileIcon(file.name.toLowerCase().split(".").pop());
  return <Comp {...props} />;
}

function toFileRejection(file: File, ...errors: Dz.FileError[]): Dz.FileRejection {
  return { file, errors };
}

function attachmentsSubmitted(
  acceptedFiles: File[],
  fileRejections: Dz.FileRejection[],
  currentAcceptedFiles: File[],
  cbAccepted: (fs: File[]) => void,
  cbRejected: (frjs: Dz.FileRejection[]) => void
): void {
  const acceptCandidates = distinctFiles([...currentAcceptedFiles, ...acceptedFiles]);
  const { accepted, rejected } = acceptCandidates.reduce<{
    totalFileSize: number;
    accepted: File[];
    rejected: Dz.FileRejection[];
  }>(
    (acc, cur, idx) =>
      idx >= numAttachmentAllowed
        ? {
            ...acc,
            rejected: [...acc.rejected, toFileRejection(cur, { code: "too-many-files", message: "Too many files" })],
          }
        : acc.totalFileSize + cur.size > maxAttachmentSizeEffectiveBytes
        ? {
            ...acc,
            rejected: [...acc.rejected, toFileRejection(cur, { code: "too-much-data", message: "Too much data" })],
          }
        : { ...acc, totalFileSize: acc.totalFileSize + cur.size, accepted: [...acc.accepted, cur] },
    { totalFileSize: 0, accepted: [], rejected: fileRejections }
  );
  cbAccepted(accepted.sort(caseInsesitiveDaStringCompare((f) => f.name)));
  cbRejected(rejected.sort(caseInsesitiveDaStringCompare((fr) => fr.file.name)));
}

function distinctFiles(files: readonly File[]): File[] {
  const seen = new Set<string>();
  const result: File[] = [];
  for (const file of files) {
    const fileFingerprint = [file.name, file.size, file.type, file.lastModified].join();
    if (!seen.has(fileFingerprint)) {
      result.push(file);
      seen.add(fileFingerprint);
    }
  }
  return result;
}

function caseInsesitiveDaStringCompare<T>(pick: (elm: T) => string): (t1: T, t2: T) => number {
  return (t1: T, t2: T) => pick(t1).localeCompare(pick(t2), "da", { sensitivity: "base" });
}

export default ApplicationsReportErrorForm;
