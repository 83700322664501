import { contentfulConfig } from "./configuration";
import { ClientError, gql, request } from "graphql-request";
import { parseISO, compareDesc, min, max } from "date-fns";
import { useSWRCurried } from "./fetching";

const releaseCalendarFetcher = () => {
  return request<DeepArrayUnmaybefy<ReleaseCalendarDataQuery>>(
    contentfulConfig.graphqlEndpoint,
    gql`
      query ReleaseCalendarData {
        itApplications: itApplicationCollection(locale: "da-DK", order: [order_ASC], limit: 5) {
          items {
            sys {
              id
            }
            name
            linkedFrom {
              releaseCalendarItems: releaseCalendarItemCollection(locale: "da-DK") {
                items {
                  sys {
                    id
                    publishedAt
                  }
                  itemType
                  startDate
                  endDate
                  description {
                    json
                  }
                }
              }
            }
          }
        }
      }
    `
  ).then((data) => {
    return (data.itApplications?.items || []).map((app) => ({
      sys: app.sys,
      name: app.name ?? "",
      releaseCalendarItems: (app.linkedFrom?.releaseCalendarItems?.items ?? [])
        .map((itm) => ({
          sys: { ...itm.sys, publishedAt: itm.sys.publishedAt && parseISO(itm.sys.publishedAt) },
          itemType: itm.itemType!,
          startDate: parseISO(itm.startDate!),
          endDate: parseISO(itm.endDate!),
          description: itm.description ? { json: itm.description.json } : undefined,
        }))
        .sort((itm1, itm2) => compareDesc(itm1.startDate, itm2.startDate)),
    }));
  });
};

export function useRealeaseCalendar() {
  const { data, error } = useSWRCurried<ClientError>()("contentful/release-calendar", releaseCalendarFetcher);

  return {
    releaseCalendarData: data
      ? {
          applications: data,
          earliestStartDate: () => min(data.map((app) => app.releaseCalendarItems[app.releaseCalendarItems.length - 1]?.startDate)),
          latestStartDate: () => max(data.map((app) => app.releaseCalendarItems[0]?.startDate)),
        }
      : undefined,
    releaseCalendarDataFetchError: error,
    isLoadingReleaseCalendarData: !data && !error,
  };
}
