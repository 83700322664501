import React from "react";
import ResizeObserverPf from "resize-observer-polyfill";

export const useResizeObserver = (ref: React.RefObject<HTMLElement>, callback?: (entry: DOMRectReadOnly) => void) => {
  const [width, setWidth] = React.useState(-1);
  const [height, setHeight] = React.useState(-1);

  const handleResize = React.useCallback(
    (entries: ResizeObserverEntry[]) => {
      const entry = !Array.isArray(entries) || entries.length < 1 ? undefined : entries[0];
      if (!!!entry) {
        return;
      }

      setWidth(entry.contentRect.width);
      setHeight(entry.contentRect.height);

      if (callback) {
        callback(entry.contentRect);
      }
    },
    [callback]
  );

  React.useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    let RO: ResizeObserverPf | null = new ResizeObserverPf(handleResize);
    RO.observe(ref.current);

    return () => {
      RO?.disconnect();
      RO = null;
    };
  }, [ref, handleResize]);

  return [width, height];
};
