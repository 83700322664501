import React, { useState } from "react";
import { css, cx } from "@emotion/css";
import { mq } from "../../styles/styleUtils";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useIsAuthenticated } from "../../store/authentication";
import { useBroadcastMessages } from "../../store/messages";

import ChevronUpIcon from "mdi-react/ChevronUpIcon";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";

const hasBackgroundWarningBright = css`
  background-color: var(--color-yellow-bright);
`;

const statusBackgroundClassMap: Readonly<{ [S in StatusHue]: string }> = {
  Red: "has-background-danger",
  Yellow: hasBackgroundWarningBright,
  Green: "has-background-success",
};

const MessageBanner: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const { broadcastMessages } = useBroadcastMessages();
  const [isCollapsed, setIsCollapsed] = useState(false);

  // Note that there might be more valid messages for broadcasting but we only ever show the one with the latest start date
  const shownMessage = broadcastMessages?.[0];

  const backgroundClass = !isAuthenticated || !!!shownMessage || !!!shownMessage.status ? "" : statusBackgroundClassMap[shownMessage.status];

  return (
    <div className={backgroundClass} onClick={() => setIsCollapsed(!isCollapsed)}>
      {!isAuthenticated || !!!shownMessage ? null : (
        <div
          className={cx({
            "container is-max-widescreen": true,
            collapsed: isCollapsed,
            [css`
              transition: all 100ms ease-in-out;
              cursor: pointer;
              padding-top: 1.5rem;
              padding-bottom: 1.5rem;
              .icon-text .icon {
                opacity: 0.5;
              }

              .description {
                white-space: pre-line;
                line-height: 1.25;
              }

              &.collapsed {
                padding-top: 0.5rem;
                padding-bottom: 0.35rem;
                .icon-text {
                  padding-top: 0.15rem;
                }
              }

              ${mq.touch} {
                padding-left: 1.5rem;
                padding-right: 1.5rem;
              }
            `]: true,
          })}
        >
          <span className="icon-text">
            <span className="has-text-weight-bold has-text-dark">{shownMessage.title}</span>
            <span className="icon ml-3 has-text-grey-dark">{isCollapsed ? <ChevronDownIcon /> : <ChevronUpIcon />}</span>
          </span>
          {!isCollapsed && shownMessage.details && <div className="description is-6 pt-3">{documentToReactComponents(shownMessage.details)}</div>}
        </div>
      )}
    </div>
  );
};

export default MessageBanner;
