import React from "react";
import PageHeader from "./shared/PageHeader";
import Loader from "./shared/Loader";
import Breadcrumb from "./shared/Breadcrumb";
import LoadingProblemBanner from "./shared/LoadingProblemBanner";
import { matchPath, NavLink, Redirect, Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import Page from "./shared/Page";
import { Routes, RouteParams } from "../store/routing";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { css, cx } from "@emotion/css";
import { mq } from "../styles/styleUtils";
import { assertUnreachable, parseUrl, toRoutingName } from "../store/misc";
import { useLibraryPages } from "../store/library";
import { usePage } from "../store/pages";
import PageList from "./shared/PageList";
import FileIcon from "./shared/FileIcon";
import ToolsReleaseCalendar from "./LibraryReleaseCalendar";
import Video from "./shared/Video";

import OpenInNewIcon from "mdi-react/OpenInNewIcon";

const routeToLibraryPage = (libraryPageRoutingName: string) => Routes.LibraryPageRouteName.create({ libraryPageRoutingName });

const Library: React.FC = () => {
  const { libraryPages } = useLibraryPages();

  const sideMenuData = {
    title: "Bibliotek",
    description: "Samlet materiale og info",
    body: (
      <>
        {libraryPages?.all.map((page) => (
          <NavLink to={routeToLibraryPage(page.routingName)} activeClassName="is-active" key={page.sys.id}>
            {page.name}
          </NavLink>
        ))}
        <NavLink to={Routes.LibraryReleaseCalendar.create({})} activeClassName="is-active">
          Releasekalender
        </NavLink>
      </>
    ),
  };

  return (
    <Page sideMenuData={sideMenuData}>
      <section className="section">
        <div className="content-container ">
          <Switch>
            <Route exact path={Routes.Library.template()} component={ToolsOverview} />
            <Route exact path={Routes.LibraryReleaseCalendar.template()} component={ToolsReleaseCalendar} />
            <Route path={Routes.LibraryPageRouteName.template()} component={LibraryPage} />
            <Route>
              <Redirect to={Routes.Library.template()} />
            </Route>
          </Switch>
        </div>
      </section>
    </Page>
  );
};

function ToolsOverview() {
  const { libraryPages, libraryPagesFetchError, isLoadingLibraryPages } = useLibraryPages();

  return (
    <>
      <Breadcrumb />

      <PageHeader title="Bibliotek" subtitle="Samlet materiale og info" />

      <Loader isLoading={isLoadingLibraryPages}>
        <LoadingProblemBanner show={Boolean(!!!libraryPages && libraryPagesFetchError)}>
          Der er i øjeblikket problemer med at hente informationer omkring værtøjer
        </LoadingProblemBanner>

        {libraryPages && libraryPages.all.length !== 0 && <Redirect to={routeToLibraryPage(libraryPages.all[0].routingName)} />}
      </Loader>
    </>
  );
}

const pageStyles = css`
  .highlights {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(auto-fit, minmax(235px, 1fr));
    grid-auto-rows: 1fr;

    &.single .box {
      ${mq.tabletOnly} {
        width: 50%;
      }

      ${mq.widescreen} {
        width: 50%;
      }
    }

    .icon {
      opacity: 0.8;
    }
  }
`;

function LibraryPage() {
  const { libraryPages } = useLibraryPages();
  const { params } = useRouteMatch<RouteParams<"LibraryPageRouteName">>();
  const { page, pageFetchError, isLoadingPage } = usePage(libraryPages?.findByRoutingName(params.libraryPageRoutingName)?.sys.id);

  const isFullWidth = (page?.secondaryLists?.total ?? 0) === 0;

  const matchedVideoRoute = matchPath<RouteParams<"LibraryPageRouteNameVideosVideoIdRouteName">>(
    useLocation().pathname,
    Routes.LibraryPageRouteNameVideosVideoIdRouteName.template()
  );

  if (libraryPages && !libraryPages.findByRoutingName(params.libraryPageRoutingName)) {
    return <Redirect to={Routes.Library.create({})} />;
  }

  const showVideo = !!(matchedVideoRoute && matchedVideoRoute.params.videoId);

  const selectedVideoItem = matchedVideoRoute?.params.videoId
    ? page?.allLinkedVideoItems.find((itm) => itm.sys.id === matchedVideoRoute.params.videoId)
    : undefined;

  const selectedVideoItemRoutingName = selectedVideoItem?.title ? toRoutingName(selectedVideoItem.title) : undefined; //TODO: redirect if routing name doesn't match

  const urlRetrievers: Parameters<typeof PageList>[0]["urlRetrievers"] = {
    videoItemUrlRetriever: (item) => ({
      kind: "route",
      href: Routes.LibraryPageRouteNameVideosVideoIdRouteName.create({
        ...params,
        videoId: item.sys.id,
        videoRoutingName: toRoutingName(item.title ?? "loading"),
      }),
    }),
  };

  return (
    <>
      <Breadcrumb
        mapUrlSegmentToName={(segment) => {
          return segment === matchedVideoRoute?.params.videoId // Don't show video ID in breadcrumb
            ? false
            : segment === selectedVideoItemRoutingName // Show actual video title not the video routing name in breadcrumb
            ? selectedVideoItem?.title
            : libraryPages?.findNameByRoutingName(segment);
        }}
      />

      <Loader isLoading={isLoadingPage}>
        <LoadingProblemBanner show={Boolean(!!!page && pageFetchError)}>
          Der er i øjeblikket problemer med at hente informationer for det valgte værktøj
        </LoadingProblemBanner>

        {page && <PageHeader title={page.title ?? ""} maxInlineSizeInChars={NaN} />}

        {page &&
          (showVideo ? (
            selectedVideoItem?.video?.playbackId ? (
              <div className="pt-3">
                <Video playbackId={selectedVideoItem.video.playbackId} />
              </div>
            ) : (
              <Redirect to={Routes.LibraryPageRouteName.create(params)} />
            )
          ) : (
            <div className={pageStyles}>
              {isFullWidth ? (
                <PageMainContent page={page} urlRetrievers={urlRetrievers} />
              ) : (
                <div className="columns is-desktop pb-5">
                  <div className="column is-7-desktop is-8-widescreen">
                    <PageMainContent page={page} urlRetrievers={urlRetrievers} />
                  </div>
                  <div className="column is-5-desktop is-4-widescreen">
                    {page.secondaryLists?.items.map((list) => (
                      <PageList list={list} urlRetrievers={urlRetrievers} key={list.sys.id} />
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
      </Loader>
    </>
  );
}

function PageMainContent({
  page,
  urlRetrievers,
}: {
  page: NonNullable<ReturnType<typeof usePage>["page"]>;
} & Pick<Parameters<typeof PageList>[0], "urlRetrievers">) {
  return (
    <>
      <div className="content">{page.description && documentToReactComponents(page.description.json)}</div>
      {page.highlights?.items.length && (
        <div className={cx({ "highlights py-5": true, single: page.highlights.items.length === 1 })}>
          {page.highlights.items.map((entry, idx) => {
            switch (entry.__typename) {
              case "AssetItem":
                return (
                  <a
                    className="has-cta"
                    title={entry.title ?? undefined}
                    href={entry.asset?.url ?? "#"}
                    key={entry.sys.id + idx} // idx is used because the same entry can potentially occur in the list multiple times :(
                  >
                    <div className={`box is-shadowless is-fullheight cta-box cta-box-${idx % 3} is-flex is-flex-direction-column`}>
                      <p className="has-text-weight-bold pb-3">{entry.title ?? entry.asset?.title}</p>
                      <div className="is-size-8 pb-3">
                        {(entry.description?.json && documentToReactComponents(entry.description.json)) ?? entry.asset?.description}
                      </div>
                      <div className="icon is-flex-grow-1 is-align-items-flex-end">
                        <FileIcon {...entry.asset} />
                      </div>
                    </div>
                  </a>
                );
              case "LinkItem":
                return (
                  <a
                    className="has-cta"
                    title={entry.title ?? undefined}
                    href={entry.link ?? "#"}
                    target="_blank"
                    rel="noreferrer"
                    key={entry.sys.id + idx} // idx is used because the same entry can potentially occur in the list multiple times :(
                  >
                    <div className={`box is-shadowless is-fullheight cta-box cta-box-${idx % 3} is-flex is-flex-direction-column`}>
                      <p className="has-text-weight-bold pb-3">{entry.title}</p>
                      <div className="is-size-8 pb-3">{entry.description?.json && documentToReactComponents(entry.description.json)}</div>
                      <div className="is-flex is-flex-grow-1 is-align-items-flex-end">
                        <span className="icon">
                          <OpenInNewIcon />
                        </span>
                        <p className="is-size-8 pl-2 link-text">{entry.linkText || parseUrl(entry.link)?.hostname}</p>
                      </div>
                    </div>
                  </a>
                );
              default:
                return assertUnreachable(entry);
            }
          })}
        </div>
      )}
      {page.mainLists?.items.length ? (
        <div className="py-5">
          {page.mainLists.items.map((list) => (
            <PageList list={list} iconsTrailing={true} urlRetrievers={urlRetrievers} key={list.sys.id} />
          ))}
        </div>
      ) : null}
    </>
  );
}

export default Library;
