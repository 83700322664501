import { cx, css } from "@emotion/css";
import React from "react";

const PageHeader: React.FC<{
  title: string;
  subtitle?: string | JSX.Element;
  padding?: boolean;
  maxInlineSizeInChars?: number;
  className?: string;
}> = ({ title, subtitle, padding = true, maxInlineSizeInChars = 60, className = "" }) => (
  <div className={cx({ [className]: true, "py-5": padding })}>
    {title && <h1 className="title is-4 mb-3 is-spaced">{title}</h1>}
    {subtitle ? (
      <div
        className={
          "subtitle is-size-7 " +
          css`
            ${Number.isNaN(maxInlineSizeInChars) ? "" : `max-inline-size: ${maxInlineSizeInChars}ch;`};
          `
        }
      >
        {subtitle}
      </div>
    ) : null}
  </div>
);

export default PageHeader;
