import { MdiReactIconComponentType } from "mdi-react";
import FileImageOutlineIcon from "mdi-react/FileImageOutlineIcon";
import FilePdfOutlineIcon from "mdi-react/FilePdfOutlineIcon";
import FileCheckOutlineIcon from "mdi-react/FileCheckOutlineIcon";
import FilePowerpointOutlineIcon from "mdi-react/FilePowerpointOutlineIcon";
import FileVideoOutlineIcon from "mdi-react/FileVideoOutlineIcon";

const toEntryOf = (iconComp: MdiReactIconComponentType) => (ext: string) => [ext, iconComp] as const;

const fileIcons = [
  ["pdf"].map(toEntryOf(FilePdfOutlineIcon)),
  ["pptx", "pptm", "ppt"].map(toEntryOf(FilePowerpointOutlineIcon)),
  ["mp4", "mov", "wmv", "avi", "avchd", "flv,", "f4v,", "swf", "mkv"].map(toEntryOf(FileVideoOutlineIcon)),
  ["jpeg", "jpg", "png", "gif", "tiff"].map(toEntryOf(FileImageOutlineIcon)),
].flat(1);

const fileIconsMap = new Map(fileIcons);

export function getFileIcon(fileExt?: string | null): MdiReactIconComponentType {
  return fileIconsMap.get(fileExt?.toLowerCase().split(".").pop() || "") ?? FileCheckOutlineIcon;
}

export const map = <T, TVal>(val: TVal, mapFn: (arg: TVal) => T) => mapFn(val);

export function parseUrl(str?: string | null): URL | undefined {
  try {
    return new URL(str ?? "");
  } catch (err) {
    return undefined;
  }
}

export function escapeXml(str: string): string {
  return str
    .replaceAll("&", "&amp;")
    .replaceAll("<", "&lt;")
    .replaceAll(">", "&gt;")
    .replaceAll("\"", "&quot;")
    .replaceAll("'", "&apos;");
}

export const toRoutingName = (name?: string | null) =>
  name
    ?.toLowerCase()
    .replace(/[\s.,%?#/]/g, " ")
    .trim()
    .replace(/ +/g, "-") ?? "";

export function assertUnreachable(_: never): never {
  throw new Error("Didn't expect to get here");
}

export function chunkArray<T>(arr: T[], chunkSize: number): T[][] {
  chunkSize = Math.abs(chunkSize);
  return Array.from({ length: Math.ceil(arr.length / chunkSize) }, (_, i) => arr.slice(i * chunkSize, i * chunkSize + chunkSize));
}

export const normalizeString = (str: string) =>
  str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();

export type Branded<K, T> = K & { __brand?: T };

export type NormalizedSearchWords = Branded<readonly string[], "NormalizedSearchWords">;

export const splitToNormalizedWords = (wordsString: string): NormalizedSearchWords =>
  normalizeString(wordsString)
    .trim()
    .split(" ")
    .filter((s) => s.length > 1);

function videoItemsFrom(lists?: PageListsPropertiesTypes): DeepArrayUnmaybefy<VideoItemFieldsFragment>[] {
  const videoItems = lists
    ?.filter((lst): lst is DeepArrayUnmaybefy<ItmListFragment> => lst.__typename === "ItemList")
    .flatMap((lst) => lst.items?.items.filter((item): item is DeepArrayUnmaybefy<VideoItemFieldsFragment> => item.__typename === "VideoItem") ?? []);
  return videoItems ?? [];
}

export function allLinkedVideoItems(page: PageListsProperties): DeepArrayUnmaybefy<VideoItemFieldsFragment>[] {
  return [...videoItemsFrom(page.mainLists?.items), ...videoItemsFrom(page.secondaryLists?.items)];
}
