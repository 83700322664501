import React, { useEffect, useState } from "react";
import { Link, Redirect, useLocation } from "react-router-dom";
import { useAuth } from "oidc-react";
import PageHeader from "./shared/PageHeader";
import { useContactContent } from "../store/contact";

const LoginRedirect: React.FC = () => {
  const auth = useAuth();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const error = queryParams.get("error");
  const [endSessionEndpoint, setEndSessionEndpoint] = useState<string | null>(null);
  const { contactContent } = useContactContent();

  if (error) {
    auth.userManager.clearStaleState();
  }

  useEffect(() => {
    if (error === "access_denied" && endSessionEndpoint === null) {
      setEndSessionEndpoint("retrieving");
      auth.userManager.metadataService.getEndSessionEndpoint().then((endpoint) => endpoint && setEndSessionEndpoint(endpoint));
    }
  }, [auth.userManager.metadataService, error, endSessionEndpoint]);

  return auth.userData?.state?.postSignInPath ? (
    <Redirect to={auth.userData.state.postSignInPath} />
  ) : (
    <section className="section">
      <div className="container is-max-widescreen">
        <div className="columns py-5">
          <div className="column is-offset-2-widescreen is-8-widescreen px-4 has-text-centered">
            {error && (
              <>
                {endSessionEndpoint?.startsWith("http") && (
                  <iframe src={endSessionEndpoint} title="SLO on Access Denied" width={9} height={0}></iframe>
                )}
                <PageHeader
                  title="Login mislykkedes"
                  subtitle={
                    <div className="pt-2">
                      Adgang til Nuuday Partner Portal nægtet. Du kan forsøge igen, eller kontakte Nuuday Mobile Partners på tlf.{" "}
                      {contactContent?.contactPhone}, hvis problemet fortsætter.
                    </div>
                  }
                  className="is-flex is-flex-direction-column is-align-items-center"
                />
                <Link className="button is-rounded pt-3 is-primary mt-3" to="/">
                  Tilbage til forsiden
                </Link>
              </>
            )}
            {!!!error && <p>Du logges ind...</p>}
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginRedirect;
