import React from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import { RouteParams, Routes } from "../store/routing";
import { useMobileServiceProvider } from "../store/mobileServiceProviders";
import { css } from "@emotion/css";
import Page from "./shared/Page";
import Breadcrumb from "./shared/Breadcrumb";
import DashboardTopRow from "./DashboardTopRow";
import DashboardContentHighlights from "./DashboardContentHighlights";
import DashboardContentLists from "./DashboardContentLists";
import DashboardNewsTable from "./DashboardNewsTable";
import DashboardMessages from "./DashboardMessages";
import DashboardMessagesMessage from "./DashboardMessagesMessage";
import { useVideoItem } from "../store/videos";
import Video from "./shared/Video";
import PageHeader from "./shared/PageHeader";

const Dashboard: React.FC = () => {
  return (
    <Page>
      <section className="section">
        <div className="content-container">
          <Switch>
            <Route exact path={Routes.Dashboard.template()} component={DashboardDashboard} />
            <Route exact path={Routes.DashboardVideosVideoIdRouteName.template()} component={DashboardVideos} />
            <Route exact path={Routes.DashboardMessagesDirect.template()}>
              <Redirect to={Routes.DashboardMessages.create({})} />
            </Route>
            <Route
              exact
              path={Routes.DashboardMessagesDirectMsgIdMsgRouteName.template()}
              render={(p) => {
                return (
                  <DashboardMessagesMessage
                    params={p.match.params as RouteParams<"DashboardMessagesDirectMsgIdMsgRouteName">}
                    createRouteToMessage={(params, msgType) => {
                      return msgType === "direct"
                        ? Routes.DashboardMessagesDirectMsgIdMsgRouteName.create(params)
                        : Routes.DashboardMessagesMsgIdMsgRouteName.create(params);
                    }}
                    doForcedRedirect={(msg) => msg.msgType !== "direct"}
                    backUrl={Routes.Dashboard.create({})}
                  />
                );
              }}
            />
            <Route path={Routes.DashboardMessages.template()} component={DashboardMessages} />
            <Route>
              <Redirect to={Routes.Dashboard.create({})} />
            </Route>
          </Switch>
        </div>
      </section>
    </Page>
  );
};

const DashboardDashboard: React.FC = () => {
  const spName = useMobileServiceProvider().mobileServiceProvider?.name;

  return (
    <>
      <Breadcrumb />

      <div className="is-flex is-justify-content-space-between is-align-items-flex-end pb-1">
        <h1 className="title is-4 pt-5 mb-2">{spName ?? "..."}</h1>
        <div
          className={
            "is-size-5 pr-6 " +
            css`
              line-height: 1;
            `
          }
        >
          Kontrolpanel
        </div>
      </div>

      <DashboardTopRow />
      <DashboardContentHighlights />
      <DashboardContentLists />
      <DashboardNewsTable />
    </>
  );
};

const DashboardVideos: React.FC = () => {
  const { videoId, videoRoutingName } = useParams<RouteParams<"DashboardVideosVideoIdRouteName">>();
  const { videoItem } = useVideoItem(videoId);

  // Fix URL if the video item routing name doesn't match
  if (videoItem && videoRoutingName !== videoItem.routingName) {
    return <Redirect to={Routes.DashboardVideosVideoIdRouteName.create({ videoId: videoItem.sys.id, videoRoutingName: videoItem.routingName })} />;
  }

  return (
    <>
      <Breadcrumb
        mapUrlSegmentToName={(segment) => {
          return segment === videoId // Don't show video ID in breadcrumb
            ? false
            : segment === videoRoutingName // Show actual video title not the video routing name in breadcrumb
            ? videoItem?.title
            : "";
        }}
      />

      <PageHeader title={videoItem?.title ?? "..."} />

      {videoItem && videoItem.video?.playbackId && <Video playbackId={videoItem.video.playbackId} />}
    </>
  );
};

export default Dashboard;
