import React from "react";
import { RouteProps } from "react-router";
import { Route } from "react-router-dom";
import { useAuth } from "oidc-react";
import { signInAndTryRemainOnCurrentPathArgs, useIsAuthenticated } from "../../store/authentication";

const AuthorizeRoute: React.FC<RouteProps> = (props) => {
  const auth = useAuth();
  const isAuthenticated = useIsAuthenticated();

  if (!isAuthenticated) {
    return (
      <section className="section">
        <div className="container is-max-widescreen is-flex is-flex-direction-column is-justify-content-center">
          <div className="columns">
            <div className="column is-three-fifths is-offset-one-fifth has-text-centered">
              <p className="p-4">Du skal være logget ind for at se denne side</p>
              <button
                className="button is-rounded is-large pt-4"
                onClick={() => auth.signIn(signInAndTryRemainOnCurrentPathArgs())}
              >
                Log ind
              </button>
            </div>
          </div>
        </div>
      </section>
    );
  }

  return <Route {...props} />;
};

export default AuthorizeRoute;
