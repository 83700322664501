import React from "react";
import { css } from "@emotion/css";
import { FasDataItemPlanningStatus, useGetFasData } from "../store/operations";
import Page from "./shared/Page";
import { Link, useRouteMatch } from "react-router-dom";
import { RouteParams, Routes } from "../store/routing";
import OperationInformationItemDetails from "./OperationInformationItemDetails";
import OperationsInformationPageHeader from "./OperationsInformationPageHeader";
import Loader from "./shared/Loader";
import LoadingProblemBanner from "./shared/LoadingProblemBanner";

import ChevronLeftIcon from "mdi-react/ChevronLeftIcon";

const statusLabels: { [K in FasDataItemPlanningStatus]: string } = {
  [FasDataItemPlanningStatus.Completed]: "Løst",
  [FasDataItemPlanningStatus.Planned]: "Aktuel fejl",
  [FasDataItemPlanningStatus.Unplanned]: "Planlagt",
};

const OperationsInformationItem: React.FC = () => {
  const { params } = useRouteMatch<RouteParams<"OperationsInformationFasId">>();
  const { fasData, fasDataFetchError, isLoadingFasData } = useGetFasData();
  const selectedFasDataItem = fasData?.all.find((itm) => itm.fasId === params.fasId);

  return (
    <Page>
      <section className="section">
        <div className="container is-max-widescreen">
          <OperationsInformationPageHeader title="Driftsinformation" subtitle="Detaljeret beskrivelse af driftsforstyrrelsen på mobilnetværket" />

          <Link className="is-size-7 is-primary is-inverted" to={Routes.OperationsInformation.create({})}>
            <span className="icon-text">
              <span className="icon" style={{ marginTop: "-0.2em" }}>
                <ChevronLeftIcon size={"1.5em"} />
              </span>
              Tilbage til oversigten
            </span>
          </Link>

          <Loader isLoading={isLoadingFasData}>
            <LoadingProblemBanner wrapperClassName="my-5" show={Boolean(!!!fasData && fasDataFetchError)}>
              Der er i øjeblikket problemer med at hente information om netværk og services
            </LoadingProblemBanner>

            <LoadingProblemBanner wrapperClassName="my-5" show={Boolean(!!!fasDataFetchError && !selectedFasDataItem)}>
              Driftmeddelelse med ID: #{params.fasId} kan ikke fremsøges.
            </LoadingProblemBanner>

            {selectedFasDataItem && (
              <div
                className={
                  "mt-3 pt-4 pl-5 " +
                  css`
                    border-top: 1px solid var(--color-grey-lighter);
                    border-bottom: 1px solid var(--color-grey-lighter);
                  `
                }
              >
                <div className="is-flex is-size-8 pb-5">
                  <span className="pr-6">{statusLabels[selectedFasDataItem.planningStatus]}</span>
                  <span>Område: {selectedFasDataItem.geography === "Flere danske områder" ? "Flere områder" : selectedFasDataItem.geography}</span>
                </div>

                <OperationInformationItemDetails item={selectedFasDataItem} showTitle={true} />
              </div>
            )}
          </Loader>
        </div>
      </section>
    </Page>
  );
};

export default OperationsInformationItem;
