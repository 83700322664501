import { css, cx } from "@emotion/css";

import ChevronLeftIcon from "mdi-react/ChevronLeftIcon";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";

const paginationStyles = css`
  .pagination-previous,
  .pagination-next {
    border: none !important;
    box-shadow: none !important;
  }

  .pagination-list {
    flex-wrap: nowrap !important;
  }

  button {
    background-color: transparent;

    span.icon {
      position: absolute;
    }
  }
`;

const Pagination: React.FC<{
  pages: number;
  page: number;
  siblingCount: number;
  setPage: (page: number) => void;
  before?: JSX.Element;
  after?: JSX.Element;
}> = ({ pages, page, siblingCount, setPage, before, after }) => {
  if (pages < 2) {
    return null;
  }

  siblingCount = Math.max(1, siblingCount);

  const pageAndSiblings = Array.from(Array(siblingCount * 2 + 1).keys())
    .map((k) => k - siblingCount + page)
    .filter((n) => n > 1 && n < pages);

  const leadingEllipsis = pageAndSiblings.length > 0 && pageAndSiblings[0] !== 2;
  const trailingEllipsis = pageAndSiblings.length > 0 && pageAndSiblings.slice(-1).pop() !== pages - 1;
  const pagesToShow = [1, leadingEllipsis, ...pageAndSiblings, trailingEllipsis, pages];

  return (
    <nav className={"pagination is-rounded is-centered is-justify-content-center pt-5 " + paginationStyles}>
      <div className="pagination-previous">{before}</div>
      <div className="pagination-next">{after}</div>

      <ul className="pagination-list is-flex-grow-0 m-2">
        <li className="is-hidden-touch">
          <button onClick={() => setPage(Math.max(1, page - 1))} className="pagination-link">
            <span className="icon is-medium">
              <ChevronLeftIcon />
            </span>
          </button>
        </li>
        {pagesToShow.map((p, idx) => (
          <li key={`${idx}_${p}`}>
            {typeof p === "number" ? (
              <button onClick={() => setPage(p)} className={cx({ "pagination-link pt-3 is-clickable": true, "is-current": p === page })}>
                {p}
              </button>
            ) : p ? (
              <span className="pagination-ellipsis">…</span>
            ) : null}
          </li>
        ))}
        <li className="is-hidden-touch">
          <button onClick={() => setPage(Math.min(pages, page + 1))} className="pagination-link">
            <span className="icon is-medium">
              <ChevronRightIcon />
            </span>
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
