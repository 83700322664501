import { contentfulConfig } from "./configuration";
import { ClientError, gql, request } from "graphql-request";
import { useSWRCurried } from "./fetching";

const contactContentFetcher = (_: string, id: string) => {
  return request<DeepArrayUnmaybefy<ContactContentQuery>>(
    contentfulConfig.graphqlEndpoint,
    gql`
      query ContactContent {
        contactContentCollection(limit: 1) {
          total
          items {
            sys {
              id
            }
            pageHeaderTitle
            pageHeaderSubtitle
            contactEmail
            contactPhone
            contactPhoneOutsideOfficeHours
            openingHours {
              json
            }
          }
        }
      }
    `,
    {
      id,
    }
  );
};

export function useContactContent() {
  const { data, error } = useSWRCurried<ClientError>()("contentful/contact-content", contactContentFetcher);

  return {
    contactContent: data?.contactContentCollection?.total === 1 ? data.contactContentCollection.items[0] : undefined,
    contactContentFetchError: error,
    isLoadingContactContent: !data && !error,
  };
}
