import React from "react";
import Loader from "./shared/Loader";
import LoadingProblemBanner from "./shared/LoadingProblemBanner";
import { Link } from "react-router-dom";
import { useDashboardContent } from "../store/dashboard";
import { assertUnreachable, chunkArray, toRoutingName } from "../store/misc";
import { css } from "@emotion/css";
import { mq } from "../styles/styleUtils";
import DashboardSectionHeading from "./DashboardSectionHeading";
import { Routes } from "../store/routing";

import OpenInNewIcon from "mdi-react/OpenInNewIcon";
import ChevronLeftIcon from "mdi-react/ChevronLeftIcon";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import FileIcon from "./shared/FileIcon";
import PlayBoxIcon from "mdi-react/PlayBoxIcon";

type DashboardListItemTypename =
  | NonNullable<DeepArrayUnmaybefy<ItmListFragment>["items"]>["items"][number]["__typename"]
  | NonNullable<DeepArrayUnmaybefy<DocListFragment>["documents"]>["items"][number]["__typename"];

type DashboardListItem = {
  __typename: DashboardListItemTypename;
  title: string | null | undefined;
  url: string | null | undefined;
  icon: JSX.Element;
};

type DashboardList = {
  id: string;
  title: string | null | undefined;
  listItems: DashboardListItem[];
};

const listColumnsStyles = css`
  .table {
    thead,
    tfoot {
      background-color: white;
    }

    th,
    td {
      padding: 0;
    }
  }

  tbody tr {
    &:not(.is-selected) {
      &:nth-child(even) {
        background-color: var(--color-white);
      }

      &:not(.filler) {
        &:focus,
        &:active,
        &:hover,
        &:focus-within {
          background-color: var(--color-table-row-hover-background);
        }
      }
    }
  }

  .list-title,
  .item-title {
    display: flex;
    position: relative;

    padding: 1em 3em 0.75em 1em;

    > *:not(.icon) {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 0;
      flex-grow: 1;
    }

    .icon {
      position: absolute;
      right: 1em;
      overflow: visible;

      visibility: hidden;

      color: var(--color-grey-lighter);
    }

    &:focus,
    &:active,
    &:hover,
    &:focus-within {
      .icon {
        visibility: visible;
        color: var(--color-link);
        opacity: 0.5;
      }
    }
  }

  .single-page {
    .p-container {
      visibility: hidden;
    }

    ${mq.mobile} {
      tfoot,
      .filler {
        display: none;
      }
    }
  }

  .p-container {
    padding: 0.15rem 0.2rem 0 0;

    .p-prv,
    .p-nxt {
      display: flex;
      align-items: center;
    }

    button {
      display: flex;
      align-items: center;

      padding: 0;

      background-color: transparent;
      border: 1px solid transparent;
      border-radius: 2px;

      color: var(--color-grey-dark);

      &:focus,
      &:active,
      &:hover,
      &:focus-within {
        border-color: var(--color-primary);
        color: var(--color-primary);
      }
    }

    .items-shown-indicator {
      padding-top: 0.15em;
      padding-right: 1em;

      span + span:before {
        content: "/";
        margin: 0.125rem;
      }

      ${mq.mobile} {
        padding-right: 0.5em;
      }
    }
  }
`;

const RenderListInColumn: React.FC<{ list: DashboardList | undefined; longestList: number; pageSize: number }> = ({
  list,
  longestList,
  pageSize,
}) => {
  const [pageIndex, setPageIndex] = React.useState(0);
  const listLength = list?.listItems.length ?? 0;
  const pages = Math.floor(listLength / pageSize) + 1;
  const rowsOnCurrentPage = Math.min(pageSize, listLength - pageIndex * pageSize);
  const numFillerRows = !list ? pageSize : pageSize - rowsOnCurrentPage;
  const fillerRows = Array.from({ length: numFillerRows }, () => null);
  const atLeastOneListHasPagination = longestList > pageSize;

  return (
    <div className={"column is-one-third" + (!list ? " is-hidden-mobile" : "") + (pages < 2 ? " single-page" : "")}>
      <div className="card is-radiusless">
        <table className="table is-fullwidth is-size-8" role="table">
          <thead className="is-size-7">
            <tr role="row">
              <th role="columnheader" className="has-text-grey-dark">
                <div className="list-title">
                  <div>{list?.title ?? <>&nbsp;</>}</div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {list?.listItems.slice(pageIndex * pageSize, pageIndex * pageSize + pageSize).map((item, idx) => (
              <tr role="row" key={`${item.title} ${item.url} ${idx}`}>
                <td role="cell">
                  {item.__typename === "VideoItem" ? (
                    <Link className="item-title is-inverted" to={item.url ?? "#"}>
                      <span>{item.title}</span>
                      <div className="icon is-small ml-1">{item.icon}</div>
                    </Link>
                  ) : (
                    <a href={item.url ?? "#"} target="_blank" rel="noreferrer" className="item-title is-inverted">
                      <span>{item.title}</span>
                      <div className="icon is-small ml-1">{item.icon}</div>
                    </a>
                  )}
                </td>
              </tr>
            ))}
            {fillerRows.map((_, idx) => (
              <tr className="filler" key={idx}>
                <td>
                  <div className="item-title">&nbsp;</div>
                </td>
              </tr>
            ))}
          </tbody>
          {atLeastOneListHasPagination && (
            <tfoot>
              <tr>
                <td className="p-1">
                  <nav className="p-container is-flex is-justify-content-flex-end" role="navigation" aria-label="pagination">
                    <div className="p-prv pr-1">
                      <div className="items-shown-indicator">
                        <span>
                          {pageIndex * pageSize + 1}-{Math.min((pageIndex + 1) * pageSize, list?.listItems.length ?? 0)}
                        </span>
                        <span>{list?.listItems.length ?? 0}</span>
                      </div>
                      <button onClick={() => setPageIndex(pageIndex - 1 < 0 ? pages - 1 : pageIndex - 1)}>
                        <span className="icon">
                          <ChevronLeftIcon />
                        </span>
                      </button>
                    </div>
                    <div className="p-nxt pr-1">
                      <button onClick={() => setPageIndex((pageIndex + 1) % pages)}>
                        <span className="icon">
                          <ChevronRightIcon />
                        </span>
                      </button>
                    </div>
                  </nav>
                </td>
              </tr>
            </tfoot>
          )}
        </table>
      </div>
    </div>
  );
};

function DashboardContentLists() {
  const { dashboardContent, dashboardContentFetchError, isLoadingDashboardContent } = useDashboardContent();

  const mainLists: DashboardList[] = dashboardContent?.mainLists
    ? dashboardContent.mainLists.items.map((list) => ({ id: list.sys.id, title: list.title ?? "", listItems: getItems(list) }))
    : [];

  return (
    <div className={"block " + listColumnsStyles}>
      <Loader isLoading={isLoadingDashboardContent}>
        <LoadingProblemBanner show={Boolean(!!!dashboardContent && dashboardContentFetchError)}>
          Der er i øjeblikket problemer med at hente data
        </LoadingProblemBanner>

        {dashboardContent ? (
          <>
            <DashboardSectionHeading text={dashboardContent.mainListsLabel} />
            {chunkArray(mainLists, 3).map((chunk) => {
              const longestList = Math.max(0, ...chunk.map((list) => list.listItems.length));
              const maxPageSize = 7;
              const pageSize = Math.min(longestList, maxPageSize);

              return (
                <div className="columns" key={`${chunk[0]?.id} ${chunk[1]?.id} ${chunk[2]?.id}`}>
                  <RenderListInColumn list={chunk[0]} {...{ longestList, pageSize }} />
                  <RenderListInColumn list={chunk[1]} {...{ longestList, pageSize }} />
                  <RenderListInColumn list={chunk[2]} {...{ longestList, pageSize }} />
                </div>
              );
            })}
          </>
        ) : null}
      </Loader>
    </div>
  );
}

function getItems<T extends DeepArrayUnmaybefy<DocListFragment | ItmListFragment>>(entry: T): DashboardListItem[] {
  switch (entry.__typename) {
    case "DocumentList":
      return (
        entry.documents?.items.map((itm) => ({
          __typename: itm.__typename,
          title: itm.title,
          url: itm.url,
          icon: <FileIcon contentType={itm.contentType} size="1rem" />,
        })) ?? []
      );
    case "ItemList":
      return (
        entry.items?.items.map((itm) => {
          const __typename = itm.__typename;
          switch (__typename) {
            case "AssetItem":
              return { __typename, title: itm.title, url: itm.asset?.url, icon: <FileIcon contentType={itm.asset?.contentType} size="1rem" /> };
            case "LinkItem":
              return { __typename, title: itm.title, url: itm.link, icon: <OpenInNewIcon size="1rem" /> };
            case "VideoItem":
              return {
                __typename,
                title: itm.title,
                url: itm.video
                  ? Routes.DashboardVideosVideoIdRouteName.create({ videoId: itm.sys.id, videoRoutingName: toRoutingName(itm.title ?? "loading") })
                  : "#",
                icon: <PlayBoxIcon size="1rem" style={{ position: "absolute", top: "translateY(-0.0625rem)" }} />,
              };
            default:
              return assertUnreachable(itm);
          }
        }) ?? []
      );
    default:
      return assertUnreachable(entry);
  }
}

export default DashboardContentLists;
