import { css } from "@emotion/css";
import { mq } from "../../styles/styleUtils";

const heroImageScale = 0.85;
const heroImageWidth = 961 * heroImageScale;
const heroImageHeight = 541 * heroImageScale;

export default function NppHero({ title, subtitle }: { title: string; subtitle?: string }) {
  return (
    <div
      className={css`
        background: linear-gradient(90deg, #b5c64c 50%, #4a47e6 0);
        mixblendmode: normal;
      `}
    >
      <div
        className={
          "is-relative content-container " +
          css`
            background: linear-gradient(90deg, #b5c64c 13.76%, #4a47e6 100%);
            mixblendmode: normal;
          `
        }
      >
        <div
          className={
            "hero pt-3 " +
            css`
              ${mq.mobile} {
                .hero-body {
                  padding-top: 1.25rem;
                  padding-bottom: 1.5rem;
                }
              }
              &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                opacity: 0.5;
                background: url(/npp-nuuday-curl.png) no-repeat left -130px / ${heroImageWidth}px ${heroImageHeight}px;
              }
            `
          }
        >
          <div className="hero-body is-relative">
            <div className="columns px-4">
              <div className="column has-text-right is-7-tablet is-offset-5-tablet">
                <div
                  className="title mb-0 is-4 has-text-white"
                  style={{ textShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)" }}
                >
                  {title}
                </div>
                {subtitle && <div className="mt-4 has-text-white is-size-6 is-hidden-mobile">{subtitle}</div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
