import { css } from "@emotion/css";
import React from "react";
import PageHeader from "./shared/PageHeader";

import { ReactComponent as OperationsIcon } from "../styles/icon-operations-information.svg";

export const topIconStyles = css`
  width: 3.75rem;
  height: 3.75rem;
  flex-shrink: 0;
  margin-right: 2rem;
  margin-top: -0.4rem;
  margin-bottom: 1rem;
`;

const OperationsInformationPageHeader: React.FC<{ title: string; subtitle: string }> = (props) => (
  <div className="is-flex-tablet py-5">
    <OperationsIcon className={topIconStyles} />
    <PageHeader {...props} maxInlineSizeInChars={85} padding={false} className="ml-1" />
  </div>
);

export default OperationsInformationPageHeader;
