import { css } from "@emotion/css";
import React from "react";
import { useParams, generatePath, Link } from "react-router-dom";
import { useApplications } from "../store/applications";
import { RouteParams } from "../store/routing";
import ApplicationsReportErrorForm from "./ApplicationsReportErrorForm";
import Loader from "./shared/Loader";
import LoadingProblemBanner from "./shared/LoadingProblemBanner";
import PageHeader from "./shared/PageHeader";

const ApplicationsReportError: React.FC<{ applicationPickRoute: string }> = (props) => {
  const { applications, applicationsFetchError, isLoadingApplications } = useApplications();
  const { appRoutingName } = useParams<Partial<RouteParams<"AppsAppRouteNameErrorReports">>>();

  const selectedApplication = appRoutingName !== undefined ? applications?.findByRoutingName(appRoutingName) : undefined;

  const buildApplicationPickPath = (appRoutingName: string) => generatePath(props.applicationPickRoute, { appRoutingName });

  return selectedApplication ? (
    <ApplicationsReportErrorForm application={selectedApplication} />
  ) : (
    <>
      <PageHeader title="Opret fejlmelding - Vælg IT-applikation" />

      <Loader isLoading={isLoadingApplications}>
        <LoadingProblemBanner show={Boolean(!!!applications && applicationsFetchError)}>
          Der er i øjeblikket problemer med at hente informationer omkring IT-applikationerne
        </LoadingProblemBanner>

        {applications && applications.all.length > 0 && (
          <>
            <p className="pt-3 pb-5 is-size-7">Hvilken IT-applikation drejer det sig om?</p>

            <nav
              className={css`
                display: grid;
                gap: 0.75rem 1.5rem;
                grid-template-columns: repeat(auto-fill, minmax(265px, 1fr));
              `}
            >
              {applications.all.map((app) => (
                <Link key={app.applicationId} to={buildApplicationPickPath(app.routingName)} className="button is-rounded has-background-white pt-3">
                  <div className="is-size-7">{app.applicationName}</div>
                </Link>
              ))}
            </nav>
          </>
        )}
      </Loader>
    </>
  );
};

export default ApplicationsReportError;
