import useSWR from "swr";
import { ConfigInterface, fetcherFn, keyInterface } from "swr/dist/types";

export type NppFetchError = Error & {
  response?: Response;
};

export type RemoteData<E, D> = Readonly<{ kind: "initialized" } | { kind: "pending" } | { kind: "failure"; error: E } | { kind: "success"; data: D }>;

const retryableErrorCodes: ReadonlyArray<number> = [408, 429, 500, 502, 503, 504, 522, 524];

export async function fetchEnsureSuccess(input: RequestInfo, init?: RequestInit): Promise<Response> {
  const response = await fetch(input, init);

  if (response.ok) {
    return response;
  }

  // convert non-2xx HTTP responses into errors
  const error: NppFetchError = new Error(response.statusText);
  error.response = response;
  return Promise.reject(error);
}

export async function fetchEnsureSuccessWithRetry(
  input: RequestInfo,
  init?: RequestInit,
  retries: number = 0,
  backoff: number = 300
): Promise<Response> {
  try {
    const response = await fetch(input, init);
    if (response.ok) {
      return response;
    }

    if (retryableErrorCodes.includes(response.status)) {
      throw response; // throw so we can retry
    }

    // convert non-2xx non-retryable HTTP responses into errors (no retrying)
    const error: NppFetchError = new Error(response.statusText);
    error.response = response;
    return Promise.reject(error);
  } catch (err) {
    if (retries < 1) {
      return Promise.reject(err);
    }
    return new Promise(
      // Note that his could retry non-idempotent POST requests
      (resolve) => setTimeout(() => resolve(fetchEnsureSuccessWithRetry(input, init, --retries, backoff * 2)), backoff)
    );
  }
}

export function makeFlaky<T>(min: number, max: number, flakyness = 0.3, msgPostfix: string = ""): (arg: T) => Promise<T> {
  const ms = Math.max(0, Math.floor(Math.random() * (max - min) + min));
  if (ms === 0 && !flakyness) {
    console.log("makeFlaky called with not throttling and no flakyness... so we do nothing");
    return (prevResult: T) => Promise.resolve(prevResult);
  } else {
    return (prevResult: T) =>
      new Promise<T>((res, rej) => {
        console.log(`Will take another ${ms} ms ... ${msgPostfix}`);
        const doFunc = () => {
          const effectiveFlakyness = Math.max(0, Math.min(1, flakyness));
          if (Math.random() < effectiveFlakyness) {
            console.log(`Effective flakyness ${effectiveFlakyness} ... ${msgPostfix}`);
            const err = new Error(`Flaky connection ... ${msgPostfix}`);
            console.log(err.message);
            rej(err);
          } else {
            const msg = `Got result ... ${msgPostfix}`;
            console.log(msg, prevResult);
            res(prevResult);
          }
        };
        if (ms) {
          setTimeout(doFunc, ms);
        } else {
          doFunc();
        }
      });
  }
}

export type FetcherFnReturnType<T extends NonNullable<fetcherFn<any>>> = T extends NonNullable<fetcherFn<infer Data>> ? Data : never;

export const useSWRCurried = <Error extends any>() =>
  function Inner<Data extends any>(key: keyInterface, fn: NonNullable<fetcherFn<Data>>, config?: ConfigInterface<Data, Error>) {
    return useSWR<FetcherFnReturnType<typeof fn>, Error>(key, fn, config);
  };
