import React, { useState } from "react";
import { Redirect, useRouteMatch } from "react-router-dom";
import { useApplications } from "../store/applications";
import PageHeader from "./shared/PageHeader";
import Tabs, { TabItem } from "./shared/Tabs";
import Loader from "./shared/Loader";
import LoadingProblemBanner from "./shared/LoadingProblemBanner";
import ApplicationsApplicationErrorReportsTable from "./ApplicationsApplicationErrorReportsTable";
import { RouteParams } from "../store/routing";

const tabs: readonly TabItem<number>[] = ["Åbne", "Lukkede"].map((lbl, idx) => ({
  id: idx,
  label: lbl,
}));

const ApplicationsApplication: React.FC = () => {
  const { applications, applicationsFetchError, isLoadingApplications } = useApplications();
  const { url, params } = useRouteMatch<RouteParams<"AppsAppRouteName">>();
  const [selectedTabId, setSelectedTabId] = useState(0);

  const selectedApplication = applications?.findByRoutingName(params.appRoutingName);

  if (applications && !selectedApplication) {
    return <Redirect to={url.substring(0, url.length - (params.appRoutingName.length + 1))} />;
  }

  return (
    <>
      <PageHeader title={selectedApplication?.applicationName ?? "Henter data..."} subtitle={`Oversigt over indsendte fejlmeldinger`} />

      <Loader isLoading={isLoadingApplications}>
        <LoadingProblemBanner show={Boolean(!!!applications && applicationsFetchError)}>
          Der er i øjeblikket problemer med at hente informationer omkring IT-applikationerne
        </LoadingProblemBanner>

        {selectedApplication && <Tabs tabs={tabs} selectedTabId={selectedTabId} setSelectedTab={setSelectedTabId} />}

        {selectedApplication && (
          <>
            {selectedTabId === 0 ? (
              <ApplicationsApplicationErrorReportsTable
                {...selectedApplication}
                show="Open"
                paginationLabel="fejlmeldinger"
                errorReportsReportBaseUrl={url + "/error-reports"}
              />
            ) : selectedTabId === 1 ? (
              <ApplicationsApplicationErrorReportsTable
                {...selectedApplication}
                show="Closed"
                paginationLabel="fejlmeldinger"
                errorReportsReportBaseUrl={url + "/error-reports"}
              />
            ) : null}
          </>
        )}
      </Loader>
    </>
  );
};

export default ApplicationsApplication;
