import { parseISO, formatISO, format } from "date-fns";
import { da } from "date-fns/locale";

export default function DateTimeStamp({
  date,
  dateTimeFormat = "dd.MM.yyyy HH:mm",
  defaultTo = "?",
}: {
  date: string | Date | null | undefined;
  dateTimeFormat?: string;
  defaultTo?: string | JSX.Element;
}) {
  if (!date) {
    return <>{defaultTo}</>;
  }
  const d: Date = typeof date === "string" ? parseISO(date) : date;
  const dateIsoStr = typeof date === "string" ? date : formatISO(date);
  return <time dateTime={dateIsoStr}>{format(d, dateTimeFormat, { locale: da })}</time>;
}
