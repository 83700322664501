import React from "react";
import { css } from "@emotion/css";
import { Link } from "react-router-dom";
import { useTable, useFilters, useSortBy, usePagination, Column, SortByFn, Row, IdType, useGlobalFilter } from "react-table";
import { includesAnyOfFullWords, NewsItem } from "../../store/news";
import { routeToNewsItem } from "../News";
import Pagination from "./Pagination";
import DateStamp from "./DateStamp";
import { createHeaderRenderer } from "./TableHeaderRenderer";
import { mq } from "../../styles/styleUtils";
import { newsletterConfig } from "../../store/configuration";
import { map, splitToNormalizedWords } from "../../store/misc";
import Tabs, { TabItem } from "./Tabs";
import SearchTextBox from "./SearchTextBox";

import OpenInNewIcon from "mdi-react/OpenInNewIcon";

const caseInsesitiveDaStringSort: SortByFn<NewsItem> = (rowA, rowB, colId) =>
  rowA.values[colId].localeCompare(rowB.values[colId], "da", { sensitivity: "base" });

const columns: Column<NewsItem>[] = [
  {
    Header: createHeaderRenderer("Nyhed", "is-flex is-align-items-center"),
    accessor: "headline",
    sortType: caseInsesitiveDaStringSort,
    Cell: ({ value, row }) => (
      <div className="headline-column">
        <img src={row.original.heroImage?.url ?? undefined} alt="" width={68} className="mr-3" />
        <span>{value}</span>
      </div>
    ),
  },
  {
    Header: createHeaderRenderer("Emne", "is-flex is-align-items-center is-justify-content-flex-end"),
    accessor: "tagline",
    Cell: ({ value }) => <div className="has-text-right">{value}</div>,
  },
  {
    width: 50,
    Header: createHeaderRenderer("Dato", "is-flex is-align-items-center is-justify-content-flex-end"),
    accessor: "firstPublishedAtDate",
    Cell: ({ value }) => <div className="has-text-right">{<DateStamp date={value} useDistanceFromToday={false} />}</div>,
    sortType: "datetime",
  },
  {
    Header: "Year",
    accessor: (item) => item.firstPublishedAtDate.getFullYear(),
    filter: "equals",
    disableGlobalFilter: true,
  },
];

const NewsTable: React.FC<{
  data: NewsItem[];
  pageSize: number;
  noNewsText?: string;
  withControls?: boolean;
}> = ({ data, pageSize, noNewsText, withControls = false }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    state: { pageIndex, filters, globalFilter },
    gotoPage,
    setFilter,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize,
        hiddenColumns: ["Year"],
        filters: withControls ? [{ id: "Year", value: new Date().getFullYear() }] : [],
      },
      getRowId: (origRow) => origRow.sys.id,
      globalFilter: includesAnyOfFullWordsFilter,
      autoResetExpanded: false,
      autoResetFilters: false,
      autoResetGlobalFilter: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const selectedYear = filters.find((f) => f.id === "Year")?.value ?? new Date().getFullYear();

  const [tabs, setTabs] = React.useState(getYearTabsWithCounts(data, globalFilter));

  React.useEffect(() => {
    if (withControls) {
      setTabs(getYearTabsWithCounts(data, globalFilter));
    }
  }, [withControls, data, globalFilter]);

  // If data changes (and tabs therefore changes) make sure we have a year tab selected with a year that is actually in the data we have
  const tabsContainSelectedYear = tabs.find((tab) => tab.id === selectedYear);
  React.useEffect(() => {
    if (withControls && !tabsContainSelectedYear) {
      setFilter("Year", tabs[0].id);
    }
  }, [withControls, tabs, tabsContainSelectedYear, setFilter]);

  return (
    <>
      {withControls && (
        <div className="is-flex is-justify-content-space-between is-align-items-flex-start is-flex-wrap-wrap pb-1">
          <Tabs tabs={tabs} selectedTabId={selectedYear} setSelectedTab={(year) => setFilter("Year", year)} />
          <SearchTextBox onSearchTextChanged={setGlobalFilter} />
        </div>
      )}

      {rows.length === 0 ? (
        noNewsText ? (
          <div className="content has-text-primary pt-5">
            <span className="is-size-6 has-text-weight-semibold">{noNewsText}</span>
          </div>
        ) : null
      ) : (
        <>
          <div
            className={css`
              overflow-x: auto;

              .table {
                thead {
                  background-color: transparent;

                  th {
                    padding-bottom: 0.25rem;
                  }
                }

                tbody {
                  tr {
                    &:focus,
                    &:active,
                    &:hover,
                    &:focus-within {
                      a {
                        color: var(--color-primary);
                      }
                    }

                    td {
                      padding-top: 0.5rem;
                      padding-bottom: 0.5rem;

                      .headline-column {
                        display: flex;
                        align-items: center;

                        img {
                          aspect-ratio: 16 / 9;
                        }

                        ${mq.mobile} {
                          flex-direction: column;
                          align-items: flex-start;

                          font-size: 0.85rem;

                          img {
                            margin-bottom: 0.5rem;
                          }
                        }
                      }
                    }
                  }
                }
              }
            `}
          >
            <table className="table is-fullwidth is-hoverable" {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps({ className: "has-background-grey-lightest" })}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps([column.getSortByToggleProps(), { className: "has-text-grey-dark" }])}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            <Link to={routeToNewsItem(row.original.routingName, row.original.sys.id, row.original.archived)} className="is-inverted">
                              {cell.render("Cell")}
                            </Link>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {newsletterConfig.newsletterSubscribeUrl && (
              <div className="has-text-right">
                <a
                  href={newsletterConfig.newsletterSubscribeUrl}
                  className="is-inverted has-text-weight-bold icon-text is-size-7"
                  target="_blank"
                  rel="noreferrer"
                >
                  Modtag nyhedsbrev
                  <span className="icon is-small ml-2">
                    <OpenInNewIcon style={{ paddingTop: "0.375rem" }} />
                  </span>
                </a>
              </div>
            )}
          </div>

          <Pagination pages={Math.ceil(rows.length / pageSize)} page={pageIndex + 1} siblingCount={1} setPage={(p) => gotoPage(p - 1)} />
        </>
      )}
    </>
  );
};

function getYearTabsWithCounts(data: NewsItem[], globalTextSearch?: string) {
  const searchWords = splitToNormalizedWords(globalTextSearch ?? "");
  const textFilteredItems = searchWords.length === 0 ? data : data.filter((item) => includesAnyOfFullWords(item, searchWords));

  const yearBadgeCountsMap = textFilteredItems.reduce(
    (acc, cur) =>
      map(cur.firstPublishedAtDate.getFullYear(), (curYear) => {
        acc.set(curYear, (acc.get(curYear) ?? 0) + 1);
        return acc;
      }),
    new Map<number, number>()
  );

  const [minYear, maxYear] =
    data.length === 0
      ? [new Date().getFullYear(), new Date().getFullYear()]
      : data.reduce(
          ([min, max], cur) => map(cur.firstPublishedAtDate.getFullYear(), (curYear) => [Math.min(min, curYear), Math.max(max, curYear)]),
          [Number.MAX_SAFE_INTEGER, Number.MIN_SAFE_INTEGER]
        );

  return Array.from({ length: maxYear - minYear + 1 }, (_, i) => maxYear - i).map<TabItem<number>>((year) => {
    return { id: year, label: `${year}`, badgeContent: yearBadgeCountsMap.get(year) ?? 0 };
  });
}

function includesAnyOfFullWordsFilter(rows: Row<NewsItem>[], ids: IdType<NewsItem>[], filterValue: string): Array<Row<NewsItem>> {
  const normFilterValues = splitToNormalizedWords(filterValue);
  return normFilterValues.length === 0 ? rows : rows.filter((row) => includesAnyOfFullWords(row.original, normFilterValues));
}

export default NewsTable;
