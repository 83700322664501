import { contentfulConfig } from "./configuration";
import { ClientError, gql, request } from "graphql-request";
import { FetcherFnReturnType, useSWRCurried } from "./fetching";
import { useMobileServiceProvider } from "./mobileServiceProviders";
import { isAfter, isBefore, parseISO } from "date-fns";
import { NormalizedSearchWords, normalizeString, toRoutingName } from "./misc";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";

const messageDtoFragment = gql`
  fragment MessageDto on Message {
    sys {
      id
    }
    status
    title
    details {
      json
    }
    from
    to
  }
`;

const broadcastMessagesFetcher = (_: string, includeHistorical: boolean) => {
  const now = new Date().toISOString();

  const whereClause: MessageFilter = includeHistorical
    ? { recepientsCollection_exists: false }
    : { recepientsCollection_exists: false, from_lte: now, OR: [{ to_exists: false }, { to_gt: now }] };

  return request<DeepArrayUnmaybefy<BroadcastMessagesQuery>>(
    contentfulConfig.graphqlEndpoint,
    gql`
      query BroadcastMessages($where: MessageFilter) {
        messages: messageCollection(locale: "da-DK", where: $where, order: [from_DESC]) {
          total
          items {
            ...MessageDto
          }
        }
      }
      ${messageDtoFragment}
    `,
    {
      where: whereClause,
    }
  );
};

type MessageDto = NonNullable<FetcherFnReturnType<typeof broadcastMessagesFetcher>["messages"]>["items"][number];

export type Message = Omit<MessageDto, "status" | "details"> & {
  status?: StatusHue;
  details?: ContentfulDocument;
  routingName: string;
  fromDate: Date;
  toDate: Date | null;
  msgType: "direct" | "broadcast";
};

function toMessage(msgType: "direct" | "broadcast") {
  return <T extends MessageDto>(dto: T): Message => {
    return {
      ...dto,
      status: dto.status ? (dto.status as StatusHue) : undefined,
      details: dto.details?.json as ContentfulDocument,
      routingName: toRoutingName(dto.title),
      fromDate: parseISO(dto.from!),
      toDate: dto.to ? parseISO(dto.to) : null,
      msgType,
    };
  };
}

export function includesAnyOfFullWords(msg: Message, searchWords: NormalizedSearchWords): boolean {
  return searchWords.length === 0
    ? true
    : searchWords.some(
        (searchWords) =>
          normalizeString(msg.title ?? "").includes(searchWords) ||
          normalizeString(msg.details ? documentToPlainTextString(msg.details) : "").includes(searchWords)
      );
}

export function useBroadcastMessages(includeHistorical: boolean = false): {
  broadcastMessages: Message[] | undefined;
  broadcastMessagesFetchError: ClientError | undefined;
  isLoadingBroadcastMessages: boolean;
} {
  const { data, error } = useSWRCurried<ClientError>()(["contentful/broadcast-messages", includeHistorical], broadcastMessagesFetcher);

  const msgs = data?.messages?.items.map(toMessage("broadcast"));

  return {
    broadcastMessages: msgs,
    broadcastMessagesFetchError: error,
    isLoadingBroadcastMessages: !data && !error,
  };
}

const directMessagesFetcher = (_: string, mobileServiceProviderId: string) => {
  return request<DeepArrayUnmaybefy<DirectMessagesQuery>>(
    contentfulConfig.graphqlEndpoint,
    gql`
      query DirectMessages($mobileServiceProviderId: String!) {
        mobileServiceProvider(id: $mobileServiceProviderId) {
          linkedFrom {
            messageCollection(locale: "da-DK") {
              total
              items {
                ...MessageDto
              }
            }
          }
        }
      }
      ${messageDtoFragment}
    `,
    {
      mobileServiceProviderId,
    }
  );
};

export function useDirectMessages(includeHistorical: boolean = false): {
  directMessages: Message[] | undefined;
  directMessagesFetchError: ClientError | undefined;
  isLoadingDirectMessages: boolean;
} {
  const { mobileServiceProvider } = useMobileServiceProvider();
  const { data, error } = useSWRCurried<ClientError>()(
    () => (!mobileServiceProvider ? null : ["contentful/direct-messages", mobileServiceProvider.sys.id]),
    directMessagesFetcher
  );

  const now = new Date();
  const msgs = data?.mobileServiceProvider?.linkedFrom?.messageCollection?.items
    .map(toMessage("direct"))
    .filter((msg) => (includeHistorical ? true : isBefore(msg.fromDate, now) && (msg.toDate === null || isAfter(msg.toDate, now))))
    .sort((a, b) => b.fromDate.valueOf() - a.fromDate.valueOf());

  return {
    directMessages: msgs,
    directMessagesFetchError: error,
    isLoadingDirectMessages: !data && !error,
  };
}

export function useMessageHistory() {
  const { broadcastMessages, broadcastMessagesFetchError, isLoadingBroadcastMessages } = useBroadcastMessages(true);
  const { directMessages, directMessagesFetchError, isLoadingDirectMessages } = useDirectMessages(true);

  const all =
    !broadcastMessages && !directMessages
      ? undefined
      : [broadcastMessages, directMessages]
          .filter((arr): arr is Message[] => !!arr)
          .flat()
          .sort((a, b) => b.fromDate.valueOf() - a.fromDate.valueOf());

  return {
    messageHistory: !all
      ? undefined
      : {
          all: {
            messages: all,
            findById: (msgId: string) => all.find((msg) => msg.sys.id === msgId),
            findByRoutingName: (routingName: string) => all.find((msg) => msg.routingName === routingName.toLowerCase()),
            findTitleByRoutingName: (routingName: string) => all.find((msg) => msg.routingName === routingName.toLowerCase())?.title,
          },
          broadcast: broadcastMessages,
          direct: directMessages,
        },
    messageHistoryFetchError:
      broadcastMessagesFetchError || directMessagesFetchError ? { broadcastMessagesFetchError, directMessagesFetchError } : undefined,
    isLoadingMessageHistory: isLoadingBroadcastMessages || isLoadingDirectMessages,
  };
}
