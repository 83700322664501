import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useNppContext } from "../../store/nppContext";
import { css } from "@emotion/css";
import { mq } from "../../styles/styleUtils";
import { Routes } from "../../store/routing";
import { useAuth } from "oidc-react";
import { signInAndTryRemainOnCurrentPathArgs, useIsAuthenticated } from "../../store/authentication";

import AsideMenusIcon from "mdi-react/HamburgerMenuBackIcon";
import KebabMenuIcon from "mdi-react/DotsVerticalIcon";
import LoginIcon from "mdi-react/LoginIcon";
import LogoutIcon from "mdi-react/LogoutIcon";

const kebabSize = "1.625rem";

const cssTopMenu = css`
  a.navbar-item,
  .navbar-link {
    &.no-outline {
      outline: none;
    }

    .main-navbar-link-text {
      padding-top: 0.5rem;
      border-bottom: 3px solid transparent;
    }

    &.is-active {
      .main-navbar-link-text {
        border-bottom-color: var(--color-primary);
      }
    }

    &:not(.is-active) {
      &:focus,
      &:focus-within,
      &:hover {
        .main-navbar-link-text {
          border-bottom-color: var(--color-primary-light);
        }
      }
    }
  }

  .navbar-brand {
    align-items: center;

    img {
      max-height: 2.125rem;
      margin: 0.5rem;
    }

    .logo span {
      line-height: 1.25em;
      padding-left: 0.25em;
      padding-top: 0.3em;
    }

    .menu-toggler {
      background: white;
      border: 0;

      display: none;

      .icon {
        padding-top: 1rem;
        transform: rotateY(180deg);
        transition: transform 300ms;
        &.is-active {
          transform: rotateY(0deg);
        }
      }
    }

    .navbar-kebab {
      --kebab-size: ${kebabSize};

      position: relative;

      width: var(--kebab-size);
      height: var(--kebab-size);

      margin-top: 0.25rem;
      margin-right: 0.75rem;

      cursor: pointer;

      transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);

      ${mq.desktop} {
        display: none;
      }

      > div {
        position: absolute;
        top: 0;

        width: var(--kebab-size);
        height: var(--kebab-size);

        transform: rotate(90deg);

        opacity: 0;
      }

      &.is-active {
        transform: rotate(45deg);

        > div {
          opacity: 1;
        }
      }
    }

    ${mq.touch} {
      justify-content: space-between;

      .menu-toggler {
        display: block;
      }
    }
  }

  .navbar-end .icon {
    margin-top: -0.25rem;
  }

  .navbar-menu.is-active {
    ${mq.touch} {
      position: absolute;
      width: 100%;
      background-color: var(--color-white);
    }
  }
`;

const TopMenu: React.FC = () => {
  const auth = useAuth();
  const isAuthenticated = useIsAuthenticated();
  const { state, dispatch } = useNppContext();

  function logInOrOut(e: React.SyntheticEvent) {
    e.preventDefault();
    if (isAuthenticated) {
      auth.signOutRedirect({
        useReplaceToNavigate: true,
        extraQueryParams: {
          TargetResource: `${window.location.protocol}//${window.location.host}` + Routes.AuthLoggedOut.create({}),
        },
      });
    } else {
      auth.signIn(signInAndTryRemainOnCurrentPathArgs());
    }
  }

  return (
    <header className="has-background-white">
      <nav className={"navbar has-shadow " + cssTopMenu}>
        <div className="navbar-brand">
          {isAuthenticated && (
            <div className="menu-toggler navbar-item">
              <button className="menu-toggler is-clickable" onClick={() => dispatch({ type: "ToggleMenus" })}>
                <span className={"icon" + (state.areMenusActive ? " is-active" : "")}>
                  <AsideMenusIcon />
                </span>
              </button>
            </div>
          )}
          <Link to="/" className="navbar-item logo">
            <img src="./npp-nuuday-curl.png" alt="NPP - Nuuday Partner Portal" />
            <span>
              nuuday
              <br />
              partner portal
            </span>
          </Link>
          <div
            className={"navbar-kebab" + (state.isTopMenuMenuActive ? " is-active" : "")}
            onClick={() => dispatch({ type: "ToggleTopMenuActive" })}
            role="button"
          >
            <KebabMenuIcon size={kebabSize} />
            <div>
              <KebabMenuIcon size={kebabSize} />
            </div>
          </div>
        </div>
        <div className={"navbar-menu" + (state.isTopMenuMenuActive ? " is-active" : "")}>
          <div className="navbar-end">
            <NavLink activeClassName="is-active" to={Routes.OperationsInformation.template()} className="navbar-item has-text-right no-outline">
              <span className="main-navbar-link-text">Driftsinformation</span>
            </NavLink>
            <NavLink activeClassName="is-active" to={Routes.OperationsCoverageMap.template()} className="navbar-item has-text-right no-outline">
              <span className="main-navbar-link-text">Dækningskort</span>
            </NavLink>
            <NavLink activeClassName="is-active" to={Routes.Contact.template()} className="navbar-item has-text-right no-outline">
              <span className="main-navbar-link-text">Kontakt</span>
            </NavLink>
            <a className="navbar-item has-text-right is-relative" href={isAuthenticated ? "/" : window.location.href} onClick={logInOrOut}>
              <span className="main-navbar-link-text pr-5">
                {isAuthenticated ? "Log ud" : "Log ind"}
                <span className="icon" style={{ position: "absolute", right: "0.75rem", top: "calc(50% - 8px)" }}>
                  {isAuthenticated ? <LogoutIcon size="20px" /> : <LoginIcon size="20px" />}
                </span>
              </span>
            </a>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default TopMenu;
