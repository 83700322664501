import ViewDashboardIcon from "mdi-react/ViewDashboardIcon";
import CubeOutlineIcon from "mdi-react/CubeOutlineIcon";
import HammerScrewdriverIcon from "mdi-react/HammerScrewdriverIcon";
import BookshelfIcon from "mdi-react/BookshelfIcon";
import LibraryIcon from "mdi-react/LibraryIcon";
import CogIcon from "mdi-react/CogIcon";
import CalendarEditIcon from "mdi-react/CalendarEditIcon";

export const DashboardAreaIcon = ViewDashboardIcon;
export const ProductsAreaIcon = CubeOutlineIcon;
export const ApplicationsAreaIcon = HammerScrewdriverIcon;
export const LibraryAreaIcon = BookshelfIcon;
export const NewsAreaIcon = LibraryIcon;
export const ProfileAreaIcon = CogIcon;

export const ReleaseCalendarIcon = CalendarEditIcon;
