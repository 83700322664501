import React from "react";
import ReactHslPlayer from "react-hls-player";
import { css } from "@emotion/css";

const Video: React.FC<{ playbackId: string }> = ({ playbackId }) => {
  const playerRef = React.useRef<HTMLVideoElement>(null);

  return (
    <div>
      <ReactHslPlayer
        className={css`
          display: block;
        `}
        src={`https://stream.mux.com/${playbackId}.m3u8`}
        playerRef={playerRef}
        autoPlay={false}
        controls={true}
        width="100%"
        height="auto"
      />
    </div>
  );
};

export default Video;
