import React from "react";
import { useTable, useSortBy, usePagination, Column, SortByFn, useFilters, useExpanded, useGlobalFilter, Row, IdType } from "react-table";
import { css } from "@emotion/css";
import { FasDataItem, FasDataItemPlanningStatus, includesAnyOfFullWords, useGetFasData, wellKnownGeography } from "../store/operations";
import DateStamp from "./shared/DateStamp";
import Loader from "./shared/Loader";
import { createHeaderRenderer } from "./shared/TableHeaderRenderer";
import Pagination from "./shared/Pagination";
import LoadingProblemBanner from "./shared/LoadingProblemBanner";
import { Link, useLocation } from "react-router-dom";
import OperationInformationItemDetails from "./OperationInformationItemDetails";
import { splitToNormalizedWords } from "../store/misc";

import OpenInNewIcon from "mdi-react/OpenInNewIcon";

const caseInsesitiveDaStringSort: SortByFn<FasDataItem> = (rowA, rowB, colId) =>
  rowA.values[colId].localeCompare(rowB.values[colId], "da", { sensitivity: "base" });

const columns: Column<FasDataItem>[] = [
  {
    Header: createHeaderRenderer("Emne"),
    accessor: "title",
    sortType: caseInsesitiveDaStringSort,
  },
  {
    Header: createHeaderRenderer("Område", "is-flex is-justify-content-center"),
    accessor: "geography",
    Cell: ({ value }) => <div className="has-text-centered">{value === "Flere danske områder" ? "Flere områder" : value}</div>,
  },
  {
    id: "date",
    Header: createHeaderRenderer("Dato", "is-flex is-justify-content-flex-end"),
    accessor: (item) => item["plannedStart"] || item["occured"],
    Cell: ({ value }: { value?: string | null }) => (
      <div className="has-text-right">{value && <DateStamp date={value} useDistanceFromToday={false} />}</div>
    ),
    sortDescFirst: true,
    disableGlobalFilter: true,
  },
  {
    Header: "Funktion",
    accessor: "function",
    disableGlobalFilter: true,
  },
  {
    Header: "planningStatus",
    accessor: "planningStatus",
    disableSortBy: true,
    filter: "equals",
    disableGlobalFilter: true,
  },
  {
    accessor: "geographyFlag",
    filter: (rows: Row<FasDataItem>[], ids: IdType<FasDataItem>[], filterValue: number) =>
      rows.filter((r) => wellKnownGeography.isFlagged(filterValue, r.original.geographyFlag) === true),
  },
  {
    Header: createHeaderRenderer("Læs", "is-flex is-justify-content-center"),
    accessor: "fasId",
    disableSortBy: true,
    Cell: ({ value, pathname }) => (
      <Link
        to={`${pathname}/${value}`}
        className={
          "is-flex is-justify-content-center is-primary is-inverted " +
          css`
            margin-top: 1px;
          `
        }
      >
        <OpenInNewIcon size="1rem" />
      </Link>
    ),
  },
];

const OperationsInformationTable: React.FC<{
  selectedGeoFilters: number;
  selectedPlanningStatus: FasDataItemPlanningStatus;
  globalTextSearch: string;
}> = ({ selectedGeoFilters, selectedPlanningStatus, globalTextSearch }) => {
  const { fasData, fasDataFetchError, isLoadingFasData } = useGetFasData();

  return (
    <Loader isLoading={isLoadingFasData}>
      <LoadingProblemBanner show={Boolean(!!!fasData && fasDataFetchError)}>
        Der er i øjeblikket problemer med at hente information om netværk og services
      </LoadingProblemBanner>
      <div>
        {fasData && (
          <RenderTable
            columns={columns}
            data={fasData.all}
            selectedGeoFilters={selectedGeoFilters}
            selectedPlanningStatus={selectedPlanningStatus}
            globalTextSearch={globalTextSearch}
          />
        )}
      </div>
    </Loader>
  );
};

function includesAnyOfFullWordsFilter(rows: Row<FasDataItem>[], ids: IdType<FasDataItem>[], filterValue: string): Array<Row<FasDataItem>> {
  const normFilterValues = splitToNormalizedWords(filterValue);
  return normFilterValues.length === 0 ? rows : rows.filter((row) => includesAnyOfFullWords(row.original, normFilterValues));
}

function RenderTable({
  columns,
  data,
  selectedGeoFilters,
  selectedPlanningStatus,
  globalTextSearch,
}: {
  columns: Column<FasDataItem>[];
  data: FasDataItem[];
  selectedGeoFilters: number;
  selectedPlanningStatus: FasDataItemPlanningStatus;
  globalTextSearch: string;
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    state: { pageSize, pageIndex },
    gotoPage,
    setFilter,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: ["function", "planningStatus", "geographyFlag"],
      },
      getRowId: (origRow) => origRow.fasId,
      globalFilter: includesAnyOfFullWordsFilter,
      autoResetExpanded: false,
      autoResetFilters: false,
      autoResetGlobalFilter: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  );

  const { pathname } = useLocation();

  React.useEffect(() => {
    setFilter("geographyFlag", selectedGeoFilters);
    setFilter("planningStatus", selectedPlanningStatus);
  }, [setFilter, selectedGeoFilters, selectedPlanningStatus]);
  React.useEffect(() => {
    setGlobalFilter(globalTextSearch);
  }, [setGlobalFilter, globalTextSearch]);

  return rows.length === 0 ? (
    <div className="content pt-5 has-text-primary">
      <span className="is-size-6 has-text-weight-semibold">Der er pt. ingen meddelelser</span>
      <br />
      <span className="is-size-7">Vend evt. tilbage senere</span>
    </div>
  ) : (
    <>
      <div
        className={css`
          overflow-y: hidden;
          overflow-x: auto;

          tr {
            td a {
              color: var(--color-grey);
            }

            &.expanded {
              td {
                border-bottom-color: transparent;
                color: var(--color-primary);
                font-weight: bold;

                a {
                  color: var(--color-primary);
                }
              }
            }

            &.hovering-details {
              background-color: var(--color-table-row-hover-background);
            }

            &:focus,
            &:active,
            &:hover,
            &:focus-within {
              + .details {
                background-color: var(--color-table-row-hover-background);
              }
            }
          }
        `}
      >
        <table className="table is-fullwidth is-hoverable is-clickable" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps([column.getSortByToggleProps(), { className: "has-text-grey-dark" }])}>{column.render("Header")}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              const rowProps = row.getRowProps({
                className: row.isExpanded ? "expanded" : "",
              });
              return (
                <React.Fragment key={rowProps.key}>
                  <tr {...rowProps} onClick={() => row.toggleRowExpanded(!!!row.isExpanded)}>
                    {row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>{cell.render("Cell", { pathname })}</td>
                    ))}
                  </tr>
                  {row.isExpanded ? (
                    <tr
                      className="details"
                      onClick={() => row.toggleRowExpanded(!!!row.isExpanded)}
                      onMouseEnter={addHoveringDetails}
                      onMouseLeave={removeHoveringDetails}
                    >
                      <td className="pt-0 pb-4 pl-5" colSpan={row.cells.length}>
                        <OperationInformationItemDetails item={row.original}></OperationInformationItemDetails>
                      </td>
                    </tr>
                  ) : null}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>

      <Pagination pages={Math.ceil(rows.length / pageSize)} page={pageIndex + 1} siblingCount={1} setPage={(p) => gotoPage(p - 1)} />
    </>
  );
}

function addHoveringDetails(e: React.MouseEvent) {
  e.currentTarget.previousElementSibling?.classList.add("hovering-details");
}

function removeHoveringDetails(e: React.MouseEvent) {
  e.currentTarget.previousElementSibling?.classList.remove("hovering-details");
}

export default OperationsInformationTable;
