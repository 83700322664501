import React from "react";
import { Redirect, useParams } from "react-router-dom";
import { RouteParams, Routes } from "../store/routing";
import { useVideoItem } from "../store/videos";
import Breadcrumb from "./shared/Breadcrumb";
import Page from "./shared/Page";
import PageHeader from "./shared/PageHeader";
import Video from "./shared/Video";

const Videos: React.FC = () => {
  const { videoId, videoRoutingName } = useParams<RouteParams<"VideosVideoIdRouteName">>();
  const { videoItem } = useVideoItem(videoId);

  // Fix URL if the video item routing name doesn't match
  if (videoItem && videoRoutingName !== videoItem.routingName) {
    return <Redirect to={Routes.VideosVideoIdRouteName.create({ videoId: videoItem.sys.id, videoRoutingName: videoItem.routingName })} />;
  }

  return (
    <Page>
      <section className="section">
        <div className="content-container">
          <Breadcrumb
            mapUrlSegmentToName={(segment) => {
              return segment === videoId // Don't show video ID in breadcrumb
                ? false
                : segment === videoRoutingName // Show actual video title not the video routing name in breadcrumb
                ? videoItem?.title
                : "";
            }}
          />

          <PageHeader title={videoItem?.title ?? "..."} />

          {videoItem && videoItem.video?.playbackId && <Video playbackId={videoItem.video.playbackId} />}
        </div>
      </section>
    </Page>
  );
};

export default Videos;
