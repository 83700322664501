// Skews and sass mixins taken from Bulma and JavaScript'ified for use in @emotion/css

// The container horizontal gap, which acts as the offset for breakpoints
const gap = 32; // 32px !default

// 960, 1152, and 1344 have been chosen because they are divisible by both 12 and 16
const tablet = 769; // 769px !default

// 960px container + 4rem
const desktop = 960 + 2 * gap; // 960px + (2 * $gap) !default

// 1152px container + 4rem
const widescreen = 1152 + 2 * gap; // 1152px + (2 * $gap) !default
const widescreenEnabled = true; // $widescreen-enabled: true !default

const fullhd = 1344 + 2 * gap; // 1344px + (2 * $gap) !default
const fullhdEnabled = true; // $fullhd-enabled: true !default

const mediaQuery = (...args: string[]) => `@media screen ${args.join(" ")}`;
const from = (widthInPx: number) => `and (min-width: ${widthInPx}px)`;
const until = (widthInPx: number) => `and (max-width: ${widthInPx - 1}px)`;

const cheapDummyCssSelector = "#no-such-id-" + new Date().getMilliseconds();
const ifEnabled = (enabled: boolean, mediaQueryString: string) => (enabled ? mediaQueryString : cheapDummyCssSelector);

export const mq = {
  from: (widthInPx: number) => mediaQuery(from(widthInPx)),

  until: (widthInPx: number) => mediaQuery(until(widthInPx)),

  mobile: mediaQuery(until(tablet)),

  tablet: mediaQuery(from(tablet)) + ", print",

  tabletOnly: mediaQuery(from(tablet), until(desktop)),

  touch: mediaQuery(until(desktop)),

  desktop: mediaQuery(from(desktop)),

  desktopOnly: mediaQuery(from(desktop), until(widescreen)),

  untilWidescreen: ifEnabled(widescreenEnabled, mediaQuery(until(widescreen))),

  widescreen: ifEnabled(widescreenEnabled, mediaQuery(from(widescreen))),

  widescreenOnly: ifEnabled(widescreenEnabled, mediaQuery(from(widescreen), until(fullhd))),

  untilFullhd: ifEnabled(fullhdEnabled, mediaQuery(until(fullhd))),

  fullhd: ifEnabled(fullhdEnabled, mediaQuery(from(fullhd))),
};
