import React from "react";
import Loader from "./shared/Loader";
import LoadingProblemBanner from "./shared/LoadingProblemBanner";
import { useMessageHistory } from "../store/messages";
import { css } from "@emotion/css";
import { Link, Redirect } from "react-router-dom";
import { Routes } from "../store/routing";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Breadcrumb from "./shared/Breadcrumb";
import PageHeader from "./shared/PageHeader";
import { CreateRouteToMessage } from "./DashboardMessages";
import { Message } from "../store/messages";

import ChevronLeftIcon from "mdi-react/ChevronLeftIcon";

const DashboardMessagesMessage: React.FC<{
  params: { msgId: string; msgRoutingName: string };
  createRouteToMessage: CreateRouteToMessage;
  doForcedRedirect?: (message: Message) => boolean;
  backUrl?: string;
}> = ({ params, createRouteToMessage, doForcedRedirect, backUrl }) => {
  const { messageHistory, messageHistoryFetchError, isLoadingMessageHistory } = useMessageHistory();
  const selectedMessage = params.msgId ? messageHistory?.all.findById(params.msgId) : undefined;

  // Fix URL if the message routing name doesn't match the one from the URL or doForcedRedirect returns true
  if (selectedMessage && (params.msgRoutingName !== selectedMessage.routingName || doForcedRedirect?.(selectedMessage) === true)) {
    return <Redirect to={createRouteToMessage({ ...params, msgRoutingName: selectedMessage.routingName }, selectedMessage.msgType)} />;
  }

  return (
    <>
      <Breadcrumb
        mapUrlSegmentToName={(segment) =>
          segment === params.msgId && /^[a-z0-9]{22}/i.test(segment) ? false : messageHistory?.all.findTitleByRoutingName(segment)
        }
      />

      <PageHeader title={selectedMessage?.title ?? "..."} />

      <Link className="is-size-7 is-primary is-inverted" to={backUrl ?? Routes.Dashboard.create({})}>
        <span className="icon-text">
          <span className="icon" style={{ marginTop: "-0.2em" }}>
            <ChevronLeftIcon size={"1.5em"} />
          </span>
          Tilbage
        </span>
      </Link>

      <Loader isLoading={isLoadingMessageHistory}>
        <LoadingProblemBanner show={Boolean(!!!messageHistory && messageHistoryFetchError)}>
          Der er i øjeblikket problemer med at hente beskeder
        </LoadingProblemBanner>

        <LoadingProblemBanner wrapperClassName="my-5" show={Boolean(!!!messageHistoryFetchError && !selectedMessage)}>
          Besked med ID: #{params.msgId} kan ikke fremsøges.
        </LoadingProblemBanner>

        {selectedMessage && (
          <div
            className={
              "mt-3 py-5 pl-5 " +
              css`
                border-top: 1px solid var(--color-grey-lighter);
                border-bottom: 1px solid var(--color-grey-lighter);

                > div {
                  max-inline-size: 55ch;
                }
              `
            }
          >
            {selectedMessage.details && <div className="content">{documentToReactComponents(selectedMessage.details)}</div>}
          </div>
        )}
      </Loader>
    </>
  );
};

export default DashboardMessagesMessage;
