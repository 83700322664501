import { contentfulConfig } from "./configuration";
import { ClientError, gql, request } from "graphql-request";
import { useSWRCurried } from "./fetching";
import { toRoutingName } from "./misc";

const libraryPagesFetcher = () => {
  return request<DeepArrayUnmaybefy<LibraryPagesQuery>>(
    contentfulConfig.graphqlEndpoint,
    gql`
      query LibraryPages {
        libraryPages: pageCollection(locale: "da-DK", where: { pageType: "Library" }, order: [order_ASC]) {
          total
          items {
            sys {
              id
            }
            routingName
            name
            title
            teaser
            description {
              json
            }
          }
        }
      }
    `
  );
};

export function useLibraryPages() {
  const { data, error } = useSWRCurried<ClientError>()("contentful/library-pages", libraryPagesFetcher);
  const all = data?.libraryPages?.items.map((p) => ({ ...p, routingName: p.routingName ?? toRoutingName(p.name) })) ?? [];

  return {
    libraryPages: data
      ? {
          all: all,
          findByRoutingName: (routingName: string) => all.find((p) => p.routingName === routingName.toLowerCase()),
          findNameByRoutingName: (routingName: string) => all.find((p) => p.routingName === routingName.toLowerCase())?.name ?? "",
        }
      : undefined,
    libraryPagesFetchError: error,
    isLoadingLibraryPages: !data && !error,
  };
}
