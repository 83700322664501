import React from "react";
import { includesAnyOfFullWords, Message } from "../store/messages";
import { useTable, useSortBy, usePagination, useExpanded, useFilters, useGlobalFilter, IdType, Row } from "react-table";
import { Link } from "react-router-dom";
import { CreateRouteToMessage } from "./DashboardMessages";
import { Column, SortByFn } from "react-table";
import { createHeaderRenderer } from "./shared/TableHeaderRenderer";
import DateStamp from "./shared/DateStamp";
import { css, cx } from "@emotion/css";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { mq } from "../styles/styleUtils";
import { splitToNormalizedWords } from "../store/misc";
import DashboardMessagesTableControls from "./DashboardMessagesTableControls";

import ChevronLeftIcon from "mdi-react/ChevronLeftIcon";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";

const caseInsesitiveDaStringSort: SortByFn<Message> = (rowA, rowB, colId) =>
  rowA.values[colId].localeCompare(rowB.values[colId], "da", { sensitivity: "base" });

const columns: Column<Message>[] = [
  {
    width: undefined,
    Header: createHeaderRenderer("Besked"),
    accessor: "title",
    sortType: caseInsesitiveDaStringSort,
    disableSortBy: true,
  },
  {
    width: 110,
    Header: createHeaderRenderer("Dato"),
    accessor: "fromDate",
    Cell: ({ value }) => <DateStamp date={value} useDistanceFromToday={false} />,
    sortType: "datetime",
    disableSortBy: true,
  },
  {
    accessor: "msgType",
    filter: "",
  },
];

const tableStyles = css`
  tbody tr {
    &.even,
    &.even + .details {
      background-color: var(--color-white);
    }

    &.expanded td {
      border-bottom-color: transparent;
    }

    &.details {
      font-size: 0.8125rem;

      td {
        padding-top: 0;
        padding-bottom: 1em;
      }

      div.content {
        max-inline-size: 55ch;
      }
    }

    &.hovering-details {
      background-color: var(--color-table-row-hover-background);
    }

    &:not(.filler) {
      cursor: pointer;

      &:focus,
      &:active,
      &:hover,
      &:focus-within {
        background-color: var(--color-table-row-hover-background);

        a {
          color: var(--color-primary);
        }

        + .details {
          background-color: var(--color-table-row-hover-background);
        }
      }

      &.details {
        &:focus,
        &:active,
        &:hover,
        &:focus-within {
          background-color: var(--color-table-row-hover-background);
        }
      }
    }
  }

  .p-container {
    padding: 0.15rem 0.2rem 0 0;

    .p-prv,
    .p-nxt {
      display: flex;
      align-items: center;
    }

    button {
      display: flex;
      align-items: center;

      padding: 0;

      background-color: transparent;
      border: 1px solid transparent;
      border-radius: 2px;

      color: var(--color-grey-dark);

      &:focus,
      &:active,
      &:hover,
      &:focus-within {
        border-color: var(--color-primary);
        color: var(--color-primary);
      }
    }

    .items-shown-indicator {
      padding-top: 0.15em;
      padding-right: 1em;

      span + span:before {
        content: "/";
        margin: 0.125rem;
      }

      ${mq.mobile} {
        padding-right: 0.5em;
      }
    }
  }
`;

const DashboardMessagesTable: React.FC<{ data: Message[]; pageSize: number; expandedMessage?: string; createRouteToMessage: CreateRouteToMessage }> =
  ({ data, pageSize, expandedMessage, createRouteToMessage }) => {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      page,
      state: { pageIndex },
      gotoPage,
      visibleColumns,
      setFilter,
      setGlobalFilter,
      toggleRowExpanded,
    } = useTable(
      {
        columns,
        data,
        initialState: { pageSize, hiddenColumns: ["msgType"] },
        getRowId: (origRow) => origRow.sys.id,
        globalFilter: includesAnyOfFullWordsFilter,
        autoResetExpanded: false,
        autoResetFilters: false,
        autoResetGlobalFilter: false,
        autoResetPage: false,
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      useExpanded,
      usePagination
    );

    const msgToExpandRef = React.useRef<string>();

    // if expandedMessage changes then we want to expand it and navigate to it
    React.useEffect(() => {
      msgToExpandRef.current = expandedMessage;
    }, [expandedMessage]);

    const expandedRowIdx = expandedMessage ? rows.findIndex((m) => m.id === expandedMessage) : -1;
    const expandedRowPageIndex = expandedRowIdx > -1 ? Math.floor(expandedRowIdx / pageSize) : undefined;

    React.useEffect(() => {
      // if there is a message ID in the msgToExpandRef ref
      if (expandedMessage && expandedMessage === msgToExpandRef.current) {
        // rest it to undefined so we don't keep navigating and expand to the same message
        msgToExpandRef.current = undefined;

        // expand the row
        toggleRowExpanded([expandedMessage], true);

        // and go to the page where the row sits
        if (expandedRowPageIndex && expandedRowPageIndex !== pageIndex) {
          gotoPage(expandedRowPageIndex);
        }
      }
    }, [expandedMessage, expandedRowIdx, expandedRowPageIndex, pageIndex, gotoPage, toggleRowExpanded]);

    // because we configure "autoResetPage: false" the pageIndex can exceed the current max page index
    // but wee need "autoResetPage: false" so the pageIndex isn't reset when we navigate back from having visited the message details page
    const maxPageIndex = rows.length === 0 ? 0 : Math.ceil(rows.length / pageSize) - 1;
    React.useEffect(() => {
      pageIndex > maxPageIndex && gotoPage(maxPageIndex);
    }, [pageIndex, maxPageIndex, gotoPage]);

    const emptyRows = Array.from({ length: pageSize - page.length }, () => null);

    return (
      <>
        <DashboardMessagesTableControls
          onShowOnlyDirectMessagesChanged={(showOnlyDirectMessages) => setFilter("msgType", showOnlyDirectMessages ? "direct" : undefined)}
          onSearchTextChanged={(globalTextSearch) => setGlobalFilter(globalTextSearch)}
        />

        <div className={"card is-radiusless " + tableStyles}>
          <table className="table is-fullwidth" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps([
                        column.getSortByToggleProps(),
                        { style: { width: column.width } },
                        { className: "has-text-grey-dark" },
                      ])}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, idx) => {
                prepareRow(row);
                const rowProps = row.getRowProps({ className: cx({ expanded: row.isExpanded, [idx % 2 ? "even" : "odd"]: true }) });
                return (
                  <React.Fragment key={rowProps.key}>
                    <tr {...rowProps}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            width={cell.column.width}
                            {...cell.getCellProps()}
                            onClick={(e) => e.currentTarget === e.target && row.toggleRowExpanded(!!!row.isExpanded)}
                          >
                            <Link
                              to={createRouteToMessage(
                                { msgId: row.original.sys.id, msgRoutingName: row.original.routingName },
                                row.original.msgType
                              )}
                              className="is-inverted"
                            >
                              {cell.render("Cell")}
                            </Link>
                          </td>
                        );
                      })}
                    </tr>
                    {row.isExpanded ? (
                      <tr
                        className="details"
                        onClick={() => row.toggleRowExpanded(!!!row.isExpanded)}
                        onMouseEnter={addHoveringDetails}
                        onMouseLeave={removeHoveringDetails}
                      >
                        <td colSpan={visibleColumns.length}>
                          {row.original.details && <div className="content">{documentToReactComponents(row.original.details)}</div>}
                        </td>
                      </tr>
                    ) : null}
                  </React.Fragment>
                );
              })}
              {emptyRows.map((_, idx) => (
                <tr key={idx} className={cx({ filler: true, [(idx + page.length) % 2 ? "even" : "odd"]: true })}>
                  <td colSpan={visibleColumns.length}>&nbsp;</td>
                </tr>
              ))}
            </tbody>

            <tfoot>
              <tr>
                <td colSpan={visibleColumns.length} className="p-1">
                  <nav className="p-container is-flex is-justify-content-flex-end" role="navigation" aria-label="pagination">
                    <div className="p-prv pr-1">
                      <div className="items-shown-indicator">
                        <span>
                          {pageIndex * pageSize + 1}-{Math.min((pageIndex + 1) * pageSize, rows.length)}
                        </span>
                        <span>{rows.length}</span>
                      </div>
                      <button onClick={() => gotoPage(pageIndex - 1)}>
                        <span className="icon">
                          <ChevronLeftIcon />
                        </span>
                      </button>
                    </div>
                    <div className="p-nxt pr-1">
                      <button onClick={() => gotoPage(pageIndex + 1)}>
                        <span className="icon">
                          <ChevronRightIcon />
                        </span>
                      </button>
                    </div>
                  </nav>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </>
    );
  };

function addHoveringDetails(e: React.MouseEvent) {
  e.currentTarget.previousElementSibling?.classList.add("hovering-details");
}

function removeHoveringDetails(e: React.MouseEvent) {
  e.currentTarget.previousElementSibling?.classList.remove("hovering-details");
}

function includesAnyOfFullWordsFilter(rows: Row<Message>[], ids: IdType<Message>[], filterValue: string): Array<Row<Message>> {
  const normFilterValues = splitToNormalizedWords(filterValue);
  return normFilterValues.length === 0 ? rows : rows.filter((row) => includesAnyOfFullWords(row.original, normFilterValues));
}

export default DashboardMessagesTable;
