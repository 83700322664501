import { buildEndpointUrl } from "./configuration";
import { fetchEnsureSuccess, NppFetchError, useSWRCurried } from "./fetching";
import { map, NormalizedSearchWords, normalizeString } from "./misc";
import * as zod from "zod";

export enum FasDataItemPlanningStatus {
  Unplanned,
  Planned,
  Completed,
}

export const wellKnownGeography = (() => {
  const values = ["Danmark", "Sjælland", "Jylland", "Fyn", "Bornholm", "Flere danske områder"] as const;
  const allValueFlags = Array.from(values.keys(), (k) => [k, 1 << k]);
  const allFlag = ~(~0 << values.length);

  type WellKnownGeographyValue = typeof values[number];

  const calcFlag = (geography: string): number => map(values.indexOf(geography as WellKnownGeographyValue), (idx) => (idx === -1 ? 0 : 1 << idx));

  const isFlagged = (flags: number, containsFlags: number): boolean | undefined => (flags & containsFlags) === containsFlags;

  const getFlaggedValues = (flags: number): WellKnownGeographyValue[] =>
    flags === 0 ? [] : allValueFlags.filter(([_, k]) => (flags & k) === k).map(([idx]) => values[idx]);

  const isValueFlagged = (flags: number, geography: WellKnownGeographyValue): boolean | undefined => isFlagged(flags, calcFlag(geography));

  const getFlaggedOrAllValues = (flags: number): WellKnownGeographyValue[] =>
    map(getFlaggedValues(flags), (vals) => (vals.length ? vals : [...values]));

  return {
    values,
    allFlag,
    calcFlag,
    isFlagged,
    isValueFlagged,
    getFlaggedOrAllValues,
  } as const;
})();

const fasDataItemDtoSchema = zod
  .object({
    category: zod.number(),
    function: zod.string(),
    title: zod.string(),
    messageType: zod.string(),
    level: zod.string(),
    hightestLevel: zod.string(),
    reason: zod.string(),
    consequence: zod.string().nullable(),
    geography: zod.string(),
    plannedStart: zod.string().nullable(),
    plannedEnd: zod.string().nullable(),
    realEnd: zod.string().nullable(),
    occured: zod.string().nullable(),
    nextStatus: zod.string().nullable(),
    estimateResolved: zod.string().nullable(),
    realResolved: zod.string().nullable(),
    updated: zod.string().nullable(),
    contactName: zod.string(),
    contactNumber: zod.string(),
    fasId: zod.string(),
    status: zod.string(),
    language: zod.string(),
  })
  .required();

type FasDataItemDto = zod.TypeOf<typeof fasDataItemDtoSchema>;

type FasDataItemBase = Omit<FasDataItemDto, "consequence"> & {
  consequence: string[];
  geographyFlag: number;
};

export type FasDataItem =
  | (FasDataItemBase & {
      planningStatus: FasDataItemPlanningStatus.Unplanned;
    })
  | (FasDataItemBase & {
      planningStatus: FasDataItemPlanningStatus.Planned;
    })
  | (FasDataItemBase & {
      planningStatus: FasDataItemPlanningStatus.Completed;
      wasPlanned: boolean;
    });

export type FasDataStatsEntry = {
  [S in FasDataItemPlanningStatus]: number;
} & {
  total: number;
};

export type FasDataStats = {
  readonly [K in typeof wellKnownGeography.values[number]]: FasDataStatsEntry;
};

export type FasData = {
  readonly all: FasDataItem[];
  readonly stats: FasDataStats;
};

export const createEmptyFasDataStatsEntry = (): FasDataStatsEntry => ({
  [FasDataItemPlanningStatus.Unplanned]: 0,
  [FasDataItemPlanningStatus.Planned]: 0,
  [FasDataItemPlanningStatus.Completed]: 0,
  total: 0,
});

export function createEmptyFasDataStats(): FasDataStats {
  return {
    Danmark: createEmptyFasDataStatsEntry(),
    Sjælland: createEmptyFasDataStatsEntry(),
    Jylland: createEmptyFasDataStatsEntry(),
    Fyn: createEmptyFasDataStatsEntry(),
    Bornholm: createEmptyFasDataStatsEntry(),
    "Flere danske områder": createEmptyFasDataStatsEntry(),
  };
}

function splitIntoSignificantLinesArray(str: string | null | undefined) {
  const arr = str?.split(/\r?\n/g).map((s) => s.trim()) || [];
  while (arr.length && !!!arr[arr.length - 1]) {
    arr.pop();
  }
  return arr;
}

export function includesAnyOfFullWords(item: FasDataItem, searchWords: NormalizedSearchWords): boolean {
  return searchWords.length === 0
    ? true
    : searchWords.some(
        (searchWords) =>
          normalizeString(item.reason).includes(searchWords) ||
          normalizeString(item.title).includes(searchWords) ||
          item.consequence.some((c) => normalizeString(c).includes(searchWords))
      );
}

export function calculateFasDataStats(items: readonly FasDataItem[]) {
  return items.reduce<FasDataStats & { [index: string]: FasDataStatsEntry | undefined }>((acc, cur) => {
    const statsEntry = acc[cur.geography] ?? createEmptyFasDataStatsEntry();
    statsEntry[cur.planningStatus] += 1;
    statsEntry.total += 1;
    return {
      ...acc,
      [cur.geography]: statsEntry,
    };
  }, createEmptyFasDataStats());
}

const getFasDataFetcher = (url: string): Promise<FasData> =>
  fetchEnsureSuccess(url)
    .then((r) => r.json())
    .then((payload) => zod.array(fasDataItemDtoSchema).parse(payload)) //  Validate the retrieved payload so we can be confident in our typings
    .then((dtos) => {
      const all: FasDataItem[] = dtos
        .filter((dto) => ["Mobil Telefoni", "Mobil Tjenester", "Telefoni"].indexOf(dto.function.trim()) > -1)
        .map((dto) => {
          const item = {
            ...dto,
            title: dto.title.trim(),
            consequence: splitIntoSignificantLinesArray(dto.consequence),
            geography: dto.geography,
            geographyFlag: wellKnownGeography.calcFlag(dto.geography),
          };

          const planningStatus = dto.realResolved
            ? FasDataItemPlanningStatus.Completed
            : dto.plannedStart
            ? FasDataItemPlanningStatus.Planned
            : FasDataItemPlanningStatus.Unplanned;

          return planningStatus !== FasDataItemPlanningStatus.Completed
            ? { ...item, planningStatus }
            : {
                ...item,
                planningStatus,
                wasPlanned: !!dto.plannedStart,
              };
        });

      return {
        all,
        stats: calculateFasDataStats(all),
      };
    });
//.then(makeFlaky(10000, 10000, 0));

export function useGetFasData() {
  const { data, error } = useSWRCurried<NppFetchError>()(() => buildEndpointUrl("operationsHost", "/api/getfasdata"), getFasDataFetcher);

  return {
    fasData: data,
    fasDataFetchError: error,
    isLoadingFasData: !data && !error,
  };
}
