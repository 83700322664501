import React from "react";
import { css } from "@emotion/css";
import Page from "./shared/Page";
import { useIsAuthenticated } from "../store/authentication";
import PageHeader from "./shared/PageHeader";
import Breadcrumb from "./shared/Breadcrumb";
import { Routes } from "../store/routing";
import { Link } from "react-router-dom";
import { useContactContent } from "../store/contact";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import { ReactComponent as ContactEmailIcon } from "../styles/icon-contact-email.svg";
import { ReactComponent as CustomerServiceIcon } from "../styles/icon-customer-service.svg";
import { ApplicationsAreaIcon, DashboardAreaIcon, LibraryAreaIcon, NewsAreaIcon, ProductsAreaIcon, ReleaseCalendarIcon } from "./shared/NppIcons";

const iconSize = 55;

const choices = [
  [
    {
      route: Routes.Dashboard.create({}),
      icon: <DashboardAreaIcon />,
      label: "Kontrolpanel",
    },
    {
      route: Routes.Library.create({}),
      icon: <LibraryAreaIcon />,
      label: "Bibliotek",
    },
  ],
  [
    {
      route: Routes.Products.create({}),
      icon: <ProductsAreaIcon />,
      label: "Produkter",
    },
    {
      route: Routes.News.create({}),
      icon: <NewsAreaIcon />,
      label: "Læs nyheder",
    },
  ],
  [
    {
      route: Routes.LibraryReleaseCalendar.create({}),
      icon: <ReleaseCalendarIcon />,
      label: "Releasekalender",
    },
    {
      route: Routes.AppsErrorReports.create({}),
      icon: <ApplicationsAreaIcon />,
      label: "Opret fejlmelding",
    },
  ],
];

const Contact: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const { contactContent } = useContactContent();

  return (
    <Page whitePage={true}>
      <section className="section">
        <div className="container is-max-widescreen">
          <Breadcrumb />

          <div className="columns pt-5 pb-5">
            <div className="column is-half is-flex is-flex-direction-column is-justify-content-center">
              <PageHeader
                title={contactContent?.pageHeaderTitle ?? ""}
                subtitle={contactContent?.pageHeaderSubtitle ?? ""}
                className="pt-4 has-text-centered"
                padding={false}
              />
            </div>
            <div className="column is-flex is-flex-direction-column is-justify-content-center is-align-items-center has-text-centered">
              <a href={`mailto:${contactContent?.contactEmail}`}>
                <ContactEmailIcon height={iconSize} width={iconSize} />
                <p className="is-size-5 is-size-4-desktop has-text-weight-bold white-space-nowrap">Skriv til os</p>
                <p className="is-size-8">Vi svarer så hurtigt som muligt</p>
              </a>
            </div>
            <div className="column is-flex is-flex-direction-column is-justify-content-center is-align-items-center has-text-centered">
              <a href={`tel:${contactContent?.contactPhone}`}>
                <CustomerServiceIcon height={iconSize} width={iconSize} />
                <p className="is-size-5 is-size-4-desktop has-text-weight-bold white-space-nowrap">{contactContent?.contactPhone}</p>
                <div className="is-size-8 white-space-pre-line">
                  {contactContent?.openingHours?.json && documentToReactComponents(contactContent.openingHours.json)}
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section
        className={
          "section " +
          css`
            background-color: var(--color-scheme-main);

            .column {
              .box {
                &:not(:last-child) {
                  margin-bottom: 1.25rem;
                }

                .lbl {
                  padding-top: 0.3125rem;
                }
              }
            }
          `
        }
      >
        <div className="container is-max-widescreen">
          <div className="pb-5">Nuuday Partner Portal valgmuligheder</div>
          <div className="columns pb-4">
            {choices.map((items, idx) => (
              <div className="column" key={idx}>
                {items.map(({ route, icon, label }, idx) => (
                  <Link to={route} key={idx} className="box is-shadowless is-flex is-align-items-center is-inverted p-4">
                    <span className="icon has-text-primary">{icon}</span>
                    <span className="pl-2 lbl">{label}</span>
                  </Link>
                ))}
              </div>
            ))}
          </div>
        </div>
      </section>

      {isAuthenticated && (
        <section className="section">
          <div className="container is-max-widescreen">
            <div>
              <p>
                <a href={`tel:${contactContent?.contactPhoneOutsideOfficeHours}`} className="has-text-weight-bold is-inverted">
                  Ved kritiske fejl udenfor arbejdstid ring: {contactContent?.contactPhoneOutsideOfficeHours}
                </a>
              </p>
            </div>
          </div>
        </section>
      )}
    </Page>
  );
};

export default Contact;
