import React from "react";
import { NavLink } from "react-router-dom";
import { isAuthenticated } from "../../store/authentication";
import { css } from "@emotion/css";
import { useAuth } from "oidc-react";
import { Routes } from "../../store/routing";
import { useProducts } from "../../store/products";
import { MdiReactIconComponentType } from "mdi-react";

import { ApplicationsAreaIcon, DashboardAreaIcon, LibraryAreaIcon, NewsAreaIcon, ProductsAreaIcon, ProfileAreaIcon } from "./NppIcons";

const subMenuSurfaceStyles = css`
  background-color: var(--color-primary);
  height: 100%;
  width: 4.75rem;
  position: absolute;
`;

const subMenuStyles =
  "is-size-6 has-background-primary is-flex is-flex-direction-column is-align-items-center " +
  css`
    position: sticky;
    top: 0;

    z-index: 1;

    padding-top: 0.925rem;

    a {
      margin-bottom: 0.4625rem;
      padding: 0.925rem;
      line-height: 0;
      border-radius: 5px;
      outline: none;

      position: relative;

      &.is-active {
        background-color: var(--color-primary-dark);
      }

      &:after {
        content: " ";
        border-radius: 5px;
        opacity: 0.5;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -1;

        transition: background-color 250ms;
      }

      .tool-tip {
        pointer-events: none;

        position: absolute;
        top: 0;
        bottom: 0;
        left: calc(100% + 0.125rem);

        padding-left: 1.25em;
        padding-right: 1em;
        padding-top: calc(50% + 0.125rem); // the font type is slightly top skewed

        color: white;
        background-color: rgba(0, 0, 0, 0.8);

        border-radius: 5px;

        white-space: nowrap;

        opacity: 0;
        transition: opacity 250ms;
      }

      &:focus,
      &:active,
      &:hover,
      &:focus-within {
        &:after {
          background-color: var(--color-primary-dark);
        }

        .tool-tip {
          opacity: 1;
          transition: opacity 500ms 350ms;
        }
      }
    }
  `;

const SubMenuVertical: React.FC = () => {
  const auth = useAuth();
  const { products } = useProducts();

  if (!isAuthenticated(auth)) {
    return <div></div>;
  }

  const showProductsArea = products && products.filtered.products.length > 0;

  return (
    <>
      <div className={"sub-menu-surface " + subMenuSurfaceStyles}></div>
      <nav className={"sub-menu " + subMenuStyles}>
        <SubMenuVerticalItem area="Kontrolpanel" to={Routes.Dashboard.create({})} itemIcon={DashboardAreaIcon} />
        <SubMenuVerticalItem area="Produkter" to={Routes.Products.create({})} itemIcon={ProductsAreaIcon} hidden={!showProductsArea} />
        <SubMenuVerticalItem area="IT-applikationer" to={Routes.Apps.create({})} itemIcon={ApplicationsAreaIcon} />
        <SubMenuVerticalItem area="Bibliotek" to={Routes.Library.create({})} itemIcon={LibraryAreaIcon} />
        <SubMenuVerticalItem area="Nyheder" to={Routes.News.create({})} itemIcon={NewsAreaIcon} />
        <SubMenuVerticalItem area="Profil" to={Routes.Profile.create({})} itemIcon={ProfileAreaIcon} />
      </nav>
    </>
  );
};

const SubMenuVerticalItem: React.FC<
  {
    itemIcon: MdiReactIconComponentType;
    area: string;
  } & Omit<Parameters<NavLink>[0], "activeClassName">
> = ({ itemIcon: ItemIcon, area, ...props }) => {
  return (
    <NavLink {...props} activeClassName="is-active">
      <div className="tool-tip">{area}</div>
      <ItemIcon size="1.125rem" color="white" />
    </NavLink>
  );
};

export default SubMenuVertical;
