import { contentfulConfig } from "./configuration";
import { ClientError, gql, request } from "graphql-request";
import { useSWRCurried } from "./fetching";
import { useAuth } from "oidc-react";

const mobileServiceProvidersFetcher = () => {
  return request<DeepArrayUnmaybefy<MobileServiceProvidersQuery>>(
    contentfulConfig.graphqlEndpoint,
    gql`
      query MobileServiceProviders {
        mobileServiceProviders: mobileServiceProviderCollection(locale: "da-DK") {
          total
          items {
            sys {
              id
            }
            spid
            name
            productAccess: productAccessCollection(locale: "da-DK", limit: 5) {
              items {
                sys {
                  id
                }
                name
              }
            }
            dashboardContent {
              sys {
                id
              }
            }
          }
        }
      }
    `,
    {
      now: new Date().toISOString(),
    }
  ).then((msps) => msps.mobileServiceProviders);
};

export function useMobileServiceProvider() {
  const spId = Number.parseInt(useAuth().userData?.profile.spid || "-1");
  const { data, error } = useSWRCurried<ClientError>()("contentful/mobile-service-providers", mobileServiceProvidersFetcher);

  const mobileServiceProvider = data?.items.find((msp) => msp.spid === spId);

  return {
    mobileServiceProvider,
    mobileServiceProviderFetchError: error,
    isLoadingMobileServiceProvider: !data && !error,
  };
}
