import { CreateClientParams } from "contentful";

/**
 * External URLs
 */

const appConfiguration = {
  operationsHost: process.env.REACT_APP_OPERATIONS_HOST,
  errorReportingHost: process.env.REACT_APP_ERROR_REPORTING_HOST,
};

// Gets all keys of a type which ends with 'Host' and is a string property
type HostKeys<T> = {
  [K in keyof T]: K extends `${string}Host` ? (T[K] extends string | undefined ? K : never) : never;
}[keyof T];

export type AppConfiguration = typeof appConfiguration;
export type ApiHostUrlKeys = HostKeys<AppConfiguration>;

export function buildEndpointUrl(apiHostKey: ApiHostUrlKeys, path: string, ...args: any[]): null | [url: string, ...rest: unknown[]] {
  return appConfiguration[apiHostKey] ? [appConfiguration[apiHostKey] + path, ...args] : null;
}

/**
 * Other general application configuration
 */

const errorReportingDontShowReportFilterRegexStr = process.env.REACT_APP_ERRORREPORTING_DONTSHOW_REPORT_FILTER_REGEX || "/$^/"; // the expression /$^/ will never match anything so NO reports will be filtered away
var regParts = errorReportingDontShowReportFilterRegexStr.match(/^\/(.*?)\/([gimy]*)$/);
export const errorReportingTestReportFilter = regParts
  ? // the parsed pattern had delimiters and modifiers. handle them.
    new RegExp(regParts[1], regParts[2])
  : // we got pattern string without delimiters
    new RegExp(errorReportingDontShowReportFilterRegexStr);

/**
 * Contentful
 */

const contentfulSpace = process.env.REACT_APP_CONTENTFUL_SPACE!;
const contentfulAccessToken = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN!;
const contentfulEnvironment = process.env.REACT_APP_CONTENTFUL_ENVIRONMENT!;

export const contentfulConfig: { clientParams: CreateClientParams; graphqlEndpoint: string } = {
  clientParams: {
    space: contentfulSpace,
    accessToken: contentfulAccessToken,
    environment: contentfulEnvironment,
  },
  graphqlEndpoint: `https://graphql.contentful.com/content/v1/spaces/${contentfulSpace}/environments/${contentfulEnvironment}?access_token=${contentfulAccessToken}`,
};

/**
 * Newsletter
 */

export const newsletterConfig = {
  newsletterSubscribeUrl: process.env.REACT_APP_NEWSLETTER_SUBSCRIBE_URL,
};
