import React from "react";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import { Routes } from "../store/routing";
import ApplicationsReportError from "./ApplicationsReportError";
import ApplicationsApplication from "./ApplicationsApplication";
import ApplicationsApplicationErrorReportsReport from "./ApplicationsErrorReportsReport";
import { useApplications } from "../store/applications";
import PageHeader from "./shared/PageHeader";
import Loader from "./shared/Loader";
import LoadingProblemBanner from "./shared/LoadingProblemBanner";
import Page from "./shared/Page";
import Breadcrumb from "./shared/Breadcrumb";

const routeToApplication = (appRoutingName: string) => Routes.AppsAppRouteName.create({ appRoutingName });

const Applications: React.FC = () => {
  const { applications } = useApplications();

  const sideMenuData = {
    title: "Værktøjer",
    description: "Vælg et værktøj",
    body: (
      <>
        {applications?.all.map((application) => (
          <NavLink to={routeToApplication(application.routingName)} activeClassName="is-active" key={application.cfId}>
            {application.applicationName}
          </NavLink>
        ))}
        <NavLink to={Routes.AppsErrorReports.create({})} activeClassName="is-active">
          Opret fejlmelding
        </NavLink>
      </>
    ),
  };

  return (
    <Page sideMenuData={sideMenuData}>
      <section className="section">
        <div className="content-container">
          <Breadcrumb mapUrlSegmentToName={applications?.findNameByRoutingName} />

          <Switch>
            <Route exact path={Routes.Apps.template()} component={ApplicationsOverview} />
            <Route exact path={Routes.AppsErrorReports.template()}>
              <ApplicationsReportError applicationPickRoute={Routes.AppsAppRouteNameErrorReports.template()} />
            </Route>
            <Route exact path={Routes.AppsAppRouteName.template()} component={ApplicationsApplication} />
            <Route exact path={Routes.AppsAppRouteNameErrorReports.template()}>
              <ApplicationsReportError applicationPickRoute={Routes.AppsAppRouteNameErrorReports.template()} />
            </Route>
            <Route exact path={Routes.AppsAppRouteNameErrorReportsRptId.template()} component={ApplicationsApplicationErrorReportsReport} />
            <Route>
              <Redirect to={Routes.Apps.create({})} />
            </Route>
          </Switch>
        </div>
      </section>
    </Page>
  );
};

const ApplicationsOverview: React.FC = () => {
  const { applications, applicationsFetchError, isLoadingApplications } = useApplications();

  return (
    <>
      <PageHeader title="Status over applikationer" subtitle="Her får du et samlet overblik over vores applikationer" />

      <Loader isLoading={isLoadingApplications}>
        <LoadingProblemBanner show={Boolean(!!!applications && applicationsFetchError)}>
          Der er i øjeblikket problemer med at hente IT-applikationerne
        </LoadingProblemBanner>

        {applications && applications.all.length > 0 && (
          <Redirect
            to={Routes.AppsAppRouteName.create({
              appRoutingName: applications.all[0].routingName,
            })}
          />
        )}
      </Loader>
    </>
  );
};

export default Applications;
