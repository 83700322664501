import React from "react";
import { useMobileServiceProvider } from "../store/mobileServiceProviders";
import SearchTextBox from "./shared/SearchTextBox";

const DashboardMessagesTableControls: React.FC<{
  onShowOnlyDirectMessagesChanged: (value: boolean) => void;
  onSearchTextChanged: (searchText: string) => void;
}> = ({ onShowOnlyDirectMessagesChanged, onSearchTextChanged }) => {
  const [showOnlyDirectMessages, setShowOnlyDirectMessages] = React.useState(false);

  const onChangeShowOnlyDirectMessages = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setShowOnlyDirectMessages(e.target.checked);
      onShowOnlyDirectMessagesChanged(e.target.checked);
    },
    [onShowOnlyDirectMessagesChanged, setShowOnlyDirectMessages]
  );

  const spName = useMobileServiceProvider().mobileServiceProvider?.name;

  return (
    <div className="is-flex is-justify-content-space-between is-align-items-center is-flex-wrap-wrap pb-1">
      <label className="checkbox pb-2 px-3 pr-2">
        <input type="checkbox" className="mr-2" checked={showOnlyDirectMessages} onChange={onChangeShowOnlyDirectMessages} />
        Vis kun direkte beskeder{spName && ` til ${spName}`}
      </label>
      <SearchTextBox onSearchTextChanged={onSearchTextChanged} />
    </div>
  );
};

export default DashboardMessagesTableControls;
