import React from "react";

import AlertOutlineIcon from "mdi-react/AlertOutlineIcon";

const LoadingProblemBanner: React.FC<{ show?: boolean; wrapperClassName?: string; className?: string }> = ({
  show = true,
  children,
  wrapperClassName = "",
  className = "",
}) => {
  return show ? (
    <div className={"message is-warning " + wrapperClassName}>
      <div className="message-body">
        <span className={"icon-text is-align-items-center " + className}>
          <span className="icon is-medium mr-3">
            <AlertOutlineIcon size={32} />
          </span>
          <span className="mt-1">{children}</span>
        </span>
      </div>
    </div>
  ) : null;
};

export default LoadingProblemBanner;
