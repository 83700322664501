import React from "react";
import { css, cx } from "@emotion/css";

const loaderCss = css`
  position: relative;

  &.is-active {
    min-height: 12rem;

    > .loader-wrapper {
      opacity: 1;
      z-index: 1;
    }
  }

  .loader-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: var(--color-scheme-main);
    opacity: 0;
    transition: opacity 0.3s;
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    min-height: 12rem;

    transition: all 200ms ease-in-out;
  }

  .loader {
    height: 120px;
    width: 120px;
    border: 7px solid var(--color-primary);
    border-right-color: transparent;
    border-top-color: transparent;
  }
`;

const Loader: React.FC<{ isLoading: boolean }> = ({ isLoading, children }) => {
  return (
    <div className={cx({ "loader-container": true, [loaderCss]: true, "is-active": isLoading })}>
      <div className="loader-wrapper">
        <div className="loader is-loading"></div>
      </div>
      {children}
    </div>
  );
};

export default Loader;
