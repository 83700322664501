import React from "react";
import { AuthContextProps, AuthProviderProps, useAuth, UserManager, AuthProvider } from "oidc-react";
import { fetchEnsureSuccessWithRetry, RemoteData } from "./fetching";
import { addSeconds } from "date-fns";

const authority = process.env.REACT_APP_STM_URL;
const clientId = "NPP";
const redirectUri = `${window.location.protocol}//${window.location.host}/authentication/login-redirect`;

const oidcConfig: Omit<AuthProviderProps, "onSignOut"> = {
  autoSignIn: false,
  userManager: new UserManager({
    authority: authority,
    client_id: clientId,
    redirect_uri: redirectUri,
    silent_redirect_uri: redirectUri,
    post_logout_redirect_uri: `${window.location.protocol}//${window.location.host}`,
    response_type: "code",
    scope: "openid",
    loadUserInfo: true,
    automaticSilentRenew: false,
    monitorSession: false, // No session monitoring endpoint available from Ping Federate
  }),
};

export function isAuthenticated(auth: AuthContextProps | null): boolean {
  return !!auth?.userData && auth.userData.expired === false;
}

export function useIsAuthenticated(): boolean {
  const auth = useAuth();
  return isAuthenticated(auth);
}

// function useIdToken() {
//   const auth = useAuth();
//   return auth?.userData?.id_token;
// }

function useAccessToken(): string | undefined {
  const auth = useAuth();
  return auth.userData?.access_token;
}

export function useToken(): string | undefined {
  return useAccessToken();
}

export type ItsgTokenResponse = {
  access_token: string;
  issued_token_type: "urn:ietf:params:oauth:token-type:access_token" | string;
  token_type: "Bearer" | string;
  expires_in: number;
};

export type RemoteDataItsgToken = RemoteData<any, ItsgTokenResponse & { retrievedAt: Date }>;

function containsExpiredToken(remoteItsgToken?: RemoteDataItsgToken): boolean {
  return remoteItsgToken?.kind === "success" && addSeconds(remoteItsgToken.data.retrievedAt, remoteItsgToken.data.expires_in) < new Date();
}

export function useItsgToken(): string | undefined {
  const auth = useAuth();
  const accessToken = useAccessToken();
  const [itsgTokenState, setItsgTokenState] = React.useState(auth.itsgTokenRemoteData);

  auth.itsgTokenRemoteData = auth.itsgTokenRemoteData ?? { kind: "initialized" };

  React.useEffect(() => {
    if ((auth.itsgTokenRemoteData?.kind === "initialized" || containsExpiredToken(auth.itsgTokenRemoteData))) {
      auth.itsgTokenRemoteData = { kind: "pending" };
      setItsgTokenState(auth.itsgTokenRemoteData);
      (async () => {
        try {
          const tokenEndpoint = await oidcConfig.userManager?.metadataService.getTokenEndpoint();
          if (!tokenEndpoint) {
            throw new TypeError("Token endpoint for token exchange could not be retrieved");
          } else {
            const response = await fetchEnsureSuccessWithRetry(
              tokenEndpoint,
              {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: [
                  ["grant_type", "urn:ietf:params:oauth:grant-type:token-exchange"],
                  ["client_id", clientId],
                  ["resource", "https://itsg.tdc.dk"],
                  ["subject_token", accessToken ?? ""],
                  ["subject_token_type", "urn:ietf:params:oauth:token-type:jwt"],
                ]
                  .reduce((params, [key, val]) => {
                    params.append(key, val);
                    return params;
                  }, new URLSearchParams())
                  .toString(),
              },
              3,
              300
            );

            const itsgTokenResponse: ItsgTokenResponse = await response.json(); // TODO: Verify the parsing
            auth.itsgTokenRemoteData = { kind: "success", data: { ...itsgTokenResponse, retrievedAt: new Date() } };
            setItsgTokenState(auth.itsgTokenRemoteData);
          }
        } catch (error) {
          auth.itsgTokenRemoteData = { kind: "failure", error: error };
          setTimeout(() => {
            auth.itsgTokenRemoteData = { kind: "initialized" };
            setItsgTokenState(auth.itsgTokenRemoteData);
          }, 10000); // Wait some time before trying again
        }
      })();
    }
  }, [accessToken, auth, itsgTokenState]);

  return auth.itsgTokenRemoteData.kind === "success" ? auth.itsgTokenRemoteData.data.access_token : undefined;
}

export function signInAndTryRemainOnCurrentPathArgs() {
  return { state: { postSignInPath: window.location.pathname + window.location.search } };
}

export const NppAuthProvider: React.FC<{ children: JSX.Element } & Pick<AuthProviderProps, "onSignOut">> = ({ children, ...rest }) => {
  // We need an inner component to use the useAuth hook which relies on the AuthContext set up by AuthProvider
  const InnerComponent: React.FC<{ children: JSX.Element }> = ({ children }) => {
    const auth = useAuth();

    // Sign out (locally) when token expires
    React.useEffect(() => {
      const handleAccessTokenExpired = (ev: any[]) => auth.signOut();
      auth.userManager.events.removeAccessTokenExpired(handleAccessTokenExpired);
      return auth.userManager.events.addAccessTokenExpired(handleAccessTokenExpired);
    }, [auth, auth.userManager]);

    return children;
  };

  return (
    <AuthProvider {...oidcConfig} {...rest}>
      <InnerComponent>{children}</InnerComponent>
    </AuthProvider>
  );
};
