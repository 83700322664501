import { contentfulConfig } from "./configuration";
import { ClientError, gql, request } from "graphql-request";
import { useSWRCurried } from "./fetching";
import { toRoutingName } from "./misc";

export type ApplicationOverview = {
  cfId: string;
  applicationId: number;
  applicationName: string;
  routingName: string;
};

type Applications = {
  all: ReadonlyArray<ApplicationOverview>;
  findById(appId: number): ApplicationOverview | undefined;
  findByRoutingName(routingName: string): ApplicationOverview | undefined;
  findNameByRoutingName(routingName: string): string | undefined;
};

const applicationsFetcher = () => {
  return request<DeepArrayUnmaybefy<ApplicationsQuery>>(
    contentfulConfig.graphqlEndpoint,
    gql`
      query Applications {
        itApplications: itApplicationCollection(locale: "da-DK", order: [order_ASC]) {
          total
          items {
            sys {
              id
            }
            appId
            name
            order
          }
        }
      }
    `
  );
};

export function useApplications(): {
  applications: Applications | undefined;
  applicationsFetchError: ClientError | undefined;
  isLoadingApplications: boolean;
} {
  const { data, error } = useSWRCurried<ClientError>()("contentful/it-applications", applicationsFetcher);

  const allApps = data?.itApplications?.items.map<ApplicationOverview>((app) => ({
    cfId: app.sys.id,
    applicationId: app.appId!,
    applicationName: app.name!,
    routingName: toRoutingName(app.name!),
  }));

  return {
    applications: !allApps
      ? undefined
      : {
          all: allApps,
          findById: (appId: number) => allApps.find((app) => app.applicationId === appId),
          findByRoutingName: (rn: string) => allApps.find(propEq("routingName", rn.toLowerCase())),
          findNameByRoutingName: (rn: string) => allApps.find(propEq("routingName", rn.toLowerCase()))?.applicationName,
        },
    applicationsFetchError: error,
    isLoadingApplications: !data && !error,
  };
}

function propEq<K extends string | number>(prop: K, val: any) {
  return (obj: Record<K, any>) => obj[prop] === val;
}
