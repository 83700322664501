import React, { useEffect, useMemo } from "react";
import Loader from "./shared/Loader";
import { createHeaderRenderer } from "./shared/TableHeaderRenderer";
import { ErrorReport, useErrorReports } from "../store/errorReporting";
import { Column, SortByFn, usePagination, useFilters, useSortBy, useTable, Filters } from "react-table";
import DateStamp from "./shared/DateStamp";
import LoadingProblemBanner from "./shared/LoadingProblemBanner";
import { ApplicationOverview } from "../store/applications";
import { css } from "@emotion/css";
import Pagination from "./shared/Pagination";
import { differenceInDays } from "date-fns/fp";
import { Link, useHistory } from "react-router-dom";

const caseInsesitiveDaStringSort: SortByFn<ErrorReport> = (rowA, rowB, colId) =>
  rowA.values[colId].localeCompare(rowB.values[colId], "da", { sensitivity: "base" });

const columns: Column<ErrorReport>[] = [
  {
    Header: createHeaderRenderer("Emne"),
    accessor: "subject",
    sortType: caseInsesitiveDaStringSort,
  },
  {
    width: 30,
    Header: createHeaderRenderer("Status", "is-flex is-justify-content-flex-end"),
    accessor: "status",
    Cell: ({ value }) => <div className="has-text-right">{value}</div>,
  },
  {
    width: 50,
    Header: createHeaderRenderer("Dato", "is-flex is-justify-content-flex-end"),
    accessor: "createdDate",
    Cell: ({ value }) => <div className="has-text-right">{<DateStamp date={value} useDistanceFromToday={false} />}</div>,
    sortType: "datetime",
  },
  {
    width: 40,
    Header: createHeaderRenderer("Doc ID", "is-flex is-justify-content-flex-end white-space-nowrap"),
    accessor: "docId",
    Cell: ({ value, url }) => (
      <div className="has-text-right pr-1">
        <Link to={`${url}/${value}`}>{value}</Link>
      </div>
    ),
    sortType: "basic",
  },
  {
    id: "application",
    filter: (rows, _, filterValue) => rows.filter((r) => r.original.applicationId === filterValue.applicationId),
  },
  {
    id: "openClosed",
    filter: (rows, _, filterValue) =>
      filterValue === "Open"
        ? rows.filter((r) => r.values.status !== "Lukket")
        : rows.filter((r) => r.values.status === "Lukket" && differenceInDays(r.original.statusChangedDate, Date.now()) < 5),
  },
];

const ApplicationsApplicationErrorReportsTable: React.FC<
  Pick<ApplicationOverview, "applicationId" | "applicationName"> & {
    paginationLabel: string;
    show: "Open" | "Closed";
    errorReportsReportBaseUrl: string;
  }
> = ({ applicationId, applicationName, paginationLabel, show, errorReportsReportBaseUrl: url }) => {
  const { errorReports, errorReportsFetchError, isLoadingErrorReports } = useErrorReports();

  // Memoize filters so the instance of the array doesn't constantly change forcing re-render and reset of the page index in the table
  const filters = useMemo(
    () => [
      {
        id: "application",
        value: { applicationId, applicationName },
      },
      {
        id: "openClosed",
        value: show,
      },
    ],
    [applicationId, applicationName, show]
  );

  return (
    <Loader isLoading={isLoadingErrorReports}>
      <LoadingProblemBanner show={Boolean(!!!errorReports && errorReportsFetchError)}>
        Der er i øjeblikket problemer med at hente fejlmeldinger for {applicationName}
      </LoadingProblemBanner>

      {errorReports && (
        <RenderTable url={url} columns={columns} data={errorReports} paginationProps={{ itemLabel: paginationLabel }} filters={filters} />
      )}
    </Loader>
  );
};

function RenderTable({
  url,
  columns,
  data,
  paginationProps,
  filters,
}: {
  url: string;
  columns: Column<ErrorReport>[];
  data: ErrorReport[];
  paginationProps: { itemLabel: string };
  filters: Filters<ErrorReport>;
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    state: { pageIndex, pageSize },
    gotoPage,
    setFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 5,
        hiddenColumns: ["application", "openClosed"],
      },
      getRowId: (origRow) => origRow.docId.toString(),
      autoResetExpanded: false,
      autoResetFilters: false,
      autoResetGlobalFilter: false,
    },
    useFilters,
    useSortBy,
    usePagination
  );
  const history = useHistory();

  filters.sort((f1, f2) => f1.id.localeCompare(f2.id));
  useEffect(() => {
    filters.forEach((f) => setFilter(f.id, f.value));
  }, [setFilter, filters]);

  return rows.length === 0 ? (
    <div className="content pt-5 has-text-primary">
      <span className="is-size-6 has-text-weight-semibold">Der er ingen aktuelle fejlmeldinger på nuværende tidspunkt</span>
    </div>
  ) : (
    <>
      <div
        className={css`
          overflow-x: auto;
        `}
      >
        <table className={"table is-fullwidth is-hoverable is-clickable"} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps([column.getSortByToggleProps(), { className: "has-text-grey-dark" }])}>{column.render("Header")}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} onClick={() => history.push(`${url}/${row.values.docId}`)}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render("Cell", { url })}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Pagination
        pages={Math.ceil(rows.length / pageSize)}
        page={pageIndex + 1}
        siblingCount={1}
        setPage={(p) => gotoPage(p - 1)}
        before={
          <div className="pt-1 has-text-weight-bold">
            {rows.length} {paginationProps.itemLabel}
          </div>
        }
        after={
          <div className="pt-1 has-text-weight-bold">
            Viser {pageIndex * pageSize + 1} til {Math.min((pageIndex + 1) * pageSize, rows.length)} ud af {rows.length}
          </div>
        }
      />
    </>
  );
}

export default ApplicationsApplicationErrorReportsTable;
