import React from "react";
import { useMessageHistory } from "../store/messages";
import { Routes } from "../store/routing";
import { addMonths, addSeconds, endOfYear, isAfter, isBefore, startOfMonth, startOfYear } from "date-fns";
import { Redirect, useLocation } from "react-router-dom";
import Loader from "./shared/Loader";
import LoadingProblemBanner from "./shared/LoadingProblemBanner";
import HistogramMonthRangePicker from "./shared/HistogramMonthRangePicker";
import DashboardMessagesTable from "./DashboardMessagesTable";
import Breadcrumb from "./shared/Breadcrumb";
import PageHeader from "./shared/PageHeader";

const DashboardMessagesOverview: React.FC = () => {
  const { messageHistory, messageHistoryFetchError, isLoadingMessageHistory } = useMessageHistory();

  const { msg: msgId } = Routes.DashboardMessages.parse(useLocation().search);
  const selectedMessage = msgId ? messageHistory?.all.findById(msgId) : undefined;
  const selectedMessageFromDate = selectedMessage?.fromDate.valueOf();

  const [expandedMessageId, setExpandedMessageId] = React.useState(msgId);
  React.useEffect(() => {
    msgId && setExpandedMessageId(msgId);
  }, [msgId]);

  const currentMonth = startOfMonth(new Date());
  const currentMonthValue = currentMonth.valueOf();
  const monthsDomain = React.useMemo(() => [startOfYear(currentMonthValue), endOfYear(currentMonthValue)] as [Date, Date], [currentMonthValue]);

  const [[selectedFrom, selectedTo], setSelectedMonthInterval] = React.useState([currentMonth, addMonths(currentMonth, 1)] as [Date, Date]);

  React.useEffect(() => {
    if (selectedMessageFromDate) {
      const from = startOfMonth(selectedMessageFromDate);
      setSelectedMonthInterval([from, addMonths(from, 1)]);
    }
  }, [selectedMessageFromDate, msgId]);

  const messageDates = React.useMemo(
    () => messageHistory?.all.messages.filter((msg) => isAfter(msg.fromDate, startOfYear(new Date()))).map((msg) => msg.fromDate) ?? [],
    [messageHistory?.all.messages]
  );
  const messagesShownInTable = React.useMemo(
    () =>
      messageHistory?.all.messages.filter((msg) => isAfter(msg.fromDate, addSeconds(selectedFrom, -1)) && isBefore(msg.fromDate, selectedTo)) ?? [],
    [messageHistory?.all.messages, selectedFrom, selectedTo]
  );

  return selectedMessageFromDate ? (
    // redirect will not remount THIS component (but all the ones below) so all useState and useEffect stuff in this component will still work
    <Redirect to={Routes.DashboardMessages.create({})} />
  ) : (
    <>
      <Breadcrumb />

      <PageHeader title="Beskeder fra SP support" />

      <Loader isLoading={isLoadingMessageHistory}>
        <LoadingProblemBanner show={Boolean(!!!messageHistory && messageHistoryFetchError)}>
          Der er i øjeblikket problemer med at hente beskeder
        </LoadingProblemBanner>

        {messageHistory && (
          <>
            <div className="pt-3">
              <HistogramMonthRangePicker
                dates={messageDates}
                onBrushChanged={([from, to]) => setSelectedMonthInterval([from, to])}
                monthsDomain={monthsDomain}
                initiallySelectedMonths={[selectedFrom, selectedTo]}
              />
            </div>

            <div className="pt-6">
              <DashboardMessagesTable
                data={messagesShownInTable}
                pageSize={7}
                expandedMessage={expandedMessageId}
                createRouteToMessage={Routes.DashboardMessagesMsgIdMsgRouteName.create}
              />
            </div>
          </>
        )}

        {/* <br />

        <Link
          to={Routes.DashboardMessages.create({
            query: {
              msg: messageHistory?.all.messages.filter((m) => isAfter(m.fromDate, startOfYear(new Date())))[
                Math.floor(Math.random() * messageHistory.all.messages.filter((m) => isAfter(m.fromDate, startOfYear(new Date()))).length)
              ].sys.id,
            },
          })}
        >
          GOTO
        </Link> */}
      </Loader>
    </>
  );
};

export default DashboardMessagesOverview;
