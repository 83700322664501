import React from "react";
import FileIcon from "./FileIcon";
import { assertUnreachable, toRoutingName } from "../../store/misc";
import { css } from "@emotion/css";
import { Link } from "react-router-dom";
import { Routes } from "../../store/routing";

import OpenInNewIcon from "mdi-react/OpenInNewIcon";
import PlayBoxIcon from "mdi-react/PlayBoxIcon";

const pageListStyles =
  "box is-shadowless panel page-list is-size-8 " +
  css`
    &.icons-trailing {
      a.panel-block .item-title .icon {
        position: sticky;
        right: -1.125rem;
      }
    }

    a.panel-block {
      padding: 1em 0.5em 0.75em 0.75em;

      .icon {
        position: absolute;
        right: 0;
        height: 0;
        top: 0.5rem;

        visibility: hidden;

        color: var(--color-grey-lighter);
      }

      &:focus,
      &:active,
      &:hover,
      &:focus-within {
        background-color: whitesmoke;
        color: var(--color-link);

        .icon {
          visibility: visible;
          color: var(--color-link);
          opacity: 0.5;
        }
      }

      .item-title {
        position: relative;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 0;
        flex-grow: 1;

        padding-right: 1.125rem;
      }
    }
  `;

type IconsTrailing = { iconsTrailing?: boolean };

type RouteOrUrl = { kind: "route" | "url"; href: string };

type ItemListItemTypes = NonNullable<DeepArrayUnmaybefy<ItmListFragment>["items"]>["items"][number];

type PageListItemUrlRetrievers = {
  documentListItemUrlRetriever: (asset: NonNullable<DeepArrayUnmaybefy<DocListFragment>["documents"]>["items"][number]) => RouteOrUrl;
} & {
  [P in ItemListItemTypes["__typename"] as `${Uncapitalize<P>}UrlRetriever`]: (item: Extract<ItemListItemTypes, { __typename: P }>) => RouteOrUrl;
};

const defaultUrlRetrievers: PageListItemUrlRetrievers = {
  documentListItemUrlRetriever: (asset: NonNullable<DeepArrayUnmaybefy<DocListFragment>["documents"]>["items"][0]) => ({
    kind: "url",
    href: asset.url ?? "#",
  }),
  assetItemUrlRetriever: (item) => ({ kind: "url", href: item.asset?.url ?? "#" }),
  linkItemUrlRetriever: (item) => ({ kind: "url", href: item.link ?? "#" }),
  videoItemUrlRetriever: (item) => ({
    kind: "route",
    href: Routes.VideosVideoIdRouteName.create({ videoId: item.sys.id, videoRoutingName: toRoutingName(item.title ?? "loading") }),
  }),
};

type PageListItemClickHandlers = {
  onClickDocumentListItem: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    asset: NonNullable<DeepArrayUnmaybefy<DocListFragment>["documents"]>["items"][number]
  ) => void;
} & {
  [P in ItemListItemTypes["__typename"] as `onClick${Capitalize<P>}`]: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    item: Extract<ItemListItemTypes, { __typename: P }>
  ) => void;
};

export default function PageList({
  list,
  iconsTrailing,
  urlRetrievers,
  clickHandlers,
}: { list: DeepArrayUnmaybefy<DocListFragment> | DeepArrayUnmaybefy<ItmListFragment> } & IconsTrailing & {
    urlRetrievers?: Partial<PageListItemUrlRetrievers>;
    clickHandlers?: Partial<PageListItemClickHandlers>;
  }) {
  switch (list.__typename) {
    case "DocumentList":
      return (
        <DocumentList
          list={list}
          iconsTrailing={iconsTrailing}
          urlRetriever={{ ...defaultUrlRetrievers, ...urlRetrievers }["documentListItemUrlRetriever"]}
          onClickDocumentListItem={clickHandlers?.onClickDocumentListItem}
        />
      );
    case "ItemList":
      return (
        <ItemList
          list={list}
          iconsTrailing={iconsTrailing}
          urlRetrievers={{ ...defaultUrlRetrievers, ...urlRetrievers }}
          clickHandlers={clickHandlers}
        />
      );
    default:
      return assertUnreachable(list);
  }
}

function DocumentList({
  list,
  iconsTrailing,
  urlRetriever,
  onClickDocumentListItem,
}: {
  list: DeepArrayUnmaybefy<DocListFragment>;
} & IconsTrailing & {
    urlRetriever: PageListItemUrlRetrievers["documentListItemUrlRetriever"];
    onClickDocumentListItem?: PageListItemClickHandlers["onClickDocumentListItem"];
  }) {
  return (
    <div className={pageListStyles + (iconsTrailing === true ? " icons-trailing" : "")}>
      {list.title && <p className="panel-heading p-0 pb-2 has-background-white">{list.title}</p>}
      {list.documents?.items.map((asset, idx) => (
        <PageListItem
          itemTitle={asset.title}
          linkOrRoute={urlRetriever(asset)}
          icon={<FileIcon {...asset} />}
          onClick={(e) => onClickDocumentListItem?.(e, asset)}
          key={asset.sys.id + idx} // idx is used because the same entry can potentially occur in the list multiple times :(
        />
      ))}
    </div>
  );
}

function ItemList({
  list,
  iconsTrailing,
  urlRetrievers,
  clickHandlers,
}: { list: DeepArrayUnmaybefy<ItmListFragment> } & IconsTrailing & {
    urlRetrievers: Omit<PageListItemUrlRetrievers, "documentListItemUrlRetriever">;
    clickHandlers?: Partial<Omit<PageListItemClickHandlers, "onClickDocumentListItem">>;
  }) {
  return (
    <div className={pageListStyles + (iconsTrailing === true ? " icons-trailing" : "")}>
      {list.title && <p className="panel-heading pl-0 pt-0 has-background-white">{list.title}</p>}
      {list.items?.items.map((entry, idx) => {
        switch (entry.__typename) {
          case "AssetItem":
            return (
              <PageListItem
                itemTitle={entry.title}
                title={entry.title ?? undefined}
                linkOrRoute={urlRetrievers.assetItemUrlRetriever(entry)}
                icon={<FileIcon {...entry.asset} />}
                onClick={(e) => clickHandlers?.onClickAssetItem?.(e, entry)}
                key={entry.sys.id + idx} // idx is used because the same entry can potentially occur in the list multiple times :(
              />
            );
          case "LinkItem":
            return (
              <PageListItem
                itemTitle={entry.title}
                title={entry.title ?? undefined}
                linkOrRoute={urlRetrievers.linkItemUrlRetriever(entry)}
                icon={<OpenInNewIcon />}
                onClick={(e) => clickHandlers?.onClickLinkItem?.(e, entry)}
                target="_blank"
                rel="noreferrer"
                key={entry.sys.id + idx} // idx is used because the same entry can potentially occur in the list multiple times :(
              />
            );
          case "VideoItem":
            return (
              <PageListItem
                itemTitle={entry.title}
                title={JSON.stringify(entry.video)}
                linkOrRoute={urlRetrievers.videoItemUrlRetriever(entry)}
                icon={<PlayBoxIcon size="1rem" style={{ position: "absolute", transform: "translateY(-0.2rem)" }} />}
                onClick={(e) => clickHandlers?.onClickVideoItem?.(e, entry)}
                key={entry.sys.id + idx} // idx is used because the same entry can potentially occur in the list multiple times :(
              />
            );
          default:
            return null;
        }
      })}
    </div>
  );
}

function PageListItem({
  itemTitle,
  linkOrRoute,
  icon,
  onClick,
  ...anchorAttributes
}: {
  itemTitle?: string | null;
  linkOrRoute: RouteOrUrl;
  icon: JSX.Element;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>) {
  return linkOrRoute.kind === "url" ? (
    <a className="panel-block" href={linkOrRoute.href} {...anchorAttributes} onClick={onClick}>
      <span className="item-title">
        <span>{itemTitle}</span>
        <div className="icon is-small ml-1">{icon}</div>
      </span>
    </a>
  ) : (
    <Link className="panel-block" to={linkOrRoute.href} onClick={onClick}>
      <span className="item-title">
        <span>{itemTitle}</span>
        <div className="icon is-small ml-1">{icon}</div>
      </span>
    </Link>
  );
}
