import { contentfulConfig } from "./configuration";
import { ClientError, gql, request } from "graphql-request";
import { useSWRCurried } from "./fetching";
import { docListFragment, itemListFragment } from "./contentfulShared";
import { useMobileServiceProvider } from "./mobileServiceProviders";

const dashboardContentFetcher = (_: string, id: string) => {
  return request<DeepArrayUnmaybefy<DashboardContentQuery>>(
    contentfulConfig.graphqlEndpoint,
    gql`
      query DashboardContent($id: String!) {
        mobileServiceProvider(id: $id) {
          dashboardContent {
            sys {
              id
            }
            topRowLabel
            topRowOpeningHours {
              json
            }
            highlightsLabel
            # note the limit of 3 which is set in Contentful
            highlights: highlightsCollection(locale: "da-DK", limit: 3) {
              total
              items {
                ... on AssetItem {
                  ...AssetItemFields
                }
                ... on LinkItem {
                  ...LinkItemFields
                }
              }
            }
            mainListsLabel
            # note the limit of 5 which is set in Contentful
            mainLists: mainListsCollection(locale: "da-DK", limit: 5) {
              total
              items {
                ... on DocumentList {
                  ...DocList
                }
                ... on ItemList {
                  ...ItmList
                }
              }
            }
          }
        }
      }
      ${docListFragment}
      ${itemListFragment}
    `,
    {
      id,
    }
  );
};

export function useDashboardContent() {
  const { mobileServiceProvider } = useMobileServiceProvider();
  const { data, error } = useSWRCurried<ClientError>()(
    () => (!mobileServiceProvider?.sys.id ? null : ["contentful/dashboard-content", mobileServiceProvider.sys.id]),
    dashboardContentFetcher
  );

  return {
    dashboardContent: data?.mobileServiceProvider?.dashboardContent,
    dashboardContentFetchError: error,
    isLoadingDashboardContent: !data && !error,
  };
}
