import React from "react";
import { css } from "@emotion/css";

import CloseCircleOutlineIcon from "mdi-react/CloseCircleOutlineIcon";
import MagnifyIcon from "mdi-react/MagnifyIcon";

const SearchTextBox: React.FC<{ onSearchTextChanged: (searchText: string) => void }> = ({ onSearchTextChanged }) => {
  const [searchText, setSearchText] = React.useState("");
  const onChangeSearchText = React.useCallback(
    (searchText) => {
      onSearchTextChanged(searchText);
      setSearchText(searchText);
    },
    [onSearchTextChanged, setSearchText]
  );

  return (
    <div
      className={
        "field pb-3 " +
        css`
          min-width: 28ch;
          span.icon {
            align-items: flex-start;
            padding-top: 0.4rem;
          }
        `
      }
    >
      <p className="control has-icons-left has-icons-right">
        <input
          className="input is-rounded pt-3 has-background-white is-size-7"
          type="text"
          value={searchText}
          onChange={(e) => onChangeSearchText(e.target.value)}
        />
        <span className="icon is-small is-left">
          <MagnifyIcon />
        </span>
        <span
          className="icon is-small is-right is-clickable"
          tabIndex={0}
          onKeyDown={(e: React.KeyboardEvent<HTMLAnchorElement>) => {
            if (e.key === " " || e.key === "Enter") {
              e.preventDefault();
              onChangeSearchText("");
            }
          }}
          onClick={() => onChangeSearchText("")}
        >
          <CloseCircleOutlineIcon />
        </span>
      </p>
    </div>
  );
};

export default SearchTextBox;
