import React from "react";
import { css, cx } from "@emotion/css";
import { addMonths, differenceInCalendarMonths, format, startOfMonth, endOfMonth } from "date-fns";
import { da } from "date-fns/locale";
import { BarChart, Bar, Brush, XAxis, Cell, YAxis } from "recharts";
import { useResizeObserver } from "../../store/useResizeObserver";

// HACK
// Overwriting the static method in brush to make the brush index chosen be the one nearest the x-value of the traveller in BOTH directions
Brush.getIndexInRange = (range: number[], xVal: number) => {
  let idxOfValGreaterThanX = range.findIndex((rangeVal: number) => rangeVal > xVal);
  if (idxOfValGreaterThanX === -1) {
    return range.length - 1;
  }
  if (idxOfValGreaterThanX === 0) {
    return 0;
  }

  const ltVal = range[idxOfValGreaterThanX - 1];
  const gtVal = range[idxOfValGreaterThanX];

  const idx = (xVal - ltVal) / (gtVal - ltVal) > 0.5 ? idxOfValGreaterThanX : idxOfValGreaterThanX - 1;

  return idx;
};

const chartHeight = 120;
const brushHeight = chartHeight - 10; // if we don't subtract this then we get an error like "rect can't have height set to -10"

const outerWrapperStyles = css`
  position: relative;
  width: 100%;
  height: ${chartHeight}px;

  .inner-wrapper {
    position: absolute;
  }

  .recharts-brush-slide {
    fill: rgba(0, 0, 100, 0.65);
  }

  .recharts-brush-traveller {
    rect,
    line {
      fill: rgba(0, 0, 0, 0.65);
    }
  }

  .recharts-bar-rectangle .recharts-rectangle {
    fill: var(--color-grey-lighter);

    &.current {
      //fill: rgba(0, 0, 0, 0.5);
      //fill: var(--color-primary);
      //fill: #6f73dc;
    }
    &.selected {
      //fill: rgba(0, 0, 0, 0.35);
      //fill: var(--color-primary);
      fill: #6f73dc;
    }
  }

  .recharts-cartesian-axis.recharts-xAxis {
    g text {
      fill: var(--color-grey);
    }
    g.selected text {
      fill: rgba(0, 0, 0, 0.6);
      font-weight: bold;
    }
  }
`;

export const HistogramMonthRangePicker: React.FC<{
  dates: ReadonlyArray<Date>;
  onBrushChanged?: (selected: [from: Date, to: Date]) => void;
  monthsDomain: [fromMonthInclusive: Date, toMonthInclusive: Date];
  initiallySelectedMonths?: [fromSelected: Date, toSelected: Date];
}> = ({ dates, onBrushChanged, monthsDomain, initiallySelectedMonths }) => {
  const outerWrapperRef = React.useRef(null);
  const [outerWrapperWidth] = useResizeObserver(outerWrapperRef);

  const currentMonth = startOfMonth(new Date());
  const currentMonthValue = currentMonth.valueOf();

  const brushSelectionRef = React.useRef(
    (() => {
      const fromSelectedMonthDiff = (initiallySelectedMonths && differenceInCalendarMonths(initiallySelectedMonths[0], currentMonth)) ?? 0;
      const toSelectedMonthDiff = (initiallySelectedMonths && differenceInCalendarMonths(initiallySelectedMonths[1], currentMonth) + 1) ?? 4;
      return { start: fromSelectedMonthDiff, end: toSelectedMonthDiff, hasBeenRecalculated: false };
    })()
  );
  const { current: brushSelection } = brushSelectionRef;

  const { frequencies, maxFrequency, minMonthDiffWithData, maxMonthDiffWithData } = React.useMemo(() => {
    const rollup = rollupFrequencies(dates, currentMonthValue, monthsDomain);

    // if this is the first time we do the rollup then set the brush selection indices to encompas current month and the following two
    if (!brushSelection.hasBeenRecalculated) {
      brushSelection.hasBeenRecalculated = true;
      brushSelection.start -= rollup.minMonthDiffWithData;
      brushSelection.end -= rollup.minMonthDiffWithData;
    }

    // if the data changed and the old brush selection indices now fall outside the bounds of the array then force them inside
    // (a better but more complex solution would be to also hold the actual month or monthDiff selected previously
    // so that we could try to keep the selected months if they are available in the new dataset)
    if (brushSelection.end >= rollup.frequencies.length) {
      const prevSelectionSize = brushSelection.end - brushSelection.start;
      brushSelection.end = rollup.frequencies.length - 1;
      brushSelection.start = Math.max(brushSelection.end - prevSelectionSize, 0);
    }

    return rollup;
  }, [dates, currentMonthValue, monthsDomain, brushSelection]);

  const onChange = (
    event: {
      startIndex?: number;
      endIndex?: number;
    } & any // HACK since onChange on Brush has a signature that doesn't play nice
  ) => {
    if (onBrushChanged && event.startIndex !== undefined && event.endIndex !== undefined) {
      if (brushSelection.start !== event.startIndex || brushSelection.end !== event.endIndex) {
        brushSelection.start = event.startIndex;
        brushSelection.end = event.endIndex;
      }
    }
  };

  const onBrushMoved = () => {
    // create a new object instance to break memoization of data frequency date for chart and brush get
    // rerendered and brush "snaps" to closes indices
    brushSelectionRef.current = { ...brushSelection };

    onBrushChanged &&
      frequencies[brushSelection.start] &&
      frequencies[brushSelection.end] &&
      onBrushChanged([
        endOfMonth(addMonths(currentMonth, frequencies[brushSelection.start].monthDiff)),
        startOfMonth(addMonths(currentMonth, frequencies[brushSelection.end].monthDiff)),
      ]);
  };

  return (
    <div ref={outerWrapperRef} className={outerWrapperStyles}>
      <div className="inner-wrapper" onMouseLeave={onBrushMoved} onMouseUp={onBrushMoved} onTouchEnd={onBrushMoved}>
        <BarChart width={outerWrapperWidth} height={chartHeight} data={frequencies}>
          <Brush
            stroke="transparent"
            fill="transparent"
            height={brushHeight}
            onChange={onChange}
            startIndex={brushSelection.start}
            endIndex={brushSelection.end}
          >
            <BarChart>
              <Bar dataKey="frequency">
                {frequencies.map((entry, index) => {
                  const isSelected = index > brushSelection.start && index < brushSelection.end;
                  return <Cell key={`cell-${index}`} className={cx({ selected: isSelected, current: entry.monthDiff === 0 })} />;
                })}
              </Bar>
              <YAxis dataKey="frequency" type="number" width={0} domain={["dataMin", `dataMax+${Math.ceil(maxFrequency / 6)}`]} />
              <XAxis
                dataKey="monthDiff"
                type="number"
                domain={["dataMin", "dataMax"]}
                tick={(p: any) => {
                  const { x, y, className, payload } = p;

                  const monthDate = addMonths(currentMonth, payload.value);
                  const tickStr = format(monthDate, monthDate.getMonth() === 0 ? "yyyy" : "MMM", { locale: da }).replace(".", "");
                  const isSelected =
                    payload.value - minMonthDiffWithData + 1 > brushSelection.start && payload.value - minMonthDiffWithData + 1 < brushSelection.end;

                  return (
                    <g transform={`translate(${x},${y})`} className={cx({ [className]: true, selected: isSelected })}>
                      <text x={0} y={6} fontSize={11} textAnchor="middle" fill="#666">
                        {tickStr}
                      </text>
                    </g>
                  );
                }}
                ticks={Array.from({ length: maxMonthDiffWithData - minMonthDiffWithData + 1 }, (_, idx) => idx + minMonthDiffWithData)}
                tickMargin={6}
              />
            </BarChart>
          </Brush>
        </BarChart>
      </div>
    </div>
  );
};

/**
 *
 * @param dateItems is the raw date items used to calculate frequency per month
 * @param monthOrigin the raw number representing the start instant of the month to DEFINITELY have in the domain
 * @parem [fromMonthInclusive, toMonthInclusive] are the min and max months guarantied to be in the domain (which will expand if dateItems fall outside these)
 */
function rollupFrequencies(
  dateItems: ReadonlyArray<Date>,
  monthOriginValue: number,
  [fromMonthInclusive, toMonthInclusive]: [fromMonthInclusive: Date, toMonthInclusive: Date]
): {
  frequencies: Array<{
    monthDiff: number;
    frequency: number;
  }>;
  maxFrequency: number;
  minMonthDiffWithData: number;
  maxMonthDiffWithData: number;
} {
  const {
    map: monthsWithItemsMap,
    minMonthDiff,
    maxMonthDiff,
  } = dateItems
    .map((itm) => ({ itm, monthDiff: differenceInCalendarMonths(itm, monthOriginValue) }))
    .reduce<{ map: Map<number, number>; minMonthDiff: number; maxMonthDiff: number }>(
      (acc, cur) => {
        acc.map.set(cur.monthDiff, (acc.map.get(cur.monthDiff) ?? 0) + 1);
        return {
          ...acc,
          minMonthDiff: Math.min(acc.minMonthDiff, cur.monthDiff),
          maxMonthDiff: Math.max(acc.maxMonthDiff, cur.monthDiff),
        };
      },
      {
        map: new Map<number, number>(),
        minMonthDiff: differenceInCalendarMonths(fromMonthInclusive, monthOriginValue),
        maxMonthDiff: differenceInCalendarMonths(toMonthInclusive, monthOriginValue),
      }
    );

  // +3 because we need 1 position before and 2 positions after the data boundaries in order to display things properly
  const desiredfrequenciesLength = maxMonthDiff - minMonthDiff + 3;

  let maxFrequency = 0;
  const frequencies = Array.from({ length: desiredfrequenciesLength }, (_, idx) => {
    const monthDiff = minMonthDiff + idx - 1; // we offset by -1 so we start with a space and then a full bar instead of a cut off half bar
    const frequency = monthsWithItemsMap.get(monthDiff) ?? 0;

    maxFrequency = Math.max(maxFrequency, frequency);

    return {
      monthDiff,
      frequency,
    };
  });

  return {
    frequencies,
    maxFrequency,
    minMonthDiffWithData: minMonthDiff,
    maxMonthDiffWithData: maxMonthDiff,
  };
}

export default HistogramMonthRangePicker;
